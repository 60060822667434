import moment from "moment";
import "moment/locale/fr-ca";

// Locale
moment.locale("fr-ca");

export const formatDateTimeStripe = datetime => {
    return moment(datetime).format("DD/MM/YY HH:mm");
};

export const formatDateTime = datetime => {
    return moment(datetime).format("DD MMMM HH:mm:ss");
};

export const formatDate = datetime => moment(datetime).format("DD/MM/YYYY");

export const differenceInDaysBetweenDate = (date1, date2) => {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    return diff / (1000 * 3600 * 24);
};
