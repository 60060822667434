import React from "react";

const Bottom = () => {
    return (
        <div className="bottom">
            <div className="bottom__container kanit">
                <div className="bottom__left">
                    <a href="https://melangecoupefaim.com/" target="_blank"><p>Mélange Coupe Faim</p></a>
                    <a href="https://aktivation.ca" target="_blank"><p>Aktivation</p></a>
                    <a href="https://gochoko.com/" target="_blank"><p>Go Choko!</p></a>
                    <a href="https://gomatcha.ca/" target="_blank"><p>Go Matcha!</p></a>
                    <a href="https://gokrisp.com/" target="_blank"><p>Go Krisp!</p></a>
                </div>
                <a href="www.leadhouse.ca" target="_blank" className="bottom__right">
                    Site créé par <span>LEADHOUSE</span>
                </a>
            </div>
        </div>
    );
};

export default Bottom;
