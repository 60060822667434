import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Mui
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";

// Assets
import raspberry from "../../../assets/gg/raspberry.png";
import watermelon from "../../../assets/gg/watermelon.png";
import pineapple from "../../../assets/gg/pineapple.png";
import peach from "../../../assets/gg/peach.png";
import tvn from "../../../assets/gg/tvn.png";
import wrap from "../../../assets/gg/wrap-gummy.png";

// Custom components
import ProductTypeDialog from "./productSelectionDialog";
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";

const Products = () => {
    const all = {
        ananas: { product: "joW4jcPUx9pJbNr1LPc5", product12: "84g6fIX6sVpaFcMZoPFI", product24: "63gUhrB28TtsMCG2WrCj" },
        framboise: { product: "fBMg3Lgn91NVI0NjRy2s", product12: "2tKbaMESuiUpseNA5AyH", product24: "kwMzeRtsCGHVIgAflkO2" },
        melon: { product: "AVth4W7fbfff6V8RtSnP", product12: "ATvC32yac4Sm7wtfAHAX", product24: "nRW6v6VgcFJlSpD2kTap" },
        peche: { product: "98TeV5BSPKNyNK152amd", product12: "X8yrIpsGAz0xpLbABzLn", product24: "fcN4oRm5HT2bdRQLLdOz" },
    };

    // states
    const [openDialog, setOpenDialog] = useState(false);
    const [items, setItems] = useState({});
    const [value, setValue] = useState(0);
    const handleOpenDialog = () => setOpenDialog(true);

    const handleCloseDialog = () => setOpenDialog(false);

    const history = useHistory();
    // Redux
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const getNames = name => {
        switch (name) {
            case "raspberry":
                return "Framboise";
            case "watermelon":
                return "Melon d'eau";
            case "pineapple":
                return "Ananas";
            case "peach":
                return "Pêche";
            default:
                return;
        }
    };

    const getProduct = name => {
        switch (name) {
            case "raspberry":
                return setItems({
                    product: {
                        id: all.framboise.product,
                        isProduct: true,
                    },
                    product12: {
                        id: all.framboise.product12,
                        isProduct: true,
                    },
                    product24: {
                        id: all.framboise.product24,
                        isProduct: true,
                    },
                    name: "raspberry",
                });
            case "watermelon":
                return setItems({
                    product: {
                        id: all.melon.product,
                        isProduct: true,
                    },
                    product12: {
                        id: all.melon.product12,
                        isProduct: true,
                    },
                    product24: {
                        id: all.melon.product24,
                        isProduct: true,
                    },
                    name: "watermelon",
                });
            case "pineapple":
                return setItems({
                    product: {
                        id: all.ananas.product,
                        isProduct: true,
                    },
                    product12: {
                        id: all.ananas.product12,
                        isProduct: true,
                    },
                    product24: {
                        id: all.ananas.product24,
                        isProduct: true,
                    },
                    name: "pineapple",
                });
            case "peach":
                return setItems({
                    product: {
                        id: all.peche.product,
                        isProduct: true,
                    },
                    product12: {
                        id: all.peche.product12,
                        isProduct: true,
                    },
                    product24: {
                        id: all.peche.product24,
                        isProduct: true,
                    },
                    name: "peach",
                });
        }
    };

    const Product = ({ name, image, imagealt }) => {
        return (
            <div className={`product ${name} londrina`}>
                <h3 className="name">{getNames(name)}</h3>
                <img src={image} alt={imagealt} />
                <h4 className="price">À partir de 3,49$ + Txs</h4>
                <button
                    onClick={async () => {
                        handleOpenDialog();
                        getProduct(name);
                    }}
                >
                    Acheter
                </button>
            </div>
        );
    };

    return (
        <div className="products" id="produits">
            <Product name="raspberry" image={raspberry} imagealt="Go Gummy à la framboise" />
            <Product name="watermelon" image={watermelon} imagealt="Go Gummy au melon d'eau" />
            <Product name="pineapple" image={pineapple} imagealt="Go Gummy à l'ananas" />
            <Product name="peach" image={peach} imagealt="Go Gummy à la pêche" />
            <div className="tvn__left">
                <h2 className="title londrina">Valeurs Nutritives</h2>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab className="tab" label="Framboise" {...a11yProps(0)} />
                            <Tab className="tab" label="Melon d'eau" {...a11yProps(1)} />
                            <Tab className="tab" label="Ananas" {...a11yProps(2)} />
                            <Tab className="tab" label="Pêche" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel className="tabpanel" value={value} index={0}>
                        <img src={tvn} alt="" />
                        <div className="ingredients">
                            <p>
                                <b>Ingrédients: </b>isomalto-oligosaccharides, inuline, pectine, jus de fruits et de légumes (pour la couleur),
                                citrate de sodium, acide citrique, acide malique, acide lactique, extrait de feuille de stévia, huile TCM, cire de
                                carnauba, concentré de jus de fruit du moine, arôme naturel.
                            </p>
                            <p>
                                <b>Peut contenir: </b>Soya, lait, œufs, arachides, noix et sésame.
                            </p>
                        </div>
                    </TabPanel>
                    <TabPanel className="tabpanel" value={value} index={1}>
                        <img src={tvn} alt="" />
                        <div className="ingredients">
                            <p>
                                <b>Ingrédients: </b>isomalto-oligosaccharides, inuline, pectine, jus de fruits et de légumes (pour la couleur),
                                citrate de sodium, acide citrique, acide malique, acide lactique, extrait de feuille de stévia, huile TCM, cire de
                                carnauba, concentré de jus de fruit du moine, arôme naturel.
                            </p>
                            <p>
                                <b>Peut contenir: </b>Soya, lait, œufs, arachides, noix et sésame.
                            </p>
                        </div>
                    </TabPanel>
                    <TabPanel className="tabpanel" value={value} index={2}>
                        <img src={tvn} alt="" />
                        <div className="ingredients">
                            <p>
                                <b>Ingrédients: </b>isomalto-oligosaccharides, inuline, pectine, jus de fruits et de légumes (pour la couleur),
                                citrate de sodium, acide citrique, acide malique, acide lactique, extrait de feuille de stévia, huile TCM, cire de
                                carnauba, concentré de jus de fruit du moine, arôme naturel.
                            </p>
                            <p>
                                <b>Peut contenir: </b>Soya, lait, œufs, arachides, noix et sésame.
                            </p>
                        </div>
                    </TabPanel>
                    <TabPanel className="tabpanel" value={value} index={3}>
                        <img src={tvn} alt="" />
                        <div className="ingredients">
                            <p>
                                <b>Ingrédients: </b>isomalto-oligosaccharides, inuline, pectine, jus de fruits et de légumes (pour la couleur),
                                citrate de sodium, acide citrique, acide malique, acide lactique, extrait de feuille de stévia, huile TCM, cire de
                                carnauba, concentré de jus de fruit du moine, arôme naturel.
                            </p>
                            <p>
                                <b>Peut contenir: </b>Soya, lait, œufs, arachides, noix et sésame.
                            </p>
                        </div>
                    </TabPanel>
                </Box>
                <a href="#produits">
                    <button className="cta__bluesky">Acheter en ligne</button>
                </a>
            </div>
            <div className="tvn__right">
                <img src={wrap} alt="Un wrap de Go Gummy!" />
            </div>
            <ProductTypeDialog open={openDialog} onClose={handleCloseDialog} items={items} name={items.name} />
        </div>
    );
};

export default Products;
