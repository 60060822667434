import React from "react";

// Assets
import cast from "../../../assets/akt/facebook-cast.png";

const Facebook = () => {
    return (
        <div className="facebook">
            <div className="facebook__container">
                <img src={cast} alt="" className="facebook__left" />
                <div className="facebook__right">
                    <div className="facebook__content">
                        <h4 className="section__title facebook__title">TOUJOURS PAS CONVAINCU?</h4>
                        <span>
                            Rejoignez les milliers de membres du groupe Facebook... <br />”<b>ACTIVER LE CHANGEMENT AVEC JIMMY!</b>”
                        </span>
                        <ul>
                            <li>Communauté de 100,000 membres</li>
                            <li>Challenges santé</li>
                            <li>100% gratuit</li>
                            <li>Support et motivation</li>
                        </ul>
                        <a href="https://www.facebook.com/groups/1476658622351162" target="_blank"><button className="facebook__cta">Joins notre groupe facebook ici!</button></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Facebook;
