import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";

import { Grid } from "@material-ui/core";
import Left from "../../../assets/gc/sample.png";

function Sample() {
    const history = useHistory();
    const dispatch = useDispatch();

    const SAMPLE_PACK = "YDwCLZrBpKrlZGjERjMn";

    return (
        <div className="sample" id="sample">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <img src={Left} alt="" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="sample__container">
                        <span>
                            <span className="sample__title">
                                Essayez le <br />
                                'sample pack'
                            </span>
                            <span className="sample__text">
                                Goûtez à nos 6 délicieuses saveurs sans aucun engagement mensuel de votre part et de plus, obtenez la livraison
                                gratuitement! Vous aurez :
                            </span>
                            <span className="sample__text">1 x Go Choko! Chocolat Noir 73%</span>
                            <span className="sample__text">1 x Go Choko! Caramel / Sel Rose Himalayen </span>
                            <span className="sample__text">1 x Go Choko! Moka </span>
                            <span className="sample__text">1 x Go Choko! Framboise </span>
                            <span className="sample__text">1 x Go Choko! Menthe </span>
                            <span className="sample__text">1 x Go Choko! Noix de coco </span>
                            <a href="#produits">
                                <button
                                    className="sample__cta"
                                    onClick={async () => {
                                        const payload = await getRequest(`${productsRoot}/${SAMPLE_PACK}`, {});
                                        dispatch(addProduct({ ...payload.data, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    Essayez maintenant<sup>**</sup>
                                </button>
                            </a>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Sample;
