import React from "react";
import { useHistory } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
// Custom Components
import { GetHelmet, GetNavbar, getMarkerIcon, getLandingPageInfo } from "../../utils/config";
// Images
import BannerAKT from "../../assets/shared/contactBanner-akt.jpg";
import BannerMCF from "../../assets/shared/contactBanner-mcf.jpg";
import BannerGM from "../../assets/shared/contactBanner-gm.jpg";
import BannerGC from "../../assets/shared/affiliateBanner-gc.jpg";

// Icons
import { FaInstagram, FaFacebook } from "react-icons/fa";

// Components
import Fact from "../akt/home/fact";
import Footer from "../akt/home/footer";
import Lh from "../akt/home/lh";
// validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// action - requests
import { postRequest } from "../../actions/requests";
import { infoRoute } from "../../actions/contactUsRoutes";
import { useDispatch } from "react-redux";
import { apiCallBegan } from "../../store/apiCalls.js";

// configs
import { getLandingPageName } from "../../utils/config";

// const subjectChoices = ["Concernant la livraison", "Devenir détaillants", "Commentaires & autres"];
let subjectChoices = [];
if (getLandingPageName() === "Aktivation") subjectChoices = ["Commentaires & autres", "Concernant la livraison", "Corporatif"];
else subjectChoices = ["Commentaires & autres", "Concernant la livraison", "Devenir détaillants"];

const requiredMessage = "Ce champ est requis";
const infoSchema = yup.object({
    fullname: yup.string().required(requiredMessage),
    email: yup.string().email().required(requiredMessage),
    subject: yup.mixed().oneOf(subjectChoices).required(requiredMessage),
    message: yup.string().required(requiredMessage),
});

const Contact = () => {
    const dispatch = useDispatch();
    const hist = useHistory();
    const { brand, brandAcronym } = getLandingPageInfo();

    // validation
    const { register, handleSubmit, errors, control } = useForm({ resolver: yupResolver(infoSchema) });

    const onSubmit = async data => {
        dispatch(apiCallBegan({ url: infoRoute, method: "post", data: { brand: brandAcronym, ...data } }));
        hist.push("/");
    };

    const properBanner = () => {
        switch (brandAcronym) {
            case "MCF":
                return BannerMCF;
            case "GM":
                return BannerGM;
            case "AKT":
                return BannerAKT;
            case "GC":
                return BannerGC;
            default:
                return BannerAKT;
        }
    };

    return (
        <>
            <GetHelmet />
            <GetNavbar />
            {brand === "Aktivation" ? (
                <div className="akt">
                    <div className="contact">
                        <div className="contact__left">
                            <div className="contact__left--title">
                                <h3>Contactez-nous</h3>
                            </div>
                            <div className="contact__left--info">
                                <h6>Écrivez-nous</h6>
                                <a href="mailto:info@aktivation.ca">info@aktivation.ca</a>
                                <h6>Suivez-nous</h6>
                                <div className="medias">
                                    <a href="https://www.instagram.com/Aktivation.fit/" target="_blank">
                                        <FaInstagram />
                                    </a>
                                    <a href="https://www.facebook.com/aktivation.fit" target="_blank">
                                        <FaFacebook />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="contact__right">
                            <img src={BannerAKT} alt="" />
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className="contact__right--form">
                                <TextField
                                    error={!!errors.fullname?.message}
                                    helperText={errors.fullname?.message}
                                    name="fullname"
                                    label="Nom"
                                    fullWidth
                                    required
                                    inputRef={register}
                                />

                                <TextField
                                    error={!!errors.email?.message}
                                    helperText={errors.email?.message}
                                    name="email"
                                    label="Courriel"
                                    type="email"
                                    fullWidth
                                    required
                                    inputRef={register}
                                />

                                <Controller
                                    name="subject"
                                    control={control}
                                    defaultValue={subjectChoices[0]}
                                    render={props => (
                                        <FormControl fullWidth error={!!errors.subject?.message}>
                                            <InputLabel>À quel sujet?</InputLabel>
                                            <Select value={props.value} onChange={e => props.onChange(e.target.value)}>
                                                {subjectChoices.map((value, i) => (
                                                    <MenuItem key={i} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errors.subject?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />

                                <TextField
                                    helperText={errors.message?.message}
                                    error={!!errors.message?.message}
                                    name="message"
                                    multiline
                                    label="Message"
                                    rows={4}
                                    fullWidth
                                    required
                                    inputRef={register}
                                />

                                <button className="cta">Envoyer</button>
                            </form>
                        </div>
                    </div>
                    <Fact />
                    <Footer />
                    <Lh />
                </div>
            ) : (
                <div className="contact">
                    <img src={properBanner()} className="contact__background" alt="" />
                    <div className="contact__top">
                        <div className="contact__container">
                            <h3 className={`contact__title ${brand}`}>Contactez-nous</h3>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <TextField
                                    error={!!errors.fullname?.message}
                                    helperText={errors.fullname?.message}
                                    name="fullname"
                                    label="Nom"
                                    fullWidth
                                    required
                                    inputRef={register}
                                />

                                <TextField
                                    error={!!errors.email?.message}
                                    helperText={errors.email?.message}
                                    name="email"
                                    label="Courriel"
                                    type="email"
                                    fullWidth
                                    required
                                    inputRef={register}
                                />

                                <Controller
                                    name="subject"
                                    control={control}
                                    defaultValue={subjectChoices[0]}
                                    render={props => (
                                        <FormControl fullWidth error={!!errors.subject?.message}>
                                            <InputLabel>À quel sujet?</InputLabel>
                                            <Select value={props.value} onChange={e => props.onChange(e.target.value)}>
                                                {subjectChoices.map((value, i) => (
                                                    <MenuItem key={i} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errors.subject?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />

                                <TextField
                                    helperText={errors.message?.message}
                                    error={!!errors.message?.message}
                                    name="message"
                                    multiline
                                    label="Message"
                                    rows={4}
                                    fullWidth
                                    required
                                    inputRef={register}
                                />

                                <button className={`contact__cta ${brand}`}>Envoyer</button>
                            </form>
                        </div>
                    </div>
                    {brand === "Aktivation" || "Go Choko" ? (
                        ""
                    ) : (
                        <div className="contact__bottom">
                            <GoogleMap id="contact__map" zoom={10} center={{ lat: 45.427701, lng: -74.1102396 }}>
                                <Marker
                                    icon={getMarkerIcon()}
                                    position={{ lat: 45.427701, lng: -74.1102396 }}
                                    onClick={() =>
                                        window.open(
                                            "https://www.google.ca/maps/place/3100+Route+Harwood+%2330,+Vaudreuil-Dorion,+QC+J7V+5V5/@45.4277407,-74.1124819,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc935ba4993ccf7:0xcb1f8f9309869bbd!8m2!3d45.4277407!4d-74.1102932",
                                            "_blank"
                                        )
                                    }
                                />
                            </GoogleMap>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Contact;
