import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

// Material UI
import { ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Layouts
import PortalLayout from "./components/layouts/portal/portalLayout";

// Pages
import Mcf from "./components/mcf/home";
import Gm from "./components/gm/home";
import Akt from "./components/akt/home";
import Gc from "./components/gc/home";
import Gk from "./components/gk/home";
import Bs from "./components/bs/home";
import Gg from "./components/gg/home";
import Menus from "./components/bs/menus";
import How from "./components/bs/how";
import Commitment from "./components/bs/commitment";
import About from "./components/bs/about";
import Faq from "./components/bs/faq";
import Order from "./components/bs/order";

import Checkout from "./components/shared/checkout/Checkout";
import Retailers from "./components/shared/retailers";
import Recipes from "./components/shared/recipes";
import Recipe from "./components/shared/recipe";
import Profile from "./components/portal/profile/profile";
import Videos from "./components/portal/videos/videos";
import Video from "./components/portal/videos/video";
import FavoriteVideos from "./components/portal/videos/favorites";
import PortalRecipes from "./components/portal/recipes/recipes";
import PortalRecipe from "./components/portal/recipes/recipe";
import Contact from "./components/shared/contact";
import Corporate from "./components/shared/corporate";
import Affiliate from "./components/shared/affiliate";
import Trainer from "./components/akt/trainer";
import Login from "./components/portal/login/login";
import Bill from "./components/portal/profile/bill";
import TrialBill from "./components/portal/profile/trialBill";
import Boutique from "./components/portal/boutique";
import BoutiqueProduct from "./components/portal/boutique/Product";
import BoutiqueProducts from "./components/portal/boutique/Products";
import refundPolicy from "./components/shared/refundPolicy";
import Confidentiality from "./components/shared/confidentiality";

// Custom Components
import { UserContextProvider } from "./reducer/userContext";
import { PortalRoute } from "./routes/routes";
import ScrollToTop from "./components/shared/utils/scrollToTop";
import ForgotPassword from "./components/portal/changePassword/forgotPassword";
import VerifyCode from "./components/portal/changePassword/verifyCode";
import ChangePassword from "./components/portal/changePassword/changePassword";
import Notifier from "./components/shared/notifier";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Redux
import { store } from "./store/index";
import { Provider, useDispatch } from "react-redux";
import { loadProducts } from "./store/reducers/products";

// Settings
import { settings } from "./settings";
import { getStripeKey } from "./configs/stripe";
import versionObj from "./version.json";
import Analytics from "./services/analytics";
import { getLandingPageName, hostName } from "./utils/config";

// styles
import "./scss/App.scss";

// Theme
import theme from "./theme";
import { IconContext } from "react-icons";

console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

const BrandRouting = ({ BrandLP }) => {
    const stripePromise = loadStripe(getStripeKey(), {
        locale: "fr-CA",
    });
    const snackbarRef = React.createRef();
    const landingPageName = getLandingPageName();

    const handleAcceptCookies = () => {
        Analytics.init();
    };

    useEffect(() => {
        const cookies = getCookieConsentValue("CookieConsent");
        if (cookies === "true") {
            Analytics.init();
        }
    }, []);

    return (
        <Provider store={store}>
            <SnackbarProvider
                ref={snackbarRef}
                hideIconVariant
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={10000}
                action={key => (
                    <Button style={{ color: "white" }} onClick={() => snackbarRef.current.closeSnackbar(key)}>
                        FERMER
                    </Button>
                )}
            >
                <IconContext.Provider value={{ color: "#035164", style: { height: 24, width: 24 } }}>
                    <Notifier />
                    <ThemeProvider theme={theme}>
                        <SliceLoading />
                        <UserContextProvider>
                            <CookieConsent
                                buttonText="Accepter"
                                enableDeclineButton
                                declineButtonText="Refuser"
                                onAccept={handleAcceptCookies}
                                declineButtonClasses="gdpr_decline"
                                buttonClasses="gdpr_accept"
                                containerClasses="gdpr_container"
                            >
                                Afin d’améliorer votre expérience de navigation sur notre site Web ainsi que la qualité de nos contenus et de nos
                                communications, nous utilisons des témoins (appelés cookies en anglais). Ils servent principalement à assurer le bon
                                fonctionnement du site. Ils peuvent aussi porter sur vos préférences ou sur l’appareil que vous utilisez afin de vous
                                offrir une expérience Web personnalisée, mais ils ne permettent pas de vous identifier.
                                <br />
                                Dans un souci de respect à la vie privée, vous avez la possibilité de gérer vos préférences en acceptant ou refusant
                                certains types de témoins. Lire les{" "}
                                <b>
                                    <a href="./confidentialite">conditions d’utilisation</a>
                                </b>
                            </CookieConsent>
                            <Elements stripe={stripePromise}>
                                <BrowserRouter>
                                    <ScrollToTop />
                                    <Switch>
                                        <Route exact path="/" component={() => <BrandLP />} />
                                        <Redirect
                                            exact
                                            from="/portail"
                                            to={landingPageName === "Aktivation" ? "/portail/videos" : "/portail/profil"}
                                        />
                                        <PortalRoute exact path="/portail/profil" component={Profile} layout={PortalLayout} title="Profil" />
                                        <PortalRoute path="/portail/facture/:id" component={Bill} layout={PortalLayout} title="Facture" />
                                        <PortalRoute path="/portail/facture-essai/:id" component={TrialBill} layout={PortalLayout} title="Facture" />
                                        <PortalRoute
                                            exact
                                            path="/portail/recettes"
                                            component={PortalRecipes}
                                            layout={PortalLayout}
                                            title="Recettes"
                                        />
                                        <PortalRoute path="/portail/recette/:id" component={PortalRecipe} layout={PortalLayout} title="Recettes" />
                                        <PortalRoute
                                            exact
                                            path="/portail/videos"
                                            component={Videos}
                                            layout={PortalLayout}
                                            accessNeeded={["video"]}
                                            title="Vidéos"
                                        />
                                        <Route
                                            exact
                                            path="/portail/debrouillage"
                                            component={() => (
                                                <PortalLayout>
                                                    <Videos />
                                                </PortalLayout>
                                            )}
                                        />
                                        <PortalRoute
                                            path="/portail/video/:id"
                                            component={Video}
                                            layout={PortalLayout}
                                            accessNeeded={["video"]}
                                            title="Vidéo"
                                        />
                                        <Route
                                            exact
                                            path="/portail/debrouillage/:id"
                                            component={() => (
                                                <PortalLayout>
                                                    <Video />
                                                </PortalLayout>
                                            )}
                                        />
                                        <PortalRoute
                                            path="/portail/favoris/"
                                            component={FavoriteVideos}
                                            layout={PortalLayout}
                                            accessNeeded={["video"]}
                                            title="Vidéo Favorites"
                                        />
                                        <PortalRoute
                                            exact
                                            path="/portail/boutique/checkout/"
                                            component={() => <Checkout hideNav baseurl="/portail/boutique" />}
                                            layout={PortalLayout}
                                            accessNeeded={[]}
                                            title="Boutique"
                                        />
                                        <PortalRoute
                                            exact
                                            path="/portail/boutique/:id"
                                            component={() => (
                                                <Boutique showFilters={false}>
                                                    <BoutiqueProduct />
                                                </Boutique>
                                            )}
                                            layout={PortalLayout}
                                            accessNeeded={[]}
                                            title="Produit"
                                        />
                                        <PortalRoute
                                            path="/portail/boutique/"
                                            component={() => (
                                                <Boutique>
                                                    <BoutiqueProducts />
                                                </Boutique>
                                            )}
                                            layout={PortalLayout}
                                            accessNeeded={[]}
                                            title="Boutique"
                                        />
                                        <Route exact path="/portail/login" component={Login} />
                                        <Route exact path="/motDePasseOublie" component={ForgotPassword} />
                                        <Route exact path="/verificationCode" component={VerifyCode} />
                                        <Route exact path="/changementMotDePasse" component={ChangePassword} />
                                        <Route exact path="/detaillants" component={Retailers} />
                                        <Route exact path="/recettes" component={Recipes} />
                                        <Route path="/checkout" component={Checkout} />
                                        <Route path="/recette/:id" component={Recipe} />
                                        <Route path="/contact" component={Contact} />
                                        <Route path="/ambassadeurs" component={Affiliate} />
                                        <Route path="/corporatif" component={Corporate} />
                                        <Route path="/devenir-entraineur" component={Trainer} />
                                        <Route path="/menus" component={Menus} />
                                        <Route path="/how" component={How} />
                                        <Route path="/commitment" component={Commitment} />
                                        <Route path="/about" component={About} />
                                        <Route path="/faq" component={Faq} />
                                        <Route path="/order" component={Order} />
                                        <Route path="/confidentialite" component={Confidentiality} />
                                        <Route path="/politique-remboursement" component={refundPolicy} />
                                        <Redirect to="/" />
                                    </Switch>
                                </BrowserRouter>
                            </Elements>
                        </UserContextProvider>
                    </ThemeProvider>
                </IconContext.Provider>
            </SnackbarProvider>
        </Provider>
    );
};

function App() {
    // Detects the hostname and redirect the user based on brand
    const { mcf, gm, akt, gc, gk, bs, gg } = settings.landingPages;

    const components = {
        [mcf]: Mcf,
        [gm]: Gm,
        [akt]: Akt,
        [gc]: Gc,
        [gk]: Gk,
        [bs]: Bs,
        [gg]: Gg,
    };

    return <BrandRouting BrandLP={components[hostName]} />;
}

const SliceLoading = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadProducts());
    }, []);

    return "";
};

export default App;
