import React from "react";

import Nav from "../akt/home/nav";
import Footer from "../akt/home/footer";
import Brands from "../akt/home/brands";
import Lh from "../akt/home/lh";

const Confidentiality = () => {
    return (
        <div className="akt">
            <Nav />
            <div className="confidentiality">
                <h1>Politique de confidentialité</h1>

                <h2>Renseignements personnels collectés</h2>

                <span>
                    Nous ne recueillons que les renseignements personnels que vous avez consentis à nous fournir. Nous limitons la collecte de vos
                    renseignements personnels à ce qui est nécessaire aux fins de l’établissement, de la gestion et du maintien de notre relation avec
                    vous. Par exemple, des renseignements personnels pourraient être recueillis au moment de votre inscription à l’infolettre, de
                    l’achat depuis notre site, de votre facturation ou de votre livraison, de votre inscription à nos activités, ou de la soumission
                    de questions, commentaires ou suggestions, et pourraient inclure, sans s’y limiter, des renseignements de paiement ou
                    d’identification comme votre prénom, votre nom de famille, votre adresse postale, votre adresse courriel et votre numéro de
                    téléphone.
                </span>
                <h2>Utilisation prévue de ces renseignements personnels</h2>

                <span>Nous pourrions utiliser vos renseignements personnels aux fins suivantes :</span>

                <ul>
                    <li>vous identifier comme utilisateur de notre site web et comme client, le cas échéant;</li>

                    <li>établir et maintenir notre relation d’affaires avec vous;</li>
                    <li>vous fournir des services de façon continue;</li>
                    <li>
                        améliorer notre offre de produits et services, y compris l’utilisation de ce site web; prévenir les erreurs et la fraude; et
                    </li>

                    <li>nous conformer à nos obligations légales.</li>
                    <li>vous fournir de l’information sur nos produits et services susceptibles de vous intéresser;</li>
                    <li>communiquer avec vous dans le cas de questions, commentaires ou suggestions;</li>
                </ul>

                <span>
                    Vos renseignements personnels ne seront pas utilisés à d’autres fins que celles décrites plus haut à moins d’obtenir votre
                    consentement ou dans la mesure permise ou exigée par la loi.
                </span>
                <h2>Utilisation de fichiers témoins (« cookies »)</h2>
                <span>
                    Nous utilisons des cookies pour améliorer votre expérience de navigation et pour mieux comprendre comment vous utilisez notre site
                    web. Les cookies sont de petits fichiers qui sont stockés sur votre ordinateur lorsque vous visitez notre site web. Ils
                    contiennent des informations telles que la langue d'affichage et certaines habitudes de navigation. Ces données sont utilisées à
                    des fins statistiques, ainsi que pour activer certaines fonctionnalités et faciliter votre navigation. Les informations
                    recueillies par les cookies sont confidentielles en tout temps. Vous pouvez désactiver ou bloquer les cookies en configurant les
                    paramètres de votre navigateur.
                </span>

                <h2>Liens vers d’autres sites web</h2>
                <span>
                    Notre site web pourra proposer de temps à autre des hyperliens vers d’autres sites. Les renseignements échangés sur ces sites ne
                    sont pas assujettis à la présente Politique de confidentialité, mais à celles des sites externes concernés, s’il en existe. Le cas
                    échéant, nous vous invitons à les consulter ou avant de communiquer vos renseignements personnels.
                </span>
                <h2>Délais conservation</h2>
                <span>
                    Vos informations personnelles sont conservées uniquement pour le temps correspondant aux fins pour lesquelles elles ont été
                    collectées, et ce, à moins qu’une durée de conservation plus longue ne soit exigée ou autorisée par la loi.
                </span>
                <h2>Conditions d’accès, de rectification et de retrait</h2>
                <span>
                    Une fois que nous vous avons bien identifié, vous pouvez demander de consulter les renseignements personnels que nous possédons à
                    votre sujet, selon ce que la loi autorise ou exige. Dans certains cas, une demande écrite de votre part peut être nécessaire.
                </span>
                <br />
                <br />
                <span>
                    S’il y a des modifications à apporter à vos renseignements personnels, il est préférable de communiquer avec nous rapidement par
                    téléphone ou par courriel afin de maintenir à jour votre dossier et nos bases de données.
                </span>
                <h2>Coordonnées du Responsable de la Protection des Renseignements Personnels</h2>
                <span>
                    Pour toute question au sujet du traitement ou de la mise à jour de vos renseignements personnels ou pour nous faire part de toute
                    préoccupation à cet égard, veuillez communiquer avec nous par le biais de l’adresse courriel suivante :{" "}
                    <a href="mailto:vieprivee@jimmysevigny.com">vieprivee@jimmysevigny.com</a> ou par téléphone au{" "}
                    <a href="tel:+15149290991">+1 514-929-0991</a>.{" "}
                </span>
                <br />
                <br />
                <span>
                    Le responsable de la protection des renseignements personnels prendra contact avec vous dans les trente (30) jours suivant la
                    réception de votre courriel.
                </span>
                <h2>Mise à jour de la politique ci-dessus</h2>
                <span>
                    Nous pouvons modifier le contenu de la présente Politique de confidentialité à tout moment. Nous vous invitons donc à la consulter
                    de manière régulière afin de demeurer informé de la manière dont nous traitons les renseignements personnels.
                </span>
                <br />
                <br />
                <span>Cette version a été mise à jour le 18 décembre 2023.</span>
            </div>
            <Footer />
            <Brands />
            <Lh />
        </div>
    );
};

export default Confidentiality;
