import React from "react";

// Assets
import stars from "../../../assets/akt/stars-blue.svg";
import fb from "../../../assets/akt/facebook-logoo.svg";
// import plateform from "../../../assets/akt/iconbanner-plateform.png";
// import strong from "../../../assets/akt/iconbanner-strong.png";
// import phone from "../../../assets/akt/iconbanner-phone.png";
// import medal from "../../../assets/akt/iconbanner-plateform.png";

const Iconbanner = () => {
    return (
        <div className="iconbanner__container">
            <div className="iconbanner">
                <div className="container">
                    <span className="text">Plateforme d'entraînement</span>
                    <h4>#1</h4>
                    <span className="text">Au Québec</span>
                </div>
                <div className="container">
                    <span className="text">Application Google Play Store</span>
                    <h4>4.8</h4>
                    <img src={stars} alt="" />
                </div>
                <div className="container">
                    <span className="text">Application App Store</span>
                    <h4>4.5</h4>
                    <img src={stars} alt="" />
                </div>
                <div className="container">
                    <img src={fb} alt="" />
                    <h4>100 000+</h4>
                    <span className="text">Membres du groupe Facebook</span>
                </div>
            </div>
        </div>
    );
};

export default Iconbanner;
