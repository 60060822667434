import React from "react";
import Gos1 from "../../../assets/gk/footer-gc.png";
import Gos2 from "../../../assets/gk/footer-gm.png";
import Gos3 from "../../../assets/gk/footer-akt.png";
import Gos4 from "../../../assets/gk/footer-mcf.png";
import Gos5 from '../../../assets/gk/footer-gk.png';

function Gos() {
    return (
        <div className="gos">
            <h4 className="gos__title">Une gamme innovatrice complète de produits Go!</h4>
            <div className="gos__container">
                <a href="https://gochoko.com"><img src={Gos1} alt="" /></a>
                <a href="https://gomatcha.ca/" target="_blank"><img src={Gos2} alt="" /></a>
                <a href="https://gokrisp.com/" target="_blank"><img src={Gos5} alt="" /></a>
                <a href="https://aktivation.ca" target="_blank"><img src={Gos3} alt="" /></a>
                <a href="https://melangecoupefaim.com/" target="_blank"><img src={Gos4} alt="" /></a>
            </div>
        </div>
    );
}

export default Gos;