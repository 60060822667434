import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Material UI Core
import Grid from "@material-ui/core/Grid";
// Assets
import Mixte from "../../../assets/gc/gc-mixte-solo.png";
import Noir from "../../../assets/gc/gc-noir-solo.png";
import Caramel from "../../../assets/gc/gc-caramel-solo.png";
import Moka from "../../../assets/gc/gc-moka-solo.png";
import Framboise from "../../../assets/gc/gc-framboise-solo.png";
import Menthe from "../../../assets/gc/gc-menthe-solo.png";
import Coco from "../../../assets/gc/gc-coco-solo.png";
import Promo from "../../../assets/gc/gc-blackfriday.jpg";
import PromoMobile from "../../../assets/gc/gc-blackfriday.jpg";
// Custom components
import ProductTypeDialog from "./productSelectionDialog";
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";

function Products() {
    const all = {
        caramel: { product: "532btcs8w6MSUKZmaeAk", product12: "tu7jUdSFxDL0970UF5wF", product24: "y2gh8PPvjtksHFf5JcUX"},
        mixte: { product: "z2imVvg3KfLvmofQF8uC", product12: "Gb7ALZ32SvCWobQMNKUt", product24: "p0ECMKyPzogzkUMyf0pZ"},
        moka: { product: "z7AhW3DHC8I4iqmmNFJx", product12: "fl0h5izsVZdeycqn1NAK", product24: "7iuFYc4hwOwknl8TMEQo"},
        noir: { product: "hIbfmqwbrEegN0StSa6l", product12: "J4d51ZiF4wnuR0x1DMyM", product24: "1u1bBrgPj1oC76FgoBMO"},
        framboise: { product: "4kIQ2MIxrqmZV2hrGAHY", product12: "XidveOgHXJEM21bZou0Q", product24: "ScH8SToutgeFQOey7dV9"},
        menthe: { product: "7rppBDY7FxeUloSZdpQY", product12: "hyqvyPuxJzuC0MX21ZN9", product24: "URMyNsE9068TyaosphEO" },
        coco: { product: "eUehAcai4rGo6yg8RA44", product12: "kAjBFi8pzpu7Ruswjdv4", product24: "ZPjRokwWYO66AxbboNQN" },
    };

    // states
    const [openDialog, setOpenDialog] = useState(false);
    const [items, setItems] = useState({});

    const handleOpenDialog = () => setOpenDialog(true);

    const handleCloseDialog = () => setOpenDialog(false);

    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    // Products
    const BOITE_CADEAU = "30PonoFpu2zbObG3MVTP";

    return (
        <>
        {/* FEATURED ITEM */}

        <Grid item xs={12} className="promo__desktop" style={{ cursor: "pointer" }} onClick={async () => {
                                        const product = await fetchProductById(BOITE_CADEAU);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>
            <img src={Promo} alt="" />
        </Grid>

        <Grid item xs={12} className="promo__mobile" style={{ cursor: "pointer" }} onClick={async () => {
                                        const product = await fetchProductById(BOITE_CADEAU);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>
            <img src={PromoMobile} alt="" />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Grid>

        <div className="products" id="produits">
            <div className="products__container">
                <h3 className="products__title">Choisissez votre saveur</h3>
                <span className="products__subtitle">Livraison gratuite à la grandeur du Canada!</span>
                <Grid container>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.coco.product,
                                    isProduct: true,
                                    },
                                    product12: {
                                        id: all.coco.product12,
                                        isProduct: true,
                                    },
                                    product24: {
                                      id: all.coco.product24,
                                      isProduct: true,
                                    },
                                });
                            }}
                        >
                            <div className="products__top">
                                <div style={{position: "relative", textAlign: "center"}}>
                                <span className="framboise__new">NOUVEAU</span>
                                <img src={Coco}/>
                                </div>
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-noix de coco</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.menthe.product,
                                    isProduct: true,
                                },
                                  product12: {
                                    id: all.menthe.product12,
                                    isProduct: true,
                                },
                                product24: {
                                  id: all.menthe.product24,
                                  isProduct: true,
                                },
                                });
                            }}
                        >
                            <div className="products__top">
                                <div style={{position: "relative", textAlign: "center"}}>
                                <span className="framboise__new">NOUVEAU</span>
                                <img src={Menthe}/>
                                </div>
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-menthe</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.mixte.product,
                                    isProduct: true,
                                },
                                  product12: {
                                    id: all.mixte.product12,
                                    isProduct: true,
                                },
                                product24: {
                                  id: all.mixte.product24,
                                  isProduct: true,
                                },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Mixte} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">sachets mixte</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.framboise.product,
                                    isProduct: true,
                                },
                                  product12: {
                                    id: all.framboise.product12,
                                    isProduct: true,
                                },
                                product24: {
                                  id: all.framboise.product24,
                                  isProduct: true,
                                },
                                });
                            }}
                        >
                            <div className="products__top">
                                <div style={{position: "relative", textAlign: "center"}}>
                                <img src={Framboise}/>
                                </div>
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-framboise</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.noir.product,
                                    isProduct: true,
                                },
                                  product12: {
                                    id: all.noir.product12,
                                    isProduct: true,
                                },
                                product24: {
                                  id: all.noir.product24,
                                  isProduct: true,
                                },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Noir} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-noir</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.moka.product,
                                    isProduct: true,
                                },
                                  product12: {
                                    id: all.moka.product12,
                                    isProduct: true,
                                },
                                product24: {
                                  id: all.moka.product24,
                                  isProduct: true,
                                },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Moka} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-moka</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                  product: {
                                    id: all.caramel.product,
                                    isProduct: true,
                                },
                                  product12: {
                                    id: all.caramel.product12,
                                    isProduct: true,
                                },
                                product24: {
                                  id: all.caramel.product24,
                                  isProduct: true,
                                },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Caramel} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-caramel</button>
                                <button className="products__cta2">acheter maintenant</button>
                            </div>
                        </div>
                    </Grid>

            
                </Grid>
            </div>
            <ProductTypeDialog open={openDialog} onClose={handleCloseDialog} items={items} />
        </div>
        </>
    );
}

export default Products;
