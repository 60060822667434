import React from "react";
import { useHistory } from "react-router-dom";
// Assets
import Logo from "../../../assets/gc/logo-color.png";

function Nav() {
    const history = useHistory();

    const closeMenu = () => (document.getElementById("menu_toggler").checked = false);

    return (
        <>
            <div className="nav">
                <div className="nav__top">
                    {/* <span>PROMO EN COURS - ÉCONOMISEZ JUSQU'À 15%<sup>*</sup></span> */}
                    <span>GO CHOKO! EST DE RETOUR POUR UN TEMPS LIMITÉ AVEC 2 NOUVELLES SAVEURS.</span>
                </div>
                <div className="nav__main">
                    <div
                        className="nav__logo clickable__tag"
                        onClick={() => {
                            history.push("/");
                            window.scrollTo(0, 0);
                        }}
                    >
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="nav__nav">
                        <span className="clickable__tag" onClick={() => history.push("/detaillants")}>
                            Points de vente
                        </span>
                        <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                            Ambassadeurs
                        </span>
                        <a href="./#faq">
                            <span>Foire aux Questions</span>
                        </a>
                        <span className="clickable__tag" onClick={() => history.push("/portail")}>
                            Espace Client
                        </span>

                        <div className="nav__cta">
                            <a href="./#produits">
                                <button className="nav__cta2">Acheter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="nav__mobile">
                <div
                    className="nav__mobile__logo clickable__tag"
                    onClick={() => {
                        history.push("/");
                        window.scrollTo(0, 0);
                    }}
                >
                    <img src={Logo} alt="logo" />
                </div>
                <div className="menu-wrap">
                    <input id="menu_toggler" type="checkbox" className="toggler" />
                    <div className="hamburger">
                        <div></div>
                    </div>
                    <div className="menu">
                        <div>
                            <div>
                                <ul>
                                    <li>
                                        <span className="clickable__tag" onClick={() => history.push("/detaillants")}>
                                            Points de vente
                                        </span>
                                    </li>
                                    <li>
                                        <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                                            Ambassadeurs
                                        </span>
                                    </li>
                                    <li>
                                        <a href="./#faq" onClick={() => closeMenu()}>
                                            Foire aux Questions
                                        </a>
                                    </li>
                                    <div className="nav__cta">
                                        <button className="nav__cta1 clickable__tag" onClick={() => history.push("/portail")}>
                                            Espace client
                                        </button>
                                        <a href="./#produits">
                                            <button className="nav__cta2" onClick={() => closeMenu()}>
                                                Acheter
                                            </button>
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Nav;
