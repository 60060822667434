import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Mui
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// react hook form
import { useForm } from "react-hook-form";
import { emailSchema } from "./yupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
// Custom Components
import { CheckoutContext } from "./Checkout";
import { UserContext } from "../../../reducer/userContext";
import { login, resetPassword } from "../../../utils/auth";
import { getLandingPageInfo } from "../../../utils/config";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getCheckoutDetails, updateCheckoutData } from "../../../store/reducers/checkout";

// DB
import { getRequest } from "../../../actions/requests";

const Email = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // states
    const { goToNextPage } = useContext(CheckoutContext);
    const { user } = useContext(UserContext);
    const [showLogin, setShowLogin] = useState(false);
    const [password, setPassword] = useState("");
    const [loginErrors, setErrors] = useState(null);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const checkoutDetails = useSelector(getCheckoutDetails);
    const { brand } = getLandingPageInfo();

    const { register, errors, handleSubmit, getValues, reset, setValue } = useForm({
        resolver: yupResolver(emailSchema),
        defaultValues: { email: checkoutDetails.email },
    });

    const handleLoginSuccess = () => {
        setErrors(null);
        goToNextPage(history.push, history.location.pathname);
    };

    const handleLoginError = err => {
        if (err.code === "auth/wrong-password") {
            setErrors && setErrors({ message: "Mot de passe incorrect" });
        }
    };

    const onSubmit = async data => {
        if (showLogin) {
            return login(getValues("email"), password, handleLoginSuccess, handleLoginError);
        }
        if (!user) {
            try {
                const payload = await getRequest(`users/email/${data.email.toLowerCase()}`, {});

                const userExists = payload.data.exists;
                if (userExists) {
                    setValue("email", data.email.toLowerCase());
                    dispatch(updateCheckoutData({ email: data.email.toLowerCase() }));
                    return setShowLogin(true);
                }
            } catch (err) {
                console.error(err.response);
                if (err.response.status === 404) {
                    goToNextPage(history.replace, history.location.pathname, data);
                }
            }
        }
    };

    useEffect(() => reset({ email: checkoutDetails.email }), [checkoutDetails.email]);

    const displayPasswordResetContent = email => (
        <Grid container direction="column" align="center" spacing={3}>
            <Grid item>
                <Typography>
                    Un courriel a été envoyé à <b>{email}</b>
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setIsResetPassword(false);
                    }}
                >
                    OK
                </Button>
            </Grid>
        </Grid>
    );

    const displayLoginContent = () => (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h6" id="login-dialog-description" gutterBottom className={`${showLogin ? "visible" : "hidden"}`}>
                        Nous avons détecté que vous avez un compte. Veuillez vous connecter.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        error={errors.email ? true : false}
                        id="email"
                        name="email"
                        label="Courriel"
                        inputRef={register}
                        helperText={errors.email?.message}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        error={loginErrors !== null ? true : false}
                        label="Mot de passe"
                        name="password"
                        id="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        helperText={loginErrors?.message}
                        className={`${showLogin ? "visible" : "hidden"}`}
                        fullWidth
                    />
                </Grid>
                <Grid container item alignItems="flex-end" justify="flex-end" spacing={3}>
                    <Grid item>
                        <Button onClick={() => resetPassword(checkoutDetails.email, setIsResetPassword)} className={showLogin ? "visible" : "hidden"}>
                            Oublié mot de passe?
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button className={`checkout__next ${brand}`} variant="contained" type="submit">
                            {showLogin ? "Se connecter" : "Suivant"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

    useEffect(() => {
        // if user, then skip this page
        if (user) return goToNextPage(history.replace, history.location.pathname);
    }, []);

    return <>{isResetPassword ? displayPasswordResetContent(checkoutDetails.email) : displayLoginContent()}</>;
};

export default Email;
