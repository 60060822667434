import { createSlice, createAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { checkoutDataUpdated } from "../actions/checkout";
import { received as orderReceived } from "./orders";
// Slices
import { snackbarEnqueuedAction } from "./snackbars";

// utils
import { pushLimitReachedSnackbar, pushOutOfStockSnackbar, validateProduct, ProductError } from "../utils/functions";

import Analytics from "../../services/analytics";

const initialState = {
    details: {
        isBillingShippingSame: true,
    },
    products: [],
    subscriptions: [],
    duoEmail: null,
    activeAktSub: {},
    paymentCard: null,
    loading: false,
    duoUser: null,
    selectedEmail: null,
};

const sliceName = "checkoutData";
const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        requested: checkoutData => {
            checkoutData.loading = true;
        },
        requestedFailed: checkoutData => {
            checkoutData.loading = false;
        },
        updated: (checkoutData, action) => {
            checkoutData.details = {
                ...checkoutData.details,
                ...action.payload.details,
            };
        },
        productSkuUpdated: (checkoutData, action) => {
            const index = checkoutData.products.findIndex(p => p.id === action.payload.id);
            const tempProducts = [...checkoutData.products];
            tempProducts[index].selectedQuantity = parseInt(action.payload.quantity);
            tempProducts[index].selectedSku = action.payload.sku;
            checkoutData.products = tempProducts;
        },
        productUpdated: (checkoutData, action) => {
            const index = checkoutData.products.findIndex(p => p.id === action.payload.id);
            const tempProducts = [...checkoutData.products];
            tempProducts[index].selectedQuantity = parseInt(action.payload.quantity);
            checkoutData.products = tempProducts;
        },
        subscriptionUpdated: (checkoutData, action) => {
            const index = checkoutData.subscriptions.findIndex(s => s.id === action.payload.id);
            const tempSubscriptions = [...checkoutData.subscriptions];
            tempSubscriptions[index].selectedQuantity = parseInt(action.payload.quantity);
            checkoutData.subscriptions = tempSubscriptions;
        },
        subscriptionPriceUpdated: (checkoutData, action) => {
            const index = checkoutData.subscriptions.findIndex(s => s.id === action.payload.id);
            const tempSubscriptions = [...checkoutData.subscriptions];
            tempSubscriptions[index].price = action.payload.price;
            checkoutData.subscriptions = tempSubscriptions;
        },
        itemRemoved: (checkoutData, action) => {
            const { from, indices, id } = action.payload;
            if (id && from) {
                const filteredItems = checkoutData[from].filter(i => i.id !== id);
                checkoutData[from] = filteredItems;
                return;
            }

            if (Array.isArray(indices)) {
                const filteredItems = checkoutData[from].filter((_, index) => !indices.includes(index));
                checkoutData[from] = filteredItems;
                return;
            }
        },
        itemAdded: (checkoutData, action) => {
            const { item, to } = action.payload;
            checkoutData[to].push(item);
            checkoutData.loading = false;
            Analytics.add_to_cart([...checkoutData.subscriptions, ...checkoutData.products]);
        },
        productRemoved: (checkoutData, action) => {
            const { indexes, id } = action.payload;

            if (id) {
                const filteredProducts = checkoutData.products.filter(p => p.id !== id);
                checkoutData.products = filteredProducts;
                return;
            }

            // if (Array.isArray(indexes)) {
            //     console.log(indexes);
            // }
        },
        subscriptionRemoved: (checkoutData, action) => {
            const { indexes, id } = action.payload;

            if (id) {
                const filteredSubscriptions = checkoutData.subscriptions.filter(s => s.id !== id);
                checkoutData.subscriptions = filteredSubscriptions;
                return;
            }
        },
        duoEmailAdded: (checkoutData, action) => {
            checkoutData.duoEmail = action.payload;
        },
        activeAtkSubAdded: (checkoutData, action) => {
            checkoutData.activeAktSub = action.payload;
        },
        dataCleared: (checkoutData, action) => {
            for (const key in initialState) {
                checkoutData[key] = initialState[key];
            }
        },
        updateDuoUser: (checkoutData, action) => {
            checkoutData.duoUser = action.payload;
        },
        addedSelectedEmail: (checkoutData, action) => {
            checkoutData.selectedEmail = action.payload;
        },
        productSetted: (checkoutData, action) => {
            const { payload } = action;
            checkoutData.products = payload;
        },
    },
});

export default slice.reducer;

// action creators
export const updateCheckoutData = details => dispatch =>
    dispatch(
        checkoutDataUpdated({
            details,
        })
    );

export const updateDuoUser = data => dispatch => dispatch(createAction(slice.actions.updateDuoUser.type)(data));

export const addDuoEmail = email => dispatch => dispatch(createAction(slice.actions.duoEmailAdded.type)(email));

export const addActiveAktSub = data => dispatch => dispatch(createAction(slice.actions.activeAtkSubAdded.type)(data));

export const addSelectedEmail = data => dispatch => dispatch(createAction(slice.actions.addedSelectedEmail.type)(data));

export const addSubscription = subscription => async (dispatch, getState) => {
    // Check included product stocks
    if (subscription.product) {
        const selectedProduct = getState().products.list.find(p => p.id === subscription.product.id);
        if (subscription.product.quantityBundled > selectedProduct.quantity) {
            pushOutOfStockSnackbar(selectedProduct.name)(dispatch);
            return;
        }
    }

    const numSubs = getState().checkoutData.subscriptions.length;
    if (numSubs === 0) {
        dispatch(createAction(slice.actions.itemAdded.type)({ item: subscription, to: "subscriptions" }));
    } else if (numSubs > 0) {
        // Displays snackbar if there's already 1 sub in cart
        dispatch(
            snackbarEnqueuedAction({
                message:
                    "Ooopppss! Si vous désirez avoir un abonnement à un produit de plus, vous devez d'abord compléter cette transaction-ci et recommencez de nouveau 🙂",
                options: {
                    variant: "error",
                    popup: {
                        title: "Oh no!",
                    },
                },
            })
        );
    }
};

export const updateProductSku = (id, quantity, sku) => (dispatch, getState) => {
    const selectedProduct = getState().checkoutData.products.find(p => p.id === id);
    if (selectedProduct && quantity > parseInt(selectedProduct.limitQuantity)) {
        dispatch(
            snackbarEnqueuedAction({
                message: `Vous avez atteint la quantité maximale pour ${selectedProduct.name}`,
                options: {
                    variant: "error",
                },
            })
        );
    } else if (selectedProduct && selectedProduct.quantity < quantity) {
        dispatch(
            snackbarEnqueuedAction({
                message: `Il reste seulement ${selectedProduct.quantity} ${selectedProduct.name} en stock`,
                options: {
                    variant: "error",
                },
            })
        );
    } else {
        const action = createAction(slice.actions.productSkuUpdated.type);
        dispatch(action({ id, quantity, sku }));
    }
};

export const addProduct = product => (dispatch, getState) => {
    // no longer need selectedQuantity since every item will have its own entry
    // in the array of items in the order
    delete product.selectedQuantity;

    const productsInCart = getState()[sliceName].products;
    const products = getState().products.list;

    try {
        // validate products in kit, if there are any
        if (Array.isArray(product.kit) && product.kit.length > 0) {
            const kitProducts = products.filter(p => product.kit.some(kp => kp.id === p.id));
            kitProducts.forEach(kp => {
                const copiesInCart = productsInCart.filter(p => p.id === kp.id);
                validateProduct(kp, copiesInCart.length);
            });
        } else {
            const copiesInCart = productsInCart.filter(p => p.id === product.id);
            validateProduct(product, copiesInCart.length);
        }
    } catch (e) {
        if (e instanceof ProductError) {
            if (e.message === "out_of_stock") {
                pushOutOfStockSnackbar(e.product.name)(dispatch);
                return;
            }
            if (e.message === "limit_quantity") {
                pushLimitReachedSnackbar(e.product.name, e.product.limitQuantity)(dispatch);
                return;
            }
        }
    }

    dispatch(createAction(slice.actions.itemAdded.type)({ item: product, to: "products" }));
};

export const addItem =
    ({ item, quantity, addTo }) =>
    dispatch => {
        if (addTo === "products") {
            for (let i = 0; i < quantity; i++) dispatch(addProduct(item));
            return;
        }

        if (addTo === "subscriptions") {
            for (let i = 0; i < quantity; i++) dispatch(addSubscription(item));
            return;
        }
    };

export const changeItemQuantity = (item, quantity) => (dispatch, getState) => {
    const { checkoutData, products, subscriptions } = getState();

    const productList = products.list.length === 0 ? checkoutData.products : products.list;
    const subscriptionList = subscriptions.list.length === 0 ? checkoutData.subscriptions : subscriptions.list;

    const inProducts = productList.some(p => p.id === item.id);
    const inSubscriptions = subscriptionList.some(s => s.id === item.id);

    let from = [];

    if (inProducts) from = [...checkoutData.products];
    if (inSubscriptions) from = [...checkoutData.subscriptions.list];

    const indicesOfItem = from.reduce((indexes, current, index) => {
        if (typeof item.selectedSku !== "undefined" && typeof current.selectedSku !== "undefined") {
            if (item.selectedSku === current.selectedSku) return [...indexes, index];
            return indexes;
        }
        if (item.id === current.id) return [...indexes, index];
        return indexes;
    }, []);

    const diff = quantity - indicesOfItem.length;
    if (diff > 0) dispatch(addItem({ item, quantity: Math.abs(diff), addTo: inProducts ? "products" : "subscriptions" }));
    else dispatch(slice.actions.itemRemoved({ from: inProducts ? "products" : "subscriptions", indices: indicesOfItem.slice(0, Math.abs(diff)) }));
};

export const updateSubscriptionPriceById = (id, price) => dispatch => {
    const action = createAction(slice.actions.subscriptionPriceUpdated.type);
    dispatch(action({ id, price }));
};

export const deleteProductById = id => dispatch => {
    const action = createAction(slice.actions.itemRemoved.type);
    dispatch(action({ from: "products", id }));
};

export const deleteSubscriptionById = id => dispatch => {
    const action = createAction(slice.actions.itemRemoved.type);
    dispatch(action({ from: "subscriptions", id }));
};

export const clearAllData = () => dispatch => {
    dispatch(createAction(slice.actions.dataCleared.type)());
    dispatch(createAction(orderReceived.type)([]));
};

export const setProducts = payload => dispatch => dispatch({ type: slice.actions.productSetted.type, payload });

/*
 * data : {email, firstName, lastName, orderId}
 * */
// export const setGuest = data => dispatch => {
//     dispatch(apiCallBegan({ url: guestRoute, data, method: "post", onSuccess: [] }));
// };

// selectors
export const getCheckoutDetails = createSelector(
    state => state.checkoutData.details,
    details => details
);

export const getProducts = createSelector(
    state => state.checkoutData.products,
    products => products
);

export const getSubscriptions = createSelector(
    state => state.checkoutData.subscriptions,
    subscriptions => subscriptions
);

export const getLoading = createSelector(
    state => state.checkoutData.loading,
    loading => loading
);

export const getDuoEmail = createSelector(
    state => state.checkoutData.duoEmail,
    duoEmail => duoEmail
);

export const getSubscribedAktUser = createSelector(
    state => state.checkoutData.activeAktSub,
    activeAktSub => activeAktSub
);

export const getDuoUser = state => state.checkoutData.duoUser;

export const getNumProducts = createSelector(
    state => state.checkoutData.products,
    products => products.length
);

export const getSelectedEmail = state => state.checkoutData.selectedEmail;
