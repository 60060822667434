import _ from "lodash";
// Utils
import { getLandingPageName } from "../utils/config";
import { differenceInDaysBetweenDate } from "./date";

/**
 * Generates an order
 * @param {any[]} subscriptions
 * @param {any[]} products
 * @param {object} details
 * @param {object} user
 * @param {object} rebate
 * @param {object} promoCode
 * @param {string} brand
 * @param {string} brandAcronym
 * @param {string} market
 * @returns {object} order
 */
export const generateOrder = (
    subscriptions,
    products,
    details,
    user,
    promoCode,
    brand,
    brandAcronym,
    market,
    isFree = false,
    flashsaleId = undefined
) => {
    let orderItems = products.concat(subscriptions);

    orderItems = orderItems.map(i => {
        let item = {
            id: i.id,
            isSubscription: i.shippingInfo ? false : true,
            selectedSku: i.selectedSku ?? null,
            isAddOnTrial: i.isAddOnTrial ? true : false,
        };

        if (flashsaleId) item.flashsaleId = flashsaleId;
        if (i.price >= 0) item.price = i.price;

        return item;
    });

    let refId = undefined;
    let refDate = undefined;

    // Reference
    if (localStorage.getItem("ref-id") && localStorage.getItem("ref-date")) {
        refDate = new Date(parseInt(localStorage.getItem("ref-date")));

        // check if date is lower than 3 days
        if (differenceInDaysBetweenDate(refDate, new Date()) < 3) {
            refId = localStorage.getItem("ref-id");
        }
    }

    let order = {
        flashsaleId,
        isFree,
        source: getLandingPageName(),
        items: orderItems,
        // rebate,
        brand: {
            name: brand,
            acronym: brandAcronym,
        },
        market,
        billingCoordinates: details.billing,
        shippingCoordinates: details.shipping ? details.shipping : details.billing,
        user: {
            id: user ? user.id : details.email.toLowerCase(),
            firstName: details.firstName ? details.firstName : user ? user.firstName : "",
            lastName: details.lastName ? details.lastName : user ? user.lastName : "",
            email: details.email ? details.email.toLowerCase() : user ? user.email.toLowerCase() : "",
            phone: details.phone ? details.phone : user ? user.phone : "",
        },
    };

    if (refId) {
        order.reference = refId;
    }

    if(refDate){
        order.referenceDate = refDate;
    }

    if (promoCode) {
        order.promoCode = promoCode.toLowerCase();
    }

    // remove ref-id & ref-date from localStorage
    localStorage.removeItem("ref-id");
    localStorage.removeItem("ref-date");

    return order;
};

export const orderItemsComparator = (a, b) => {
    if (a.selectedSku && b.selectedSku) return a.selectedSku === b.selectedSku;
    return a.id === b.id;
};
