import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Custom components
import Table from "../../../shared/utils/table";
import ConfirmDialog from "../../../shared/utils/confirmDialog";
import Address from "../../../shared/Address";

// Utils
import { settings } from "../../../../settings";
import { formatDateTimeStripe } from "../../../../utils/date";
import { getTimestampDifferenceInDay } from "../../../../utils/parseData";

// Material UI Core
import IconButton from "@material-ui/core/IconButton";
import { Grid, Button, Menu, MenuItem, TextField } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Material UI Icon
import ClearIcon from "@material-ui/icons/Clear";

// DB
import { getRequest, postRequest, putRequest } from "../../../../actions/requests";
import { activeSubsRoot } from "../../../../actions/activeSubRoute";
import { deserialize } from "../../../../store/middleware/serializer";

// hooks
import useModal from "../../../../hooks/useModal";

const Subscriptions = ({ subscriptionId, displayedUser }) => {
    const [Modal, present, dismiss] = useModal();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const submitRef = useRef();

    const stripe = useStripe();
    const elements = useElements();
    const confirmDialog = useRef();
    const history = useHistory();
    // Snackbars
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isCardChangeDialogOpen, setIsCardChangeDialogOpen] = useState(false);
    const [selectedSub, setSelectedSub] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [other, setOther] = useState("");
    const [reason, setReason] = useState("");
    // Table
    const [sortColumn, setSortColumn] = useState({ path: "created", order: "desc" });
    const [selectedPage, setSelectedPage] = useState(0);
    // Data
    const [sortedData, setSortedData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (loading) return;
        if (!subscriptionId || !sortedData.length) return;

        // console.log(sortedData);
        const sub = sortedData.find(s => s.id === subscriptionId);
        if (!sub) return;
        setSelectedSub(sub);
        setIsCardChangeDialogOpen(true);
    }, [subscriptionId, sortedData, loading]);

    const parseStatus = rawStatus => {
        switch (rawStatus) {
            case "active":
                return "Actif";
            case "trialing":
                return "Actif";
            default:
                return "Inactif";
        }
    };

    const submitUnsubReason = async () => {
        const unsubReason = reason !== "other" ? reason : other;

        await postRequest("unsubReasons", {
            reason: unsubReason,
            userId: displayedUser.id,
        });
        handleCancelSubscription(selectedSub);
        setSelectedSub(null);
        setReason("");
        setOther("");
        setIsOpen(false);
    };

    const columns = [
        {
            path: "date",
            label: "Date création",
            body: sub => formatDateTimeStripe(sub.created * 1000),
        },
        {
            path: "name",
            label: "Nom",
            body: sub => sub.metadata.name,
        },
        {
            key: "price",
            path: "price",
            label: "Prix",
            body: sub => `${(sub.plan.amount / 100).toFixed(2)} $`,
        },
        {
            key: "status",
            path: "status",
            label: "Statut",
            body: sub => {
                if (sub.canceled_at && sub.status === "active") {
                    return "Renouvellement annulé";
                } else if (sub.pause_collection) {
                    return "Mis en pause";
                } else {
                    return parseStatus(sub.status);
                }
            },
        },
        {
            key: "Carte",
            label: "Carte",
            asyncBody: async sub => {
                const payload = await postRequest("users/getLastFourDigits", { subId: sub.id });

                return (
                    <div
                        onClick={() => {
                            setErrors(null);
                            setSelectedSub(sub);
                            setIsCardChangeDialogOpen(true);
                        }}
                        style={{ textDecoration: "underline" }}
                    >
                        **** {payload.data}
                    </div>
                );
            },
        },
        {
            key: "canceled_at",
            path: "canceled_at",
            label: "Renouvellement",
            body: sub => {
                if (sub) {
                    const remainingTrialDays = sub.trial_end && getTimestampDifferenceInDay(sub.trial_end, Date.now());
                    if (Date.now() < sub.trial_end && remainingTrialDays) {
                        return `Essai gratuit ${remainingTrialDays} jour${remainingTrialDays > 1 ? "s" : ""}`;
                    } else if (sub.canceled_at && Date.now() > sub.canceled_at * 1000) {
                        return `Terminé le ${sub.canceled_at && formatDateTimeStripe(deserialize(sub.canceled_at * 1000))}`;
                    } else if (sub.canceled_at) {
                        return `Termine le ${sub.canceled_at && formatDateTimeStripe(deserialize(sub.canceled_at * 1000))}`;
                    } else if (sub.pause_collection) {
                        return "En pause pour la saison estivale";
                    } else {
                        return `${sub.current_period_end && formatDateTimeStripe(deserialize(sub.current_period_end * 1000))}`;
                    }
                }
            },
        },
        {
            key: "menu",
            label: "Annuler",
            body: sub => {
                const aktSub = displayedUser.subscriptions.find(s => sub.id === s.stripeId);

                if (sub.canceled_at) {
                    return "Annulé";
                } else {
                    return (
                        !aktSub.masterId && (
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    setSelectedSub(sub);
                                    setIsOpen(true);
                                }}
                                style={{ padding: 0 }}
                            >
                                <ClearIcon style={{ color: "red" }} />
                            </IconButton>
                        )
                    );
                }
            },
        },
        {
            key: "options",
            body: sub => {
                return (
                    <IconButton
                        onClick={e => {
                            setMenuAnchorEl(e.currentTarget);
                            setSelectedSub(sub);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                );
            },
        },
    ];

    const handleSort = sortColumn => {
        setSortColumn(sortColumn);
    };

    const handlePageChange = (_, page) => {
        setSelectedPage(page);
    };

    const handleRowClick = async row => {
        const activeSubPayload = await getRequest(`${activeSubsRoot}/${row.id}`);
        try {
            const trialOrderPayload = await getRequest(`trialOrders/${activeSubPayload.data.lastOrderId}`);
            if (!_.isEmpty(trialOrderPayload.data)) {
                history.push(`/portail/facture-essai/${trialOrderPayload.data.id}`);
            }
        } catch (e) {
            if (e.response.status === 404) {
                const orderPayload = await getRequest(`orders/${activeSubPayload.data.lastOrderId}`);
                history.push(`/portail/facture/${orderPayload.data.id}`);
            } else {
                console.error(e);
            }
        }
    };

    const cancelSubscription = async sub => {
        try {
            const orderPayload = await getRequest(`orders/?orderNumber=${sub.metadata.orderNumber}`);
            const stripeSubCancelPayload = await postRequest(`users/cancelSub`, {
                id: sub.id,
                orderId: orderPayload.data[0].id,
            });

            if (stripeSubCancelPayload.status === 200) {
                enqueueSnackbar("Abonnement annulé", { variant: "success" });
                fetchSubscriptions();
            }
        } catch (e) {
            if (e.response.status === 404) {
                const trialOrderPayload = await getRequest(`trialOrders/?orderNumber=${sub.metadata.orderNumber}`);

                const stripeSubCancelPayload = await postRequest(`users/cancelSub`, {
                    id: sub.id,
                    orderId: trialOrderPayload.data[0].id,
                });

                if (stripeSubCancelPayload.status === 200) {
                    enqueueSnackbar("Abonnement annulé", { variant: "success" });
                    fetchSubscriptions();
                }
            }
        }
    };

    const handleCancelSubscription = sub => {
        confirmDialog.current.show({
            title: "Annulation",
            text: `Voulez-vous vraiment annuler le renouvellement de l'abonnement ${sub.metadata.name}?`,
            buttons: [
                {
                    class: "lh__button__danger",
                    text: "Oui",
                    action: () => cancelSubscription(sub),
                },
                {
                    variant: "outlined",
                    class: "secondary",
                    text: "Non",
                },
            ],
        });
    };

    const fetchSubscriptions = async () => {
        try {
            setLoading(true);
            setSortedData([]);
            let subs = [];

            if (displayedUser.subscriptions.length) {
                for (const sub of displayedUser.subscriptions) {
                    const { data } = await getRequest(`users/stripeSub/${sub.stripeId}`);
                    subs.push({ ...data, masterId: sub.masterId, slaveId: sub.slaveId });
                }
            }

            setSortedData(_.orderBy(subs, [sortColumn.path], [sortColumn.order]));
            setTotalCount(subs.length);

            setLoading(false);
        } catch (e) {
            enqueueSnackbar("Problème avec la requête", { variant: "error" });
            console.error(e);
            setSortedData([]);
            setLoading(false);
        }
    };

    const handleStripeError = error => {
        if (error.code) {
            switch (error.code) {
                case "incomplete_number":
                    setErrors({ message: "Numéro de carte incomplet" });
                    break;
                case "invalid_number":
                    setErrors({ message: "Numéro de carte invalide" });
                    break;
                case "incomplete_expiry":
                    setErrors({ message: "Date d'expiration invalide" });
                    break;
                case "incomplete_cvc":
                    setErrors({ message: "Code de sécurité invalide" });
                    break;
                case "incomplete_zip":
                    setErrors({ message: "Code postal invalide" });
                    break;
                default:
                    setErrors(error);
                    break;
            }
        }
    };

    const ErrorMessage = ({ children }) => <div className="checkout__form__error">{children}</div>;

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    useEffect(() => {
        fetchSubscriptions();
    }, [displayedUser]);

    return (
        <>
            <Modal
                buttons={[
                    {
                        text: "annuler",
                        onClick: () => {
                            dismiss();
                        },
                    },
                    {
                        text: "ok",
                        color: "primary",
                        onClick: () => {
                            submitRef.current.click();
                        },
                    },
                ]}
            >
                <Address
                    defaultValues={displayedUser.subscriptions.find(s => s.stripeId === selectedSub?.id)?.coordinates ?? displayedUser.coordinates}
                    ref={submitRef}
                    onAccept={async coordinates => {
                        const userCopy = JSON.parse(JSON.stringify(displayedUser));
                        const index = userCopy.subscriptions.findIndex(s => s.stripeId === selectedSub.id);
                        if (index === -1) return;

                        userCopy.subscriptions[index]["coordinates"] = coordinates;

                        try {
                            await putRequest(`users/${displayedUser.id}`, userCopy);
                            enqueueSnackbar("Adresse modifiée", { variant: "success" });
                        } catch (e) {
                            console.error(e);
                            enqueueSnackbar("Erreur lors de la modification. Veuillez rééssayer", { variant: "error" });
                        } finally {
                            dismiss();
                        }
                    }}
                />
            </Modal>
            <Menu keepMounted anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={handleMenuClose}>
                <MenuItem
                    onClick={() => {
                        present({ title: "Changement d'adresse" });
                        handleMenuClose();
                    }}
                >
                    Changer adresse
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setIsCardChangeDialogOpen(true);
                    }}
                >
                    Changer mode de paiement
                </MenuItem>
            </Menu>
            <Table
                columns={columns}
                sortColumn={sortColumn}
                data={sortedData}
                rowsPerPage={settings.page.rowsPerPage}
                selectedPage={selectedPage}
                totalCount={totalCount}
                loading={loading}
                paginated={false}
                sortable={false}
                rowClickable={true}
                isDoubleClick={true}
                onSort={handleSort}
                onPageChange={handlePageChange}
                onRowClick={handleRowClick}
            />
            <ConfirmDialog ref={confirmDialog} />

            <Dialog open={isCardChangeDialogOpen} onClose={() => setIsCardChangeDialogOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Changement de carte</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid spacing={1} container justify="center" alignItems="center">
                            <Grid md={12} item>
                                Voulez-vous vraiment changer la carte de l'abonnement <strong>{selectedSub?.metadata.name}</strong>?
                            </Grid>
                            <Grid md={12} item>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "16px",
                                                color: "#424770",
                                                "::placeholder": {
                                                    color: "#aab7c4",
                                                },
                                            },
                                            invalid: {
                                                color: "#9e2146",
                                            },
                                        },
                                    }}
                                />
                                {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={async () => {
                            const card = elements.getElement(CardElement);
                            if (!stripe || !elements) return;
                            const { error, paymentMethod } = await stripe.createPaymentMethod({
                                type: "card",
                                card,
                            });
                            setLoading(true);
                            if (error) {
                                handleStripeError(error);
                                setLoading(false);
                            } else {
                                setErrors(null);
                                try {
                                    await postRequest("users/changeCustomerPaymentMethod", {
                                        subId: selectedSub.id,
                                        paymentMethodId: paymentMethod.id,
                                    });
                                } catch (e) {
                                    if (e.response.data) {
                                        console.error(e.response.data);
                                        enqueueSnackbar(e.response.data, { variant: "error" });
                                    } else {
                                        console.error(e);
                                    }
                                }

                                setSelectedSub(null);
                                setLoading(false);
                                window.location.reload();
                                setIsCardChangeDialogOpen(false);
                            }
                        }}
                    >
                        Changer
                    </Button>
                    <Button variant="outlined" color="secondary" className="lh__button__danger" onClick={() => setIsCardChangeDialogOpen(false)}>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Raison du désabonnement</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuiller sélectionner la raison de votre désabonnement. Pour tout autre raison, choisissez <b>autres</b> et entrez la
                        manuellement
                    </DialogContentText>

                    <RadioGroup aria-label="gender" name="gender1" value={reason} onChange={e => setReason(e.target.value)}>
                        <FormControlLabel
                            value="Le contenu de la plateforme est insuffisant ou ne répond pas à mes attentes"
                            control={<Radio />}
                            label="Le contenu de la plateforme est insuffisant ou ne répond pas à mes attentes"
                        />
                        <FormControlLabel value="L'abonnement est trop dispendieux" control={<Radio />} label="L'abonnement est trop dispendieux" />
                        <FormControlLabel
                            value="L'application ne contient pas assez de fonctionnalités"
                            control={<Radio />}
                            label="L'application ne contient pas assez de fonctionnalités"
                        />
                        <FormControlLabel
                            value="Je préfère m'entraîner au gym ou dans des cours en présentiels"
                            control={<Radio />}
                            label="Je préfère m'entraîner au gym ou dans des cours en présentiels"
                        />
                        <FormControlLabel value="other" control={<Radio />} label="Autres (avec possibilité de précision)" />
                    </RadioGroup>

                    {reason === "other" && (
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            label="Veuillez préciser"
                            type="text"
                            value={other}
                            onChange={e => setOther(e.target.value)}
                            fullWidth
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)} color="primary">
                        Retour
                    </Button>

                    <Button onClick={submitUnsubReason} color="primary" disabled={!reason || (reason === "other" && !other)}>
                        Annuler Abonnement
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Subscriptions;
