import React from "react";

// Mui components

import IconButton from "@material-ui/core/IconButton";
import LikeIcon from "@material-ui/icons/Favorite";
import UnlikeIcon from "@material-ui/icons/FavoriteBorder";

const VideoThumbnail = ({ item, initialValue = null, onClick = null, onToggleFavorite = null }) => {
    const [isFavorite, setIsFavorite] = React.useState(() => (initialValue ? initialValue : false));
    return (
        <div className="workout__thumbnail__container">
            {onToggleFavorite && !window.location.href.includes("debrouillage") && (
                <IconButton
                    className="workouts__fav__button"
                    color="primary"
                    onClick={() => {
                        if (typeof onToggleFavorite !== "function") return;
                        const c = onToggleFavorite();
                        setIsFavorite(c);
                    }}
                >
                    <LikeIcon color="primary" style={{ display: `${isFavorite ? "" : "none"}` }} />
                    <UnlikeIcon color="primary" style={{ display: `${isFavorite ? "none" : ""}` }} />
                </IconButton>
            )}
            <img
                className="workout__thumbnail"
                onClick={() => {
                    if (onClick && typeof onClick === "function") onClick(item.index);
                }}
                src={item.thumbnail}
                alt="file thumbnail"
            />
            <span className="workout__item__title">{item.title}</span>
        </div>
    );
};

export default VideoThumbnail;
