import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Noir from '../../../assets/gc/gc-noir-solo.png';
import Moka from '../../../assets/gc/gc-moka-solo.png';
import Caramel from '../../../assets/gc/gc-caramel-solo.png';
import Framboise from '../../../assets/gc/gc-framboise-solo.png';
import Menthe from '../../../assets/gc/gc-menthe-solo.png';
import Coco from '../../../assets/gc/gc-coco-solo.png';

import NVNoir from '../../../assets/gc/nv-noire.jpg';
import NVMoka from '../../../assets/gc/nv-moka.jpg';
import NVCaramel from '../../../assets/gc/nv-caramel.jpg';
import NVFramboise from '../../../assets/gc/nv-framboise.png';
import NVMenthe from '../../../assets/gc/nv-menthe.png';
import NVCoco from '../../../assets/gc/nv-coco.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Valeurs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="valeurs">
        <h3 className="valeurs__title">Valeurs Nutritives</h3>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          className="valeurs__tabs"
        >
          <Tab label="Noir" {...a11yProps(0)} />
          <Tab label="Moka" {...a11yProps(1)} />
          <Tab label="Caramel & Sel Rose" {...a11yProps(2)} />
          <Tab label="Framboise" {...a11yProps(3)} />
          <Tab label="Menthe" {...a11yProps(4)} />
          <Tab label="Noix de coco" {...a11yProps(5)} />
        </Tabs>
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className="valeurs__tabs"
      > */}
        <TabPanel value={value} index={0} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Noir} className="valeurs__gc" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVNoir} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Chocolat noir (chocolat non sucré, sucre, beurre de cacao, lécithine de soya [émulsifiant], extrait naturel de vanille, poudre de lait écrémé), Protéine de lactosérum soufflées (Isolat de protéines de lactosérum, protéine de lactosérum concentrée, amidon de tapioca, carbonate de calcium, lécithine de tournesol (agent technologique)), Amidon de tapioca, Sucre, Glace de confiseur, Huile végétale. <br /><br /> <b>Peut contenir : </b>Noix</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Moka} className="valeurs__gc" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVMoka} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Chocolat noir (sucre, chocolat non sucré, beurre de cacao, lécithine de soya [émulsifiant], sel, arôme de vanille naturel, poudre de lait écrémé), Protéine de lactosérum soufflées (Isolat de protéines de lactosérum, protéine de lactosérum concentrée, amidon de tapioca, carbonate de calcium, lécithine de tournesol (agent technologique)), Café moulu, Amidon de tapioca, Sucre, Arôme naturel, Glace de confiseur, Huile végétale. <br /><br /> <b>Peut contenir : </b>Noix</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Caramel} className="valeurs__gc" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVCaramel} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Chocolat noir (sucre, chocolat non sucré, beurre de cacao, lécithine de soya [émulsifiant], sel, arôme de vanille naturel, poudre de lait écrémé), Protéine de lactosérum soufflées (Isolat de protéines de lactosérum, protéine de lactosérum concentrée, amidon de tapioca, carbonate de calcium, lécithine de tournesol (agent technologique)), Arôme naturel, Sel rose, Amidon de tapioca, Sucre, Glace de confiseur, Huile végétale. <br /><br /> <b>Peut contenir : </b>Noix</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Framboise} className="valeurs__gc" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVFramboise} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Chocolat noir (sucre, chocolat non sucré, beurre de cacao, lécithine de soya [émulsifiant], sel, arôme de vanille naturel, poudre de lait écrémé), Protéine de lactosérum soufflées (Isolat de protéines de lactosérum, protéine de lactosérum concentrée, amidon de tapioca, carbonate de calcium, lécithine de tournesol (agent technologique)), Arôme naturel, Sel rose, Amidon de tapioca, Sucre, Glace de confiseur, Huile végétale. <br /><br /> <b>Peut contenir : </b>Noix</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Menthe} className="valeurs__gc" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVMenthe} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Chocolat noir (chocolat non sucré, sucre, beurre de cacao, lécithine de soya, [émulsifiant], extrait naturel de vanille, poudre de lait écrémé) Isolat de protéine de lactosérum soufflées (Isolat de protéines de lactosérum, protéine de lactosérum concentrée, amidon de tapioca, carbonate de calcium et lécithine de tournesol (agent technologique). Arôme naturel. Amidon de tapioca. Sucre. Glace de confiseur, Huile végétale. <br /><br /> <b>Peut contenir : </b>Noix</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Coco} className="valeurs__gc" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVCoco} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Chocolat noir (chocolat non sucré, sucre, beurre de cacao, lécithine de soya, [émulsifiant], extrait naturel de vanille, poudre de lait écrémé) Isolat de protéine de lactosérum soufflées (Isolat de protéines de lactosérum, protéine de lactosérum concentrée, amidon de tapioca, carbonate de calcium et lécithine de tournesol (agent technologique). Arôme naturel. Amidon de tapioca. Sucre. Glace de confiseur, Huile végétale. <br /><br /> <b>Peut contenir : </b>Noix</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
      {/* </SwipeableViews> */}
    </div>
  );
}
