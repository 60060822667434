import React, { useContext } from "react";
import { useFormContext, Controller } from "react-hook-form";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Phone from "material-ui-phone-number";
// Contexts
import { UserContext } from "../../../reducer/userContext";
import { postalCodeRegex } from "../../../utils/regex";

const Address = ({ baseObjectName }) => {
    const { register, errors, setValue, getValues, control } = useFormContext();
    const { user } = useContext(UserContext);

    return (
        <>
            <Grid item xs={6}>
                <TextField
                    error={errors[baseObjectName]?.streetNumber}
                    id="streetNumber"
                    name={`${baseObjectName}.streetNumber`}
                    label="Numéro civique"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                        required: "Ce champ est requis",
                    })}
                    // inputProps={{ readOnly: true }}
                    fullWidth
                    helperText={errors[baseObjectName]?.streetNumber?.message}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="appartment"
                    name={`${baseObjectName}.appartment`}
                    label="Appartement"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register()}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    autocomplete="street-address"
                    error={errors[baseObjectName]?.street}
                    id="street"
                    name={`${baseObjectName}.street`}
                    label="Rue"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                        required: "Ce champ est requis",
                    })}
                    // inputProps={{ readOnly: true }}
                    fullWidth
                    helperText={errors[baseObjectName]?.street?.message}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    error={errors[baseObjectName]?.city}
                    id="city"
                    name={`${baseObjectName}.city`}
                    label="Ville"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                        required: "Ce champ est requis",
                    })}
                    // inputProps={{ readOnly: true }}
                    fullWidth
                    helperText={errors[baseObjectName]?.city?.message}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl required fullWidth error={errors[baseObjectName]?.province} helperText={errors[baseObjectName]?.province?.message}>
                    <InputLabel id="regionId" style={{ visibility: "hidden" }}>
                        Province
                    </InputLabel>
                    <Select
                        id="province"
                        name={`${baseObjectName}.province`}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            inputRef: ref => {
                                if (!ref) return;
                                register({
                                    required: "Ce champ est requis",
                                    name: `${baseObjectName}.province`,
                                    value: ref.value,
                                });
                            },
                        }}
                        defaultValue={user && user.coordinates ? user.coordinates.province.toLowerCase() : "québec"}
                        value={getValues(`${baseObjectName}.province`)}
                        onChange={e => setValue(`${baseObjectName}.province`, e.target.value)}
                    >
                        <MenuItem value="québec">QC</MenuItem>
                        <MenuItem value="ontario">ON</MenuItem>
                        <MenuItem value="alberta">AB</MenuItem>
                        <MenuItem value="british columbia">BC</MenuItem>
                        <MenuItem value="manitoba">MB</MenuItem>
                        <MenuItem value="new brunswick">NB</MenuItem>
                        <MenuItem value="newfoundland and labrador">NL</MenuItem>
                        <MenuItem value="northwest territories">NT</MenuItem>
                        <MenuItem value="nova scotia">NS</MenuItem>
                        <MenuItem value="nunavut">NU</MenuItem>
                        <MenuItem value="prince edward island">PE</MenuItem>
                        <MenuItem value="saskatchewan">SK</MenuItem>
                        <MenuItem value="yukon">YT</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    autocomplete="postal-code"
                    error={errors[baseObjectName]?.postalCode}
                    id="postalCode"
                    name={`${baseObjectName}.postalCode`}
                    label="Code Postal"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                        required: "Ce champ est requis",
                        pattern: { value: postalCodeRegex, message: "Veuillez corriger le format (A1B 2C3)" },
                    })}
                    fullWidth
                    helperText={errors[baseObjectName]?.postalCode?.message}
                    onChange={e => setValue(`${baseObjectName}.postalCode`, e.target.value)}
                    onBlur={() => {
                        let postalCode = getValues(`${baseObjectName}.postalCode`);
                        postalCode = postalCode.toUpperCase();

                        if (postalCode[3] !== " ") {
                            let localPostalCode = postalCode;

                            localPostalCode = localPostalCode.slice(0, 3) + " " + localPostalCode.slice(3);
                            setValue(`${baseObjectName}.postalCode`, localPostalCode);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    error={errors[baseObjectName]?.country}
                    autocomplete="country"
                    id="country"
                    name={`${baseObjectName}.country`}
                    label="Pays"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                        required: "Ce champ est requis",
                    })}
                    value="Canada"
                    inputProps={{ readOnly: true }}
                    fullWidth
                    helperText={errors[baseObjectName]?.country?.message}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    control={control}
                    name={`${baseObjectName}.phone`}
                    render={props => (
                        <Phone
                            autocomplete="tel"
                            value={props.value}
                            onChange={e => props.onChange(e)}
                            defaultCountry={"ca"}
                            onlyCountries={["ca"]}
                            disableAreaCodes
                            error={errors[baseObjectName]?.phone}
                            id="phone"
                            label="Téléphone"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            helperText={errors[baseObjectName]?.phone?.message}
                        />
                    )}
                />
            </Grid>
        </>
    );
};

export default Address;
