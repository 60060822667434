import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const authProd = {
    apiKey: "AIzaSyCVhDNUpHLXEWssmaZ5wsp3BuGQwuwlUAk",
    authDomain: "jimmysevigny-ed601.firebaseapp.com",
    databaseURL: "https://jimmysevigny-ed601.firebaseio.com",
    projectId: "jimmysevigny-ed601",
    storageBucket: "jimmysevigny-ed601.appspot.com",
    messagingSenderId: "755089549943",
    appId: "1:755089549943:web:904e4f8be5b4472e677472",
};

const authDev = {
    apiKey: "AIzaSyDUWwcG0G01NVE4c8gRZz42zV1bIsLrWzU",
    authDomain: "jimmysevigny-dev.firebaseapp.com",
    databaseURL: "https://jimmysevigny-dev-default-rtdb.firebaseio.com",
    projectId: "jimmysevigny-dev",
    storageBucket: "jimmysevigny-dev.appspot.com",
    messagingSenderId: "558258057935",
    appId: "1:558258057935:web:19ecf9f30fc96323ccfdba",
};

const firebaseApp = firebase.initializeApp(process.env.REACT_APP_LH_ENV === "production" ? authProd : authProd);

export const auth = firebaseApp.auth();
export const ga = firebase.analytics();
export const firestore = firebase.firestore();
export default firebase;
