import React, { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@material-ui/core/Grid";

// Redux
import useSlice from "../../../hooks/useSlice";

// System wide state from redux

// Custom Components
import { GetPortalHelmet } from "../../../utils/config";
import { getSplashScreenImage } from "../../../utils/config";
import SplashScreen from "../../shared/utils/splashScreen";
import { getRequest } from "../../../actions/requests";
import { useSnackbar } from "notistack";
import { auth } from "../../../configs/firebase";
import useGetUser from "../../../general/useGetUser";
import { UserContext } from "../../../reducer/userContext";


const Videos = () => {
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { userEnteredUnscramlingEmail, user } = useContext(UserContext);

    const workoutSlice = useSlice({ sliceName: "workouts" });
    const workouts = workoutSlice.selectors.all;

    useEffect(() => {
        workoutSlice.actionCreators.fetchFn({ refresh: true });
    }, []);

    const releasedWorkouts = useMemo(() => {
        if (!workouts) return [];
        return workouts.filter(w => {
            const release = new Date(w.release._value);
            return Date.now() - release > 0;
        });
    }, [workouts]);

    const [featured, ...rest] = useMemo(() => {
        if (!releasedWorkouts) return [];
        return JSON.parse(JSON.stringify(releasedWorkouts)).sort((a, b) => {
            const createdAtA = new Date(a.createdAt._value).getTime();
            const createdAtB = new Date(b.createdAt._value).getTime();

            return createdAtB - createdAtA;
        });
    }, [releasedWorkouts]);

    const checkUnscrambled = async () => {
        if (!auth.currentUser || auth.currentUser && !user.hasVideoAccess) {
            const payload = await getRequest("unscramblings/isUnscrambled");
            if (!userEnteredUnscramlingEmail || !payload.data || !payload.data.isUnscrambled) {
                enqueueSnackbar("Accès refusé", { variant: "error" });
                hist.push("/portail/login");
            }
        }
    };

    // check if unscrambled
    useEffect(() => {
        checkUnscrambled();
    }, []);

    if (!workouts || !featured) return <SplashScreen image={getSplashScreenImage()} />;

    return (
        <>
            <GetPortalHelmet title="Vidéos" />
            <div
                className="workouts__banner clickable__tag"
                onClick={() => {
                    if (window.location.href.includes("debrouillage")) {
                        hist.push(`/portail/debrouillage/${featured.id}`);
                    } else {
                        hist.push(`/portail/video/${featured.id}`);
                    }
                }}
            >
                <img src={featured.bannerImage} alt="workouts banner" />
            </div>
            <h1 className="workouts__header">Programmes</h1>
            <Grid container>
                {rest
                    .filter(w => {
                        const release = new Date(w.release._value);
                        return Date.now() - release > 0;
                    })
                    .map((w, i) => {
                        return (
                            <Grid xs={12} sm={6} md={4} className="workouts__thumbnail" item key={i}>
                                <img
                                    onClick={() => {
                                        if (window.location.href.includes("debrouillage")) {
                                            hist.push(`/portail/debrouillage/${w.id}`);
                                        } else {
                                            hist.push(`/portail/video/${w.id}`);
                                        }
                                    }}
                                    src={w.profileImage}
                                    alt="workout"
                                />
                                <h1 className="workouts__title">{w.title}</h1>
                                <p className="workouts__description">{w.shortDescription}</p>
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
};

export default Videos;
