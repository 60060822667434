import React from "react";

// Components
import Promo from "./home/promo";
import Nav from "./home/nav";
import Hero from "./home/hero";
import Sugar from "./home/sugar";
import Ratio from "./home/ratio";
import Opening from "./home/opening";
import Products from "./home/products";
import Sample from "./home/sample";
import Recommandation from "./home/recommandation";
import Info from "./home/info";
import Faq from "./home/faq";
import Try from "./home/try";
import Footer from "./home/footer";
import Bottom from "./home/bottom";
import Gos from './home/gos';
// Custom Components
import { GetHelmet } from "../../utils/config";

const home = () => {
    return (
        <div className="gg">
            <GetHelmet />
            <Promo />
            <Nav />
            <Hero />
            <Sugar />
            <Ratio />
            <Opening />
            <Products />
            <Sample />
            <Recommandation />
            <Info />
            <Faq />
            <Try />
            <Gos />
            <Footer />
            <Bottom />
        </div>
    );
};

export default home;
