import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useModal from "../useModal";

import Promo1 from "../../assets/akt/promo_corde.jpg";
import Promo2 from "../../assets/akt/promo_matcha.jpg";
import Promo3 from "../../assets/akt/promo_livre.jpg";

export default function useAds() {
    const [Modal, present, dismiss] = useModal();
    const h = useHistory();
    const params = new URLSearchParams(h.location.search);


    const promo = params.get("promo");


    // useEffect(() => {
    //   if (promo == "incroyable") {
    //     present();
    //   }
    // }, []);

    const onClick = () => {
        dismiss();
        window.location.hash = "#produits";
    };

    // if (promo !== "incroyable") return null;

    const Component = () => (
        <Modal 
          maxWidth={false}
        >
            <div className="promo__modal">
              <h3 className="promo__title">Voici les 3 cadeaux possibles</h3>
              <div className="promo__container">
                  <div className="promo__item">
                    <h4 className="promo__item__title"></h4>
                    <img src={Promo1} />
                    <p>Corde à sauter lestée professionnelle avec étui de transport de luxe <br/><br/><b>(Valeur de 99$)</b></p>
                  </div>
                  <div className="promo__item">
                    <h4 className="promo__item__title"></h4>
                    <img src={Promo2} />
                    <p>3 Boîtes de Go Matcha! <br /> Canne de bonbons, Vanille festive & Tarte aux pommes  <br/><br/><b>(Valeur de 89.97$)</b></p>
                  </div>
                  <div className="promo__item">
                    <h4 className="promo__item__title"></h4>
                    <img src={Promo3} />
                    <p>Livre ReSet + Agenda Aktiv 365 + Boite Go Matcha! choix du chef (aucune substitution possible) <br/><br/><b>(Valeur de 94,97$)</b></p>
                  </div>
              </div>
              <button variant="contained" className="promo__cta" onClick={onClick}>
                  M'inscrire et obtenir mon cadeau
              </button>
            </div>
        </Modal>
    );

    const show = () => {
        present();
    };

    return { Component, show };
}
