import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
// Mui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// Redux
import { useDispatch } from "react-redux";
import { addProduct, addSubscription } from "../../../store/reducers/checkout";
// Firestore
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
import { subscriptionsRoot } from "../../../actions/subscriptionsRoute";
// Custom Components
import { getLandingPageInfo } from "../../../utils/config";
// Utils
import { fetchProductById } from "../../../utils/apiCalls";

let images = [];

const ProductTypeSelectionDialog = ({ open, onClose, items }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { brand } = getLandingPageInfo();
    const [product, setProduct] = useState({});

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    if (!_.isEmpty(product) && product.imageURLs) {
        images = product.imageURLs.reduce((acc, value) => {
            acc.push(<img src={value} alt="product" className="productpop__image" style={{ width: "100%" }} />);
            return acc;
        }, []);
    }

    const handleOnContinue = async item => {
        try {
            if (item.isProduct) {
                const status = product.isPhysical ? "À livrer" : "Expédié";
                const productPayload = await getRequest(`${productsRoot}/${item.id}`);
                const productFound = productPayload.data;
                dispatch(addProduct({ ...productFound, selectedQuantity: 1, status }));
            } else {
                const subscriptionPayload = await getRequest(`${subscriptionsRoot}/${item.id}`);
                const subscription = subscriptionPayload.data;
                dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
            }
            history.push(`/checkout`);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(async () => {
        if (!_.isEmpty(items)) setProduct(await fetchProductById(items.product.id));
    }, [items]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="selection-dialog-title" className="productpop">
            <DialogTitle id="selection-dialog-title" className={`productpop__title ${brand}`}>
                {!_.isEmpty(product) && product.name}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className="productpop__images__container">
                        <Slider {...settings} autoplay>
                            {images.map((item, key) => (
                                <div key={key}>
                                    {item && item.props && item.props.src && (
                                        <img src={`${item.props.src}`} alt="product" className="productpop__image" />
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </Grid>
                    <Grid item xs={12} sm={6} className={`productpop__info__container ${brand}`}>
                        <div className="productpop__desc">{!_.isEmpty(product) && parse(draftToHtml(JSON.parse(product.description)))}</div>
                        <div>
                          <Button
                                className={`productpop__cta ${brand}`}
                                variant="contained"
                                onClick={() => {
                                    handleOnContinue(items.product12);
                                }}
                            >
                                Liberté sans abonnement 12 sachets {product && " (44.88$ - Régulier 47.88$)"}
                            </Button>
                            <Button
                                className={`productpop__cta ${brand}`}
                                variant="contained"
                                onClick={() => {
                                    handleOnContinue(items.product24);
                                }}
                            >
                                Liberté sans abonnement 24 sachets {product && " (83.88$ - Régulier 95.76$)"}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ProductTypeSelectionDialog;
