import React from "react";

// React-router-dom
import { useHistory } from "react-router-dom";

// Custom Components
import { GetHelmet, GetNavbar } from "../../utils/config";

// Images
import BannerAKT from "../../assets/shared/contactBanner-akt.jpg";
import tnt from "../../assets/akt/corporate-tnt.png";

// Components
import Fact from "../akt/home/fact";
import Footer from "../akt/home/footer";
import Lh from "../akt/home/lh";

const Corporate = () => {
    let history = useHistory();
    return (
        <>
            <GetHelmet />
            <GetNavbar />
            <div className="corporate">
                <div className="akt">
                    <div className="contact">
                        <div className="contact__left">
                            <div className="contact__left--title">
                                <h3>Corporatif</h3>
                            </div>
                            <div className="contact__left--image">
                                <img src={tnt} alt="" />
                            </div>
                        </div>
                        <div className="contact__right">
                            <img src={BannerAKT} alt="" />
                            <div className="contact__right--content">
                                <h5 className="contact__right--title">Vos employés méritent mieux qu'un simple abonnement au gym.</h5>
                                <p className="contact__right--text">
                                    Améliorez la santé et la productivité de votre équipe avec AKTIVATION, la plateforme numéro un pour l'entraînement
                                    en ligne au Québec.
                                </p>
                                <ul>
                                    <li>Offrez à vos employés un accès illimité à une vaste bibliothèque de plus de 285 entraînements</li>
                                    <li>Flexible et accessible : Disponible 24/7 via une app compatible iOS et Android</li>
                                    <li>Profitez de nos offres exclusive pour les entreprises</li>
                                </ul>
                                <p className="contact__right--text">
                                    Investissez dans la santé physique et mentale de vos employés pour aussi peu que 10$ par mois!
                                </p>
                                <p className="contact__right--text">
                                    Aktivation est LA solution à votre programme de bien-être innovant en entreprise.
                                </p>
                                <p className="contact__right--text">Contactez-nous dès maintenant!</p>
                                <button onClick={() => history.push("/contact")}>Contactez-nous</button>
                            </div>
                        </div>
                    </div>
                    <Fact />
                    <Footer />
                    <Lh />
                </div>
            </div>
        </>
    );
};

export default Corporate;
