import React from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

// validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Custom Components
import { GetHelmet, GetNavbar, getLandingPageInfo } from "../../utils/config";
// Images
import BannerAKT from "../../assets/shared/trainerBanner-akt.jpg";

// action - requests
import { postRequest } from "../../actions/requests";
import { trainerRoute } from "../../actions/contactUsRoutes";
import { useDispatch } from "react-redux";
import { apiCallBegan } from "../../store/apiCalls";

// yup validation
const requiredMessage = "Ce champ est requis";
const mustBeNumberMessage = "Veuillez inscrire un nombre valide";
const validAgeMessage = "Veuillez inscrire un age valide";

const emptyStringToNull = (value, originalValue) => {
    if (typeof originalValue === "string" && originalValue === "") return null;
    return value;
};

const affiliateValidationSchema = yup.object({
    fullname: yup.string().required(requiredMessage),
    email: yup.string().email("Courriel invalide").required(requiredMessage),
    phone: yup
        .string()
        .matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, "Numéro de téléphone invalide")
        .required(requiredMessage),
    age: yup.number().typeError(mustBeNumberMessage).positive(validAgeMessage).integer(validAgeMessage).required(requiredMessage),
    address: yup.string().required(requiredMessage),
    usesInstagram: yup.boolean(),
    usesFacebook: yup.boolean(),
    usesTiktok: yup.boolean(),
    instagramLink: yup.string(),
    instagramFollowers: yup
        .number()
        .typeError(mustBeNumberMessage)
        .positive(mustBeNumberMessage)
        .integer(mustBeNumberMessage)
        .transform(emptyStringToNull)
        .nullable(),
        instagramAvgStoryViews: yup
        .number()
        .typeError(mustBeNumberMessage)
        .positive(mustBeNumberMessage)
        .integer(mustBeNumberMessage)
        .transform(emptyStringToNull)
        .nullable(),
        facebookLink: yup.string(),
        facebookFollowers: yup
        .number()
        .typeError(mustBeNumberMessage)
        .positive(mustBeNumberMessage)
        .integer(mustBeNumberMessage)
        .transform(emptyStringToNull)
        .nullable(),
        tiktokLink: yup.string(),
        tiktokFollowers: yup
            .number()
            .typeError(mustBeNumberMessage)
            .positive(mustBeNumberMessage)
            .integer(mustBeNumberMessage)
            .transform(emptyStringToNull)
            .nullable(),
    collaboratorsText: yup.string().required(requiredMessage),
    workWithUsText: yup.string(),
    certifications: yup.string().required(requiredMessage),
    workoutYears: yup.string().required(requiredMessage),
    specialties: yup.string().required(requiredMessage),
});

const Trainer = () => {
    const dispatch = useDispatch();
    const hist = useHistory();
    const { brand, brandAcronym } = getLandingPageInfo();

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(affiliateValidationSchema) });

    const onSubmit = data => {
        dispatch(apiCallBegan({ url: trainerRoute, method: "post", data: { ...data, brandAcronym } }));
        hist.push("/");
    };

    return (
        <>
            <GetHelmet />
            <GetNavbar />
            <div className="affiliate">
                <img src={BannerAKT} className="affiliate__background" alt="" />
                <div className="affiliate__top">
                    <div className={`affiliate__container ${brand}`}>
                        <h3 className={`affiliate__title ${brand}`}>
                            Vous avez une passion pour l’entraînement et vous aimeriez vous faire connaitre afin de rayonner à un niveau jamais égalé?
                            Vous pourriez avoir une place de choix au sein de notre équipe! Nous sommes toujours à la recherche de gens talentueux qui
                            ont le désir d’avancer dans le milieu de l’entraînement et des saines habitudes de vie. Afin de postuler, veuillez remplir
                            les champs suivants. À noter que seules les personnes retenues seront contactées.
                        </h3>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                helperText={errors.fullname?.message}
                                error={!!errors.fullname?.message}
                                label="Nom Complet"
                                name="fullname"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.email?.message}
                                error={!!errors.email?.message}
                                label="Courriel"
                                type="email"
                                name="email"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.phone?.message}
                                error={!!errors.phone?.message}
                                label="Téléphone"
                                type="phone"
                                name="phone"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.age?.message}
                                error={!!errors.age?.message}
                                label="Age"
                                type="number"
                                name="age"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.address?.message}
                                error={!!errors.address?.message}
                                label="Adresse"
                                name="address"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.certifications?.message}
                                error={!!errors.certifications?.message}
                                multiline
                                label="Quelles sont les formations académiques / certifications que vous détenez ?"
                                rows={12}
                                fullWidth
                                required
                                name="certifications"
                                inputRef={register}
                            />

                            <TextField
                                helperText={errors.workoutYears?.message}
                                error={!!errors.workoutYears?.message}
                                inputRef={register}
                                name="workoutYears"
                                label="Depuis combien d’années êtes-vous dans le milieu de l’entraînement?"
                                fullWidth
                                required
                                type="number"
                            />

                            <TextField
                                helperText={errors.specialties?.message}
                                error={!!errors.specialties?.message}
                                multiline
                                label="Quelle est votre spécialité ? (exemple : fonctionnel, aérobie, bootcamp etc…)"
                                rows={12}
                                fullWidth
                                required
                                name="specialties"
                                inputRef={register}
                            />

                            <FormLabel component="legend">Quelles sont les plateformes que vous utilisez?</FormLabel>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox inputRef={register} name="usesInstagram" />} label="Instagram" />
                                <FormControlLabel control={<Checkbox inputRef={register} name="usesFacebook" />} label="Facebook" />
                                <FormControlLabel control={<Checkbox inputRef={register} name="usesTiktok" />} label="Tik Tok" />
                            </FormGroup>

                            <TextField inputRef={register} name="instagramLink" label="Lien Instagram" fullWidth />

                            <TextField
                                helperText={errors.instagramFollowers?.message}
                                error={!!errors.instagramFollowers?.message}
                                inputRef={register}
                                name="instagramFollowers"
                                label="Nombre de fans Instagram"
                                fullWidth
                                type="number"
                            />

                            <TextField
                                helperText={errors.instagramAvgStoryViews?.message}
                                error={!!errors.instagramAvgStoryViews?.message}
                                inputRef={register}
                                name="instagramAvgStoryViews"
                                label="Vue moyennes de vos stories Instagram"
                                fullWidth
                                type="number"
                            />

                            <TextField inputRef={register} name="facebookLink" label="Lien Facebook" fullWidth />

                            <TextField
                                helperText={errors.facebookFollowers?.message}
                                error={!!errors.facebookFollowers?.message}
                                inputRef={register}
                                name="facebookFollowers"
                                label="Nombre de fans Facebook"
                                fullWidth
                                type="number"
                            />

                            <TextField inputRef={register} name="tiktokLink" label="Lien TikTok" fullWidth />

                            <TextField
                                helperText={errors.tiktokFollowers?.message}
                                error={!!errors.tiktokFollowers?.message}
                                inputRef={register}
                                name="tiktokFollowers"
                                label="Nombre de fans TikTok"
                                fullWidth
                                type="number"
                            />

                            <TextField
                                helperText={errors.collaboratorsText?.message}
                                error={!!errors.collaboratorsText?.message}
                                multiline
                                label="Présentement, avez-vous des partenariats/collaborations rémunérés avec des compagnies? Si oui, lesquelles ? "
                                rows={4}
                                fullWidth
                                required
                                name="collaboratorsText"
                                inputRef={register}
                            />

                            <button className={`affiliate__cta ${brand}`}>Soumettre</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Trainer;
