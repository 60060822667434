import React from "react";

import useFetch from "../../../../hooks/useFetch";
import useAds from "../../../../hooks/useAds";

const Promo = () => {
    // const ad = useAds();
    const [aktPresentation] = useFetch("Presentations/akt");

    return (
        <div className="promo">
            {/* {ad?.Component ? <ad.Component dismiss={ad?.dimiss} /> : null} */}
            <a href="./#produits" className="promo__text">
                {/* <div className="promo__text" style={{ cursor: "pointer" }} onClick={ad?.show} > */}
                <p>{aktPresentation?.content.banner}</p>
                {/* <p className="kanit">ÉCONOMISEZ 57% sur le forfait MENSUEL en utilisante le code TONE</p>*/}
                {/* </div> */}
            </a>
        </div>
    );
};

export default Promo;
