import React, { useEffect, useState } from "react";

const Hero = () => {
    // useEffect(() => {
    //     const player = window.jwplayer(jwpTag);
    //     if (file) player.setup({ file, cast: {}, repeat: true, controls: false });
    // }, []);

    return (
        <div className="hero">
            <h1>
                Activez le <br /><span>Changement</span>
            </h1>
            <a href="./#produits">
                <button className="hero__cta">COMMENCEZ VOTRE ESSAI GRATUIT DE 14 JOURS</button>
            </a>
        </div>
    );
};

export default Hero;
