import React from "react";

/**
 * Settings file that contains globally used values
 */
export const settings = {
    page: {
        rowsPerPage: 10,
        drawerWidth: 140,
        mobileDrawerWidth: 175,
        maxScreenWidth: 960,
    },
    order: {
        TPS_NUMBER: "80093 5595 RT0001",
        TVQ_NUMBER: "1222763491 TQ 0001",
        statuses: ["En traitement", "En attente", "En retour", "Retourné", "Remboursé", "Expédié", "À livrer"],
        MAX_UPSELL: 2,
    },
    urls: {
        baseApi: "/api/v1",
        recipes: "recipes",
        orders: "orders",
        users: "users",
        markets: "markets",
        brands: "brands",
        products: "products",
        stores: "stores",
        promoCodes: "promocodes",
        subscriptions: "subscriptions",
        affiliate: "affiliate",
        workouts: "workouts",
        aktSubscribedUsers: "aktSubscribedUsers",
        flashSales: "flashSales",
        transactions: "transactions",
    },
    serverUrls: {
        dev: "http://localhost:3002",
        production: "https://js-backend.leadhouse.app",
        productionTest: "https://js-backend.leadhouse.xyz",
    },
    snackbars: {
        snackbarSeverity: ["error", "warning", "info", "success"],
        autoHideDuration: 4000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
    },
    stripe: {
        devKey: "pk_test_51HX6rOAlwHLGY552sD5fS2Jg73dxsr7oiK4ZkscH19Xj2fEep44eaRUKuU0cHTU4UjLFdJQs7H9pEJEBt0mrIj5z00qRx60lwC",
        prodKey: "pk_live_51IIcc0JTpZFo8HALfnFuYVT9MEAuQIt5SY1HnBbyapKwN2Q9GuQiKceLRvBaMad1pag3qGIBwLaDByamdGGW5cBj00Nsgq55O9",
    },
    landingPages: {
        mcf: "melangecoupefaim.com",
        gm: "gomatcha.ca",
        akt: "aktivation.ca",
        gc: "gochoko.com",
        gk: "gokrisp.com",
        bs: "test-bs.leadhouse.app",
        gg: "gogummy.ca"
    },
    shipping: {
        originZip: "J7V8P2",
    },
    stepper: {
        maxLabelSteps: 5,
    },
    encryption: {
        secret: "gGSIv1MjLJRhRXrcJD4D",
    },
    videoAccess: ["b0ohOyDVJkmxsCaVVWQO", "9RPkVos0gMqxQgxRx2C7", "TdWWNjaYrD2Rhc95tkFv"],
    staticURLs: {
        akt: "https://aktivation.ca/#produits",
        gm: "https://gomatcha.ca/#produits",
        mcf: "https://melangecoupefaim.com/#produits",
        gc: "https://gochoko.com/#produits",
        gk: "https://gokrisp.com/#produits",
        gg: "https://gogummy.ca/#produits"
    },
    taxes: {
        federal: 5,
        provincial: {
            alberta: 0,
            britishColumbia: 7,
            manitoba: 7,
            newBrunswick: 10,
            newFoundLandLabrador: 10,
            northwestTerritories: 0,
            novascotia: 10,
            nunavut: 0,
            ontario: 8,
            princeEdwardIsland: 10,
            quebec: 9.975,
            saskatchewan: 6,
            yukon: 0,
        },
    },
    status: { toDeliver: "À livrer", sent: "Expédié" },
    aktExistingSub: (
        <p className="akt__subscribed__user">
            <div> ATTENTION LA PLATEFORME AKTIVATION A MIGRÉE VERS UN NOUVEAU SYSTÈME</div>
            <br />
            <div>Un courriel détaillé vous a été envoyé à ce sujet, merci de valider votre boîte de courriels / indésirables.</div>
            <br />
            <div>
                Si vous avez un abonnement toujours actif sur la plateforme, vous devez vous inscrire à nouveau, mais rien ne sera prélevé avant votre
                date de renouvellement prévue dans l'ancien système.{" "}
            </div>
            <br />
            <div>Voici la procédure à suivre:</div>
            <div style={{ textAlign: "left" }}>
                <b>1-</b> Cliquez sur le bouton ACTIVER UN FORFAIT
            </div>
            <div style={{ textAlign: "left" }}>
                <b>2-</b> Cliquez sur le bouton 14 jours gratuits ou descendez la page vers le bas pour voir les forfaits
            </div>
            <div style={{ textAlign: "left" }}>
                <b>3-</b> Sélectionnez votre forfait qui est toujours actif{" "}
            </div>
            <div style={{ textAlign: "left" }}>
                <b>4-</b> Complétez la transaction. Le nombre de jours restant à votre abonnement sera indiqué en rouge lors de votre transaction et
                le prix indiqué sera le prix de votre renouvellement s'il est toujours actif dans votre compte.
            </div>
            <br />
            <div>Si votre abonnement n'est plus actif, simplement activer le forfait désiré.</div>
        </p>
    ),
};
