import React from "react";
import StoreIcon from "@material-ui/icons/Store";

function ContinueShopping({ brand }) {
    return (
        <a href="/">
            <div className={`continue__shop ${brand} clickable__tag`}>
                <div className="continue__shop__container">
                    <StoreIcon />
                    {/* <span className="continue__shop__text">continuer mes achats</span> */}
                </div>
            </div>
        </a>
    );
}

export default ContinueShopping;
