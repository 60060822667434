import React from "react";

// Assets
import rainbow from "../../../assets/gg/rainbow.png";

const Recommandation = () => {
    return (
        <div className="recommandation">
            <h2 className="recommandation__title londrina">Recommandation</h2>
            <p className="recommandation__text kanit">
            Comme les Go Gummy! représentent un produit très riche en fibres, nous vous recommandons de débuter par consommer la moitié d’un sachet, soit 7-8 oursons, afin de voir votre tolérance et ainsi, éviter les inconforts intestinaux.
            </p>
            <img src={rainbow} alt="Des Go Gummy! en forme d'arc-en-ciel" />
        </div>
    );
};

export default Recommandation;
