import React from "react";

// Assets
import gg from "../../../assets/akt/brands-gg.png";
import gm from "../../../assets/akt/brands-gm.png";
import gk from "../../../assets/akt/brands-gk.png";
import gc from "../../../assets/akt/brands-gc.png";
import mcf from "../../../assets/akt/brands-mcf.png";
import gh from "../../../assets/akt/brands-hop.png";
import gb from "../../../assets/akt/brands-gb.png";

const Brands = () => {
    return (
        <div className="brands">
            <div className="brands__container">
                <h5 className="title">Une gamme innovatrice complète de produits Go!</h5>
                <div className="brands__content">
                    <a href="https://www.jimmysevigny.com/boutique/go-gummy/" target="_blank">
                        <img src={gg} alt="" />
                    </a>
                    <a href="https://www.jimmysevigny.com/boutique/gokrisp/" target="_blank">
                        <img src={gk} alt="" />
                    </a>
                    <a href="https://www.jimmysevigny.com/boutique/go-matcha/" target="_blank">
                        <img src={gm} alt="" />
                    </a>
                    <a href="https://melangecoupefaim.com/" target="_blank" className="mcf">
                        <img src={mcf} alt="" />
                    </a>
                    <a href="https://www.jimmysevigny.com/boutique/gochoko/" target="_blank">
                        <img src={gc} alt="" />
                    </a>
                    <a href="https://www.jimmysevigny.com/boutique/gohop/" target="_blank">
                        <img src={gh} alt="" />
                    </a>
                    <a href="https://www.jimmysevigny.com/boutique/gobeanz/" target="_blank">
                        <img src={gb} alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Brands;
