import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Analytics from "../../../services/analytics/index.js";

// Mui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
// hook form
import { useForm, FormProvider } from "react-hook-form";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
    getCheckoutDetails,
    addActiveAktSub,
    updateSubscriptionPriceById,
    getSubscriptions,
    addSelectedEmail,
    deleteProductById,
} from "../../../store/reducers/checkout";
import { clearOrders } from "../../../store/reducers/orders";
// DB
import { getRequest } from "../../../actions/requests";
// custom components
import { CheckoutContext } from "./Checkout.jsx";
import { UserContext } from "../../../reducer/userContext";
import Address from "./Address";
import { settings } from "../../../settings";
import { parseDate } from "../../../utils/parseData";
import { getLandingPageInfo } from "../../../utils/config";

const Billing = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    // redux
    const dispatch = useDispatch();
    const details = useSelector(getCheckoutDetails);
    const subscriptions = useSelector(getSubscriptions);
    const { brand } = getLandingPageInfo();

    const history = useHistory();

    const formMethods = useForm({
        defaultValues: details,
    });

    // states
    const { changeStepShow, goToNextPage, gotToPreviousStep, products } = useContext(CheckoutContext);
    const [sameShipping, setSameShipping] = useState(details.isBillingShippingSame);
    const { user } = useContext(UserContext);

    const onSubmit = data => {
        setData(data);
        if (!open) {
            setOpen(true);
        } else {
            goToNextPage(history.push, history.location.pathname, {
                ...data,
                isBillingShippingSame: sameShipping,
            });
        }
    };

    const handleShipping = checked => {
        setSameShipping(checked);
        changeStepShow("shipping", !checked);
    };

    const handleBack = () => {
        gotToPreviousStep(history);
    };

    useEffect(() => {
        // Remove products added by promocode
        const productsToRemove = products.filter(p => p.brands.some(b => b.acronym === "AKT") && p.isAddOnTrial);
        if (productsToRemove.length) {
            productsToRemove.forEach(x => {
                dispatch(deleteProductById(x.id));
            });
        }

        dispatch(clearOrders());

        Analytics.begin_checkout([...subscriptions, ...products]);
    }, []);

    /**
     * Checks if the user has already an active sub in the old system
     * @param {string} email
     * @param {Array<object>} subscriptions
     */
    const CheckIfAktSub = async email => {
        try {
            if (settings.videoAccess.includes(subscriptions[0].id)) {
                const payload = await getRequest(`users/aktSubscribedUsers/${email.toLowerCase()}`);
                const data = payload.data;

                if (data && data.email.toLowerCase() === email.toLowerCase() && !subscriptions[0].price) {
                    if (parseDate(Date.now()) < parseDate(data.nextCycle)) {
                        dispatch(addActiveAktSub(data));
                        let price;
                        switch (subscriptions[0].id) {
                            case settings.videoAccess[1]:
                                // Monthly
                                price = 1999;
                                break;
                            case settings.videoAccess[2]:
                                // Solo yearly
                                price = 7499;
                                break;
                            case settings.videoAccess[0]:
                                // Duo yearly
                                price = 11499;
                                break;
                            default:
                                break;
                        }
                        dispatch(updateSubscriptionPriceById(subscriptions[0].id, price));
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        dispatch(addSelectedEmail(!_.isEmpty(user) && user.email ? user.email : details.email));
    }, []);

    useEffect(() => {
        if (!_.isEmpty(subscriptions)) {
            CheckIfAktSub(details.email ? details.email : user.email);
        }
    }, [subscriptions]);

    useEffect(() => {
        if (user) {
            formMethods.reset({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                billing: {
                    appartment: user.coordinates ? user.coordinates.appartment : "",
                    streetNumber: user.coordinates ? user.coordinates.streetNumber : "",
                    street: user.coordinates ? user.coordinates.street : "",
                    city: user.coordinates ? user.coordinates.city : "",
                    phone: user.phone ? user.phone : "",
                    country: user.coordinates ? user.coordinates.country : "",
                    postalCode: user.coordinates ? user.coordinates.postalCode : "",
                },
            });
        }
    }, [user]);

    const BillingPopup = ({ isOpen }) => {
        if (data) {
            return (
                <Dialog open={isOpen} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirmer votre adresse de facturation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <h4 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                                <div>
                                    {data.firstName} {data.lastName}
                                </div>
                                <div>
                                    {data.billing.appartment ? `${data.billing.appartment}-` : ""}
                                    {data.billing.streetNumber} {data.billing.street}
                                </div>
                                <div>
                                    {data.billing.city}, {data.billing.province}, {data.billing.postalCode}
                                </div>
                                <div>{data.billing.country}</div>
                            </h4>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Modifier</Button>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                goToNextPage(history.push, history.location.pathname, {
                                    ...data,
                                    isBillingShippingSame: sameShipping,
                                });
                            }}
                        >
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        } else return "";
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Typography variant="h6" gutterBottom>
                    Informations de facturation
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autocomplete="given-name"
                            error={formMethods.errors.firstName ? true : false}
                            id="firstName"
                            name="firstName"
                            label="Prénom"
                            InputLabelProps={{ shrink: true }}
                            inputRef={formMethods.register({
                                required: "Ce champ est requis",
                            })}
                            fullWidth
                            helperText={formMethods.errors.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autocomplete="family-name"
                            error={formMethods.errors.lastName ? true : false}
                            id="lastName"
                            name="lastName"
                            label="Nom"
                            InputLabelProps={{ shrink: true }}
                            inputRef={formMethods.register({
                                required: "Ce champ est requis",
                            })}
                            fullWidth
                            helperText={formMethods.errors.lastName?.message}
                        />
                    </Grid>
                    <Address baseObjectName="billing" />
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox name="isBillingShippingSame" checked={sameShipping} onChange={e => handleShipping(e.target.checked)} />
                            }
                            label="Informations d'expédition identiques à la facturation"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} align="right">
                        <Button onClick={handleBack}>Revenir</Button>
                        <Button type="submit" variant="contained" className={`checkout__next ${brand}`}>
                            Suivant
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <BillingPopup isOpen={open} />
        </FormProvider>
    );
};

export default Billing;
