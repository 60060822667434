import React from "react";
import { useHistory } from "react-router-dom";

// Assets
import packs from "../../../assets/gg/4packages.png";

// Custom components
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";

const Opening = () => {
    const history = useHistory();
    const dispatch = useDispatch();

  const LANCEMENT = "fMFfdeZh7sIdC8ZrIAzN";

    return (
        <div className="opening" id="promo">
            <div className="opening__container">
              <div className="opening__left">
                <img src={packs} alt="Quatres packets Go Gummy" />
              </div>
              <div className="opening__right">
                {/* <h5 className="opening__right--promo kanit">Promotion de lancement</h5> */}
                <h4 className="opening__right--boite londrina">boîte de 24 sacs</h4>
                <p className="opening__right--promo kanit">Économisez 12$</p>
                <h4 className="opening__right--price londrina">83,76$ + tx</h4>
                <p className="opening__right--livraison londrina">La livraison est GRATUITE!</p>
                <button className="cta__yellow" 
                  onClick={async () => {
                    const product = await fetchProductById(LANCEMENT);
                    dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                    history.push(`/checkout`);
                }}>Acheter</button>
              </div>
            </div>
        </div>
    );
};

export default Opening;
