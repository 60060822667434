import React from "react";

function Fact() {
    return (
        <div className="fact">
            <div className="fact__container">
                <div className="fact__content">
                    <span className="fact__subtitle">NOUS SOMMES FIERS DE NOTRE PLATEFORME</span>
                    <h3 className="fact__title">100% QUÉBÉCOISE!</h3>
                    <a href="./#produits">
                        <button className="fact__cta">J'ESSAIE AKTIVATON POUR 14 JOURS GRATUITEMENT</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Fact;
