import React from "react";

const Hero = () => {
    return (
        <div className="hero">
            <h1 className="hero__title londrina">
                1 Gramme de sucre <br />
                30 grammes de fibres!
            </h1>
            <a href="/#produits"><button className="cta__black">Acheter en ligne</button></a>
        </div>
    );
};

export default Hero;
