import React from "react";
import propTypes from "prop-types";
import { Editor, EditorState, convertFromRaw } from "draft-js";

// Material UI Core
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

// Utils
import { getItemPrice } from "../../../utils/price";
import analytics from "../../../services/analytics";
import useGetUser from "../../../general/useGetUser";

/**
 * Displays an upsell proposal box
 * @param {object} item
 * @param {string} acceptText
 * @param {string} refuseText
 * @param {func} onAccept
 * @param {func} onRefuse
 */
const Upsell = ({
    item,
    acceptText = "OUI, J'ACHÈTE",
    refuseText = "NON, je ne veux pas de ce produit exclusif",
    onAccept,
    onRefuse,
    showDescription = true,
    showSmallText = false,
    smallText,
}) => {
    const { user } = useGetUser();

    const handleOnAccept = e => {
        onAccept(e);
        // analytics.upsale(item, user.email);
    };

    return (
        <Grid container className="upsell__border">
            <Grid item xs={4} sm style={{ padding: 20 }} className="element__centered">
                <img src={item.imageURLs[0]} alt="" className="upsell__image" />
            </Grid>
            <Grid item xs={6} sm style={{ padding: 20 }} className="upsell__container__2">
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }} className="secondary__text__color">
                        {item.name}
                    </Typography>
                </Grid>
                {showDescription && (
                    <Grid item xs={12} style={{ paddingBottom: 20 }}>
                        <Typography variant="body2">
                            <Editor
                                editorState={
                                    item.description
                                        ? EditorState.createWithContent(convertFromRaw(JSON.parse(item.description)))
                                        : EditorState.createEmpty()
                                }
                                readOnly
                            />
                        </Typography>
                    </Grid>
                )}
                {showSmallText && (
                    <Grid item xs={12} style={{ paddingBottom: 20 }}>
                        <p>{smallText}</p>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" onClick={handleOnAccept}>
                        {acceptText}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Link component="button" variant="caption" onClick={onRefuse}>
                        {refuseText}
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={2} sm style={{ padding: 20 }} className="element__centered">
                <Typography variant="h6" style={{ fontWeight: "bold", whiteSpace: "nowrap" }} className="secondary__text__color">
                    {item.price ? item.price / 100 : getItemPrice(item)}$
                </Typography>
            </Grid>
        </Grid>
    );
};

Upsell.propTypes = {
    item: propTypes.object.isRequired,
    acceptText: propTypes.string,
    refuseText: propTypes.string,
    onAccept: propTypes.func.isRequired,
    onRefuse: propTypes.func.isRequired,
};

export default Upsell;
