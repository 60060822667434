import React from "react";
import { Grid } from "@material-ui/core";

// Assets
import jimmy from "../../../assets/akt/use-jimmy.png";
import HD from "../../../assets/akt/use-hd.png";
import L24 from "../../../assets/akt/use-24.png";
import online from "../../../assets/akt/use-online.png";
import forever from "../../../assets/akt/use-clock.png";

function Use() {
    return (
        <div className="use">
            <div className="use__left">
                <h3 className="use__title section__title">APPORTEZ AKTIVATION PARTOUT!</h3>
                <p className="use__text">
                    Une plateforme d'entraînement 100% québécoise avec les <b>meilleurs entraînements</b> qui soit! connexion.
                </p>
                <div className="use__items">
                    <div className="use__item">
                        <img src={HD} alt="" />
                        <span>VIDÉO DE QUALITÉ SUPÉRIEURE</span>
                    </div>

                    <div className="use__item">
                        <img src={L24} alt="" />
                        <span>ACCÈS 24/7</span>
                    </div>

                    <div className="use__item">
                        <img src={online} alt="" />
                        <span>WEB POUR STREAMING</span>
                    </div>

                    <div className="use__item">
                        <img src={forever} alt="" />
                        <span>ENTRAÎNEZ-VOUS À TOUT MOMENT</span>
                    </div>
                </div>
            </div>
            <div className="use__right">
                <img src={jimmy} alt="" />
            </div>
            {/* <div className="use__left">
                <img src={bg} alt="Aktivation!" />
            </div> */}
        </div>
    );
}

export default Use;
