import React, { useEffect, useState } from "react";
import _ from "lodash";
// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// Utils
import { formatPrice, parseDate } from "../../../utils/parseData";
import { getLandingPageInfo } from "../../../utils/config";
import { settings } from "../../../settings";

/**
 * Displays all the total for the received order based on received market
 * @param {string} market
 * @param {Object} order
 * @returns JSX component
 */
const ItemsTotals = ({ order }) => {
    // Totals
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [federalTax, setFederalTax] = useState(0);
    const [provincialTax, setProvincialTax] = useState(0);
    const [amountSaved, setAmountSaved] = useState(0);

    const getTotalRefund = items => {
        let total = 0;
        if (items) {
            items.forEach(e => {
                total += e.price;
            });
        }
        return total;
    };

    useEffect(() => {
        if (order) {
            if (order.amountSaved) setAmountSaved(order.amountSaved / 100);
            setSubTotal(order.totalSub / 100);
            setFederalTax(order.totalTaxesFed / 100);
            setProvincialTax(order.totalTaxesProv / 100);
            setTotal(order.totalGrand / 100);
        }
    }, [order]);

    return (
        <Grid container>
            <Grid item xs={6}>
                {amountSaved !== 0 && (
                    <Typography variant="subtitle1" gutterBottom>
                        Promotion
                    </Typography>
                )}
                {order.promoCode && (
                    <Typography variant="subtitle1" gutterBottom>
                        Code promo
                    </Typography>
                )}
                <Typography variant="subtitle1" gutterBottom>
                    Coût livraison
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Sous-total
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Taxe fédérale ({settings.order.TPS_NUMBER})
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Taxe provinciale ({settings.order.TVQ_NUMBER})
                </Typography>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    Total
                </Typography>
                {/* {order.hasOwnProperty("refunds") &&
                    order.refunds.map((refund, i) => (
                        <Typography key={i} variant="subtitle2" style={{ color: "red" }}>{`Remboursement (${parseDate(
                            refund.createdAt
                        )}):`}</Typography>
                    ))} */}
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
                {amountSaved !== 0 && (
                    <Typography variant="subtitle1" gutterBottom>
                        {amountSaved.toFixed(2)} $
                    </Typography>
                )}
                {order.promoCode && (
                    <Typography variant="subtitle1" gutterBottom>
                        {order.promoCode} $
                    </Typography>
                )}
                <Typography variant="subtitle1" gutterBottom>
                    {formatPrice(order.totalShippingCost)} $
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {subTotal.toFixed(2)} $
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {order.isFree ? "0.00" : federalTax.toFixed(2)} $
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {order.isFree ? "0.00" : provincialTax.toFixed(2)} $
                </Typography>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    {total.toFixed(2)} $
                </Typography>

                {order.hasOwnProperty("refunds") &&
                    order.refunds.map((refund, i) => (
                        <Typography key={i} variant="subtitle2" style={{ color: "red" }}>{`${parseFloat(refund.amount).toFixed(2)} $`}</Typography>
                    ))}
            </Grid>
        </Grid>
    );
};

export default ItemsTotals;
