import React from "react";
import Footer1 from "../../../assets/gc/footer-choko.png";
import Footer2 from "../../../assets/gc/footer-matcha.png";
import Footer3 from "../../../assets/gc/footer-mcf.png";

function Footer() {
    return (
        <div className="footer">
            <h4 className="footer__title">Une gamme innovatrice complète de produits santé</h4>
            <div className="footer__container">
                <a href="https://gochoko.com"><img src={Footer1} alt="" /></a>
                <a href="https://gomatcha.ca/" target="_blank"><img src={Footer2} alt="" /></a>
                <a href="https://melangecoupefaim.com/" target="_blank"><img src={Footer3} alt="" /></a>
            </div>
            <span className="footer__disclaimer">**limite de 1 'sample pack' par client</span>
        </div>
    );
}

export default Footer;