import { useState, useEffect } from "react";

const useWindowSize = () => {
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        // Calculate the window width size at all time
        const handleWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowWidth);
        return () => {
            window.addEventListener("resize", handleWindowWidth);
        };
    }, [window.innerWidth]);

    return windowWidth;
};

export default useWindowSize;
