import React from "react";
import Left from '../../../assets/gc/new.png';
import Grid from '@material-ui/core/Grid';

import Balls from '../../../assets/gc/boules.png';

function Framboise() {
    return (
        <div className="framboise">
            <Grid container>
                <Grid item md={6}>
                    <div className="framboise__left">
                        <img src={Left} />
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="framboise__right">
                        <div>
                            <span className="framboise__right__1">Essayez nos nouvelles</span>
                            <span className="framboise__right__2">saveurs de Menthe et Noix de coco!</span>
                            <span className="framboise__right__3">16 grammes de protéines.</span>
                            <span className="framboise__right__4">Que ce soit en collation, pendant,avant et/ou après votre entraînement, Go Choko! est la collation hyperprotéinée parfaite!</span>
                            <a href="#produits"><button className="framboise__cta">
                                Acheter maintenant
                            </button></a>
                            <img src={Balls} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Framboise;