import React from "react";

// Assets
import red from "../../../assets/gg/gummy-red.png";
import yellow from "../../../assets/gg/gummy-yellow.png";
import green from "../../../assets/gg/gummy-green.png";
import pink from "../../../assets/gg/gummy-pink.png";

const Info = () => {
    return (
        <div className="info">
            <div className="info__container londrina">
                <div className="info__item left">
                    <img src={red} alt="Go Gummy! à la framboise" />
                    <p className="text">1g de sucre par sac</p>
                </div>
                <div className="info__item right">
                    <img src={yellow} alt="Go Gummy! à l'ananas" />
                    <p className="text">30g de fibre</p>
                </div>
                <div className="info__item left">
                    <img src={green} alt="Go Gummy! au melon d'eau" />
                    <p className="text">Végan</p>
                </div>
                <div className="info__item right">
                    <img src={pink} alt="Go Gummy! à la pêche" />
                    <p className="text">Sans sucre d’alcool</p>
                </div>
            </div>
        </div>
    );
};

export default Info;
