import React, { useContext,  useEffect,  useState } from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

// Material UI Core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// Custom Components
import { UserContext } from "../../../../reducer/userContext";
import { settings } from "../../../../settings";
import { logout as logoutFunction } from "../../../../utils/auth";
import { getLandingPageInfo } from "../../../../utils/config";
// Assets
import profileIcon from "../../../../assets/shared/nav_profile.svg";
import shopIcon from "../../../../assets/shared/nav_shop.svg";
import workoutIcon from "../../../../assets/shared/nav_workout.svg";
import Favorite from "../../../../assets/shared/nav_favorite.svg";
import homeIcon from "../../../../assets/shared/nav_home.svg";
import signoutIcon from "../../../../assets/shared/nav_signout.svg";
// Redux
import { useDispatch } from "react-redux";
import { generateKey, snackbarEnqueuedAction } from "../../../../store/reducers/snackbars";
import { getRequest, postRequest } from "../../../../actions/requests";
import analytics from "../../../../services/analytics";
import { parseDate } from "../../../../utils/parseData";

/**
 * Displays the user's part of the navigation bar. Does not need any special role.
 */
const MobileNav = ({ isMobile = false }) => {
    const { user, userEnteredUnscramlingEmail, isUnscrambled } = useContext(UserContext);
    const { brand } = getLandingPageInfo();

    // notifications functions
    const pushNotification = (...args) => dispatch(snackbarEnqueuedAction(...args));

    const history = useHistory();
    const dispatch = useDispatch();

    const [hasOldAktSub, setHasOldAktSub] = useState(false);

    const handleLogoutSuccess = () => {
        // dispatch(actionCreators.signOutUser());
        return history.push("/");
    };

    const baseUrl = "/portail";
    const videos = {
        path: `${baseUrl}${"/videos"}`,
        text: <span>Aktivation</span>,
    };
    const favs = { path: `${baseUrl}${"/favoris"}`, text: "favoris" };
    const recipe = { path: `${baseUrl}${"/recettes"}`, text: "Recettes" };
    const profile = { path: `${baseUrl}${"/profil"}`, text: "Profil" };
    const home = { path: `/`, text: "Retour site" };
    const logout = { path: "", text: "Déconnexion" };

    const checkOldAktSub = async () => {
        try {
            if (user) {
                // If user had previous AKT subs
                if (user.subscriptions && user.subscriptions.some(s => settings.videoAccess.includes(s.id))) {
                    throw new Error();
                }

                const oldAktSubPayload = await getRequest(`${settings.urls.aktSubscribedUsers}/${user.email.toLowerCase()}`);
                const oldAktSubUser = oldAktSubPayload.data;

                // Check if oldAktSubUser is valid
                if (!_.isEmpty(oldAktSubUser) && !oldAktSubUser.used) {
                    if (parseDate(Date.now()) < parseDate(oldAktSubUser.nextCycle)) {
                        return setHasOldAktSub(true);
                    }
                }

                throw new Error();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const displayOldAktSubPopup = () => {
        if (hasOldAktSub && !user.hasVideoAccess) {
            pushNotification({
                message: settings.aktExistingSub,
                options: {
                    key: generateKey(),
                    variant: "success",
                    popup: {
                        title: "Accès vidéo",
                        showImage: false,
                        redirectUrl: "https://aktivation.ca#produits",
                        buttonText: "Activer un forfait",
                    },
                },
            });
        }
    };

    const accessUnscrambledVideos = async () => {
        // add email to the list
        const payload = await postRequest("unscramblings/addEntry", {
            email: user.email,
        });

        if (payload.status === 200) {
            analytics.newLead(user.email);
        }
    };

    const VideoPath = () => {
        if (user && user.hasVideoAccess) {
            return (
                <Link to={videos.path} onClick={() => displayOldAktSubPopup()}>
                    <VideoContent />
                </Link>
            );
        }

        if (user && !user.hasVideoAccess && !userEnteredUnscramlingEmail && isUnscrambled) {
            return (
                <Link to="/portail/debrouillage" onClick={accessUnscrambledVideos}>
                    <VideoContent />
                </Link>
            );
        }

        if (user && !user.hasVideoAccess && !userEnteredUnscramlingEmail && !isUnscrambled) {
          return (
              <a href="https://aktivation.ca/">
                  <VideoContent />
              </a>
          );
      }

        if (userEnteredUnscramlingEmail) {
            return (
                <Link to="/portail/debrouillage">
                    <VideoContent />
                </Link>
            );
        }
        if (!hasOldAktSub) {
            return (
                <a href="https://aktivation.ca/" onClick={() => displayOldAktSubPopup()}>
                    <VideoContent />
                </a>
            );
        } else {
            return (
                <span onClick={() => displayOldAktSubPopup()}>
                    <VideoContent />
                </span>
            );
        }
    };

    const VideoContent = () => {
        if (brand === "Aktivation") {
            return (
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={workoutIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={videos.text} />
                    </ListItem>
                </div>
            );
        } else return <></>;
    };

    useEffect(() => {
        checkOldAktSub();
    }, []);

    return (
        <List className="nav__link__container">
            <Link to={home.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={homeIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={home.text} />
                    </ListItem>
                </div>
            </Link>

            <VideoPath />

            {user?.favoriteVideos && (
                <Link to={favs.path}>
                    <div className="nav__link__item">
                        <ListItem button className="nav__listItem">
                            <ListItemIcon>
                                <img src={Favorite} alt="fav" />
                            </ListItemIcon>
                            <ListItemText className="nav__item__text" primary={favs.text} />
                        </ListItem>
                    </div>
                </Link>
            )}

            <a href="https://www.jimmysevigny.com/boutique/aktivation/" target="_blank" rel="noopener noreferrer">
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={shopIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary="Boutique" />
                    </ListItem>
                </div>
            </a>

            {/* <Link to={recipe.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={recipeIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={recipe.text} />
                    </ListItem>
                </div>
            </Link> */}

            {user && (
                <Link to={profile.path}>
                    <div className="nav__link__item">
                        <ListItem button className="nav__listItem">
                            <ListItemIcon>
                                <img src={profileIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText className="nav__item__text" primary={profile.text} />
                        </ListItem>
                    </div>
                </Link>
            )}

            <ListItem button className="nav__listItem" onClick={() => logoutFunction(handleLogoutSuccess())}>
                <ListItemIcon>
                    <img src={signoutIcon} alt="" />
                </ListItemIcon>
                <ListItemText className="nav__item__text" primary={logout.text} />
            </ListItem>
        </List>
    );
};

export default MobileNav;
