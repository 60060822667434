import React from "react";
// Pages
import Nav from "./home/nav";
import Hero from "./home/hero";
import Intro from "./home/intro";
import Video from './home/video';
import Cadeau from './home/cadeau';
import Framboise from './home/framboise';
import Products from "./home/products";
import Socials from "./home/socials";
import Use from "./home/use";
import Faq from "./home/faq";
import Benefits from "./home/benefits";
import Testimonials from "./home/testimonials";
import Stats from './home/stats';
import Sample from "./home/sample";
import Footer from "./home/footer";
import FooterNav from "./home/footernav";
import Shipping from "./home/shipping";
import Newsletter from './home/newsletter';
import Lh from "./home/lh";
import Valeurs from "./home/valeurs";
import Summer from "./home/summer";
// Custom Components
import { GetHelmet } from "../../utils/config";

function Home() {
    return (
        <div className="gc">
            <GetHelmet />
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id="100525795408489"
                theme_color="#352312"
                logged_in_greeting="Bienvenue sur GoChoko! Comment pouvons-nous vous aider?"
                logged_out_greeting="Bienvenue sur GoChoko! Comment pouvons-nous vous aider?"
            ></div>
            <Nav />
            {/* <Summer /> */}
            <Hero />
            <Intro />
            <Video />
            {/* <Cadeau /> */}
            <Use />
            <Socials />
            <Framboise />
            <Products />
            <Sample />
            <Valeurs />
            <Testimonials />
            <Stats />
            <Benefits />
            <Shipping />
            <Newsletter />
            <Faq />
            <Footer />
            <FooterNav />
            <Lh />
        </div>
    );
}

export default Home;
