import React from "react";
// import Fb from "../../../assets/akt/fb.png";
// import Ig from "../../../assets/akt/ig.png";
import Fb from "../../../assets/akt/fb-white.svg";
import Ig from "../../../assets/akt/ig-white.svg";

function Socials() {
    return (
        <div className="socials">
            <div className="socials__container">
                <h3 className="socials__title">SUIVEZ-NOUS SUR LES RÉSEAUX SOCIAUX </h3>
                <span className="socials__icons">
                    <a href="https://www.facebook.com/Aktivation-107573801050278" target="_blank">
                        <img src={Fb} alt="" />
                    </a>
                    <a href="https://www.instagram.com/Aktivation.fit/" target="_blank">
                        <img src={Ig} alt="" />
                    </a>
                </span>
            </div>
        </div>
    );
}

export default Socials;
