import React from "react";

// Assets
import cast from "../../../assets/akt/compatible-cast.png";
import phones from "../../../assets/akt/compatible-phones.png";
import google from "../../../assets/shared/google-play.png";
import apple from "../../../assets/shared/apple-store.png";

function Compatible() {
    return (
        <div className="compatible">
            <div className="compatible__container">
                <img src={cast} alt="" className="compatible__left" />
                <div className="compatible__right">
                    <div className="compatible__content">
                        <h4 className="section__title compatible__title">Tous vos entraînements... Disponibles en tout temps sur votre téléphone.</h4>
                        <span>
                            Accédez à nos nombreux programmes et entraînements n'importe où, avec ou sans accès internet, via l’application Aktivation
                            disponible sur iOS et Android!
                        </span>
                        <div className="compatible__content--bottom">
                            <div className="compatible__download">
                                <a href="https://apps.apple.com/ca/app/aktivation/1546164727" target="_blank" className="links__container">
                                    <img src={apple} alt="" className="links" />
                                </a>

                                <a
                                    href="https://play.google.com/store/apps/details?id=com.jimmysevigny.aktivation&pli=1"
                                    target="_blank"
                                    className="links__container"
                                >
                                    <img src={google} alt="" className="links" />
                                </a>
                            </div>
                            <img src={phones} alt="" className="compatible__phones" />
                        </div>
                    </div>
                </div>
                <div className="compatible__tablet--phones">
                    <img src={phones} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Compatible;
