import React from 'react'

const Promo = () => {
  return (
    <div className='promo'>
      <a href="./#promo" className='promo__text'><p className='kanit'>profitez d'un rabais de lancement incroyable!</p></a>
    </div>
  )
}

export default Promo