import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
// Assets
import pack from "../../../assets/gg/4packages.png";

const Sample = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const SAMPLE_PACK = "8BzLtVDwqXaTOFdAgcTW";


    return (
        <div className="sample" id="sample">
            <div className="sample__container">
                <div className="sample__left">
                    <img src={pack} />
                </div>
                <div className="sample__right">
                    <span className="sample__right--try kanit">Essayez le sample pack!</span>
                    <span className="sample__right--price londrina">14,99$ + txs</span>
                    <span className="sample__right--shipping kanit">La livraison est GRATUITE!</span>
                        <button className="cta__pink"
                          onClick={async () => {
                            const payload = await getRequest(`${productsRoot}/${SAMPLE_PACK}`, {});
                            dispatch(addProduct({ ...payload.data, selectedQuantity: 1, status: "À livrer" }));
                            history.push(`/checkout`);
                        }}
                        >Acheter en ligne</button>
                </div>
            </div>
        </div>
    );
};

export default Sample;
