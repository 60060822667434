import React, { useEffect, useState } from "react";

// React-router-dom
import { Link, NavLink } from "react-router-dom";

// React-icons
import { BsBoxSeam } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";

// Assets
import logo from "../../../assets/gg/logo.png";
import menu from "../../../assets/gg/menu.png";
import close from "../../../assets/gg/menu-close.png";
import wallet from "../../../assets/gg/wallet.svg";

const Nav = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    let activeStyle = {
        textDecoration: "underline",
    };

    let activeClassName = "underline";

    const HamburgerMenu = () => {
        return (
            <div className={open ? "nav__left--links open" : "nav__left--links"}>
                <a href="./#faq">
                    <span className="link kanit">Faq</span>
                </a>
                <a href="./portail">
                    <span className="link kanit">Espace Client</span>
                </a>
                <div className="nav__right--buttons">
                  <a href="./#produits">
                    <button className="cta__blue">Acheter <img src={wallet} alt="" /></button>
                  </a>
                </div>
                <div className="nav__bar"></div>
                <div className="nav__buttons--list"></div>
                <span className="copyright kanit">
                    © 2022 <b>Go Gummy</b>
                </span>
            </div>
        );
    };

    const handleSize = () => {
        setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    const handleSetOpen = () => {
        setOpen(!open);
    };

    return (
        <>
            {screenSize && screenSize > 1280 ? (
                <nav className="nav">
                    <div className="nav__container">
                        <div className="nav__container--content">
                            <Link to="/">
                                <img className="nav__left--logo" src={logo} alt="Go Gummy" />
                            </Link>
                            <div className="nav__left">
                                <div className="nav__left--links">
                                    <a href="./#faq">
                                        <span className="link kanit">Faq</span>
                                    </a>
                                    <a href="./portail">
                                        <span className="link kanit">Espace Client</span>
                                    </a>
                                </div>
                            </div>
                            <div className="nav__right">
                              <a href="./#produits">
                                <button className="cta__blue">
                                    Acheter <img src={wallet} alt="" />
                                </button>
                              </a>
                            </div>
                        </div>
                        <div className="footer__bar"></div>
                    </div>
                </nav>
            ) : (
                <nav className="nav">
                    <div className="nav__container">
                        <div className="nav__container--content">
                            <div className="nav__left">
                                <Link to="/">
                                    <img className="nav__left--logo" src={logo} alt="Go Gummy" />
                                </Link>
                            </div>
                            <div className="nav__right">
                                {!open ? (
                                    <img
                                        src={menu}
                                        alt="hamburger menu"
                                        onClick={() => {
                                            handleSetOpen();
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={close}
                                        alt="hamburger menu"
                                        onClick={() => {
                                            handleSetOpen();
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <HamburgerMenu />
                </nav>
            )}
        </>
    );
};

export default Nav;
