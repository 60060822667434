import React, { useState, useEffect } from "react";

// Slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Hooks
import useWindowSize from "../../../hooks/useWindowSize";

// Assets
import stars from "../../../assets/akt/stars-blue.svg";

const Testimonials2 = () => {
    // Hooks
    const widthOnWindowChange = useWindowSize();

    // States
    const [width, setWidth] = useState(document.body.offsetWidth);

    const settings = {
        className: "center",
        // dots: false,
        infinite: true,
        centerPadding: "120px",
        speed: 1000,
        slidesToShow: 2.8,
        slidesToScroll: 1,
        arrows: false,
    };

    const settingsMobile = {
        className: "center",
        // dots: false,
        infinite: true,
        centerPadding: "120px",
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const Item = ({ text, name }) => {
        return (
            <div className="item">
                <p className="item__text">{text}</p>
                <p className="item__name">{name}</p>
                <img src={stars} alt="" />
            </div>
        );
    };

    useEffect(() => {
        if (widthOnWindowChange !== 0) setWidth(widthOnWindowChange);
    }, [width, widthOnWindowChange]);

    return (
        <div className="testimonials2">
            {width >= 768 ? (
                <Slider {...settings} autoplay>
                    <div>
                        <Item text="Je suis complètement vendue Aktivation. J’en parle beaucoup autour de moi." name="Julie Lapointe" />
                    </div>
                    <div>
                        <Item text="Ça prend Aktivation dans sa vie!" name="Caroline Desrosiers" />
                    </div>
                    <div>
                        <Item text="C’est vraiment une belle plate-forme!! L’équipe est hyper et qualifiée et à l’écoute." name="Sylvie Hovington" />
                    </div>
                    <div>
                        <Item text="Excellente plateforme qui peut être utilisée tant à la maison qu’au gym!" name="Josée L'Heureux" />
                    </div>
                    <div>
                        <Item text="Excellente plateforme québécoise!! Je suis abonnée depuis deux ans et j’adore." name="Mélissa McGraw" />
                    </div>
                </Slider>
            ) : (
                <Slider {...settingsMobile} autoplay>
                    <div>
                        <Item text="Je suis complètement vendue Aktivation. J’en parle beaucoup autour de moi." name="Julie Lapointe" />
                    </div>
                    <div>
                        <Item text="Ça prend Aktivation dans sa vie!" name="Caroline Desrosiers" />
                    </div>
                    <div>
                        <Item text="C’est vraiment une belle plate-forme!! L’équipe est hyper et qualifiée et à l’écoute." name="Sylvie Hovington" />
                    </div>
                    <div>
                        <Item text="Excellente plateforme qui peut être utilisée tant à la maison qu’au gym!" name="Josée L'Heureux" />
                    </div>
                    <div>
                        <Item text="Excellente plateforme québécoise!! Je suis abonnée depuis deux ans et j’adore." name="Mélissa McGraw" />
                    </div>
                </Slider>
            )}
        </div>
    );
};

export default Testimonials2;
