import React from "react";
import Grid from "@material-ui/core/Grid";

// Assets
import Image from "../../../assets/akt/subhero2.png";
import ordi from "../../../assets/akt/ordi.png";
import bras from "../../../assets/akt/bras.png";
import telephone from "../../../assets/akt/telephone.png";
import medaille from "../../../assets/akt/medaille.png";

function Subhero() {
    return (
        <div className="subhero">
            <div className="subhero__container">
                <div className="subhero__left">
                    <h3 className="subhero__title">PASSEZ AU NIVEAU SUPÉRIEUR!</h3>
                    <p className="subhero__text">
                        AKTIVATION est la nouvelle façon de s'entraîner 100% en ligne via vos appareils intelligents (téléphone, tablette et
                        téléviseur) et votre ordinateur. Vous pouvez aussi connecter votre ordinateur à votre téléviseur à l'aide d'un fil HDMI ou
                        utiliser l'appareil Chromecast ou Apple TV.
                    </p>
                    <div className="subhero__visuals">
                        <div className="subhero__card">
                            <img src={ordi} alt="" />
                            <h4>Plus de 285 entraînements sur notre plateforme</h4>
                        </div>
                        <div className="subhero__card">
                            <img src={bras} alt="" />
                            <h4>Pas besoin de payer d'extra lors d'ajout de nouveaux programmes!</h4>
                        </div>
                        <div className="subhero__card">
                            <img src={telephone} alt="" />
                            <h4>Vient avec une app 100% compatible android et iOS</h4>
                        </div>
                        <div className="subhero__card">
                            <img src={medaille} alt="" />
                            <h4>Coachs reconnus avec plusieurs années d'expérience</h4>
                        </div>
                    </div>
                    <a href="./#produits">
                        <button className="subhero__cta">JE VEUX ACTIVER LE CHANGEMENT!</button>
                    </a>
                </div>
                <div className="subhero__right">
                    <img src={Image} />
                </div>
            </div>
        </div>
    );
}

export default Subhero;
