import React from "react";
import { Helmet } from "react-helmet";

// Custom Components
import McfNav from "../components/mcf/home/nav";
import GmNav from "../components/gm/home/nav";
import AktNav from "../components/akt/home/nav";
import GcNav from "../components/gc/home/nav";
import GkNav from "../components/gk/home/nav";
import GgNav from "../components/gg/home/nav";

// Settings
import { settings } from "../settings";

// Images
import GmFav from "../assets/gm/favicon.png";
import GcFav from "../assets/gc/favicon.png";
import McfFav from "../assets/mcf/favicon.png";
import gmMarkerIcon from "../assets/gm/marker.png";
import mcfMarkerIcon from "../assets/mcf/marker.png";
import gcMarkerIcon from "../assets/gc/marker.png";
import aktMarkerIcon from "../assets/portal/aktivation-favicon.png";
import aktFav from "../assets/portal/aktivation-favicon.png";
import gkFav from "../assets/gk/gk-favicon.png";
import ggFav from "../assets/gg/gg-favicon.png";
import ggMarkerIcon from "../assets/gg/gg-favicon.png";
import gcLoading from "../assets/gc/gc_loading.gif";
import gmLoading from "../assets/gm/gm_loading.gif";
import mcfLoading from "../assets/mcf/js_loading.gif";
import aktLoading from "../assets/portal/aktivation_loading.gif";
import gkLoading from "../assets/gk/gk_loading.gif";
import ggLoading from "../assets/gg/gg_loading.gif";
import gkMarkerIcon from "../assets/gk/gk-marker.png";
import gmLogo from "../assets/gm/logo-color.png";
import gcLogo from "../assets/gc/logo-color.png";
import mcfLogo from "../assets/mcf/logo-color.png";
import gkLogo from "../assets/gk/logo-gokrisp.png";
import ggLogo from "../assets/gg/logo.png";
import aktLogo from "../assets/portal/logo-aktivation.png";
import gmDrawerLogo from "../assets/shared/gm_drawer_logo.png";
import gcDrawerLogo from "../assets/shared/gc_drawer_logo.png";
import mcfDrawerLogo from "../assets/shared/mcf_drawer_logo.png";
import aktDrawerLogo from "../assets/shared/akt_drawer_logo.png";
import gkDrawerLogo from "../assets/shared/gk_drawer_logo.png";
import ggDrawerLogo from "../assets/shared/gg_drawer_logo.png";

const mcf = settings.landingPages.mcf;
const gm = settings.landingPages.gm;
const akt = settings.landingPages.akt;
const gc = settings.landingPages.gc;
const gk = settings.landingPages.gk;
const gg = settings.landingPages.gg;
export const hostName = window.location.hostname.includes("localhost") ? akt : window.location.hostname;

const staticURLs = settings.staticURLs;
const ggProducts = {
    upsells: [
        {
            id: "8anvHZ1VFWQ607a2awPq",
            price: 1399,
            text: "Essayez tous nos saveurs ",
        },
    ],
};
const gkProducts = {
    upsells: [
        {
            id: "PsVzdTab2dRctfblOLpw",
            price: 1399,
            text: "Essayez tous nos saveurs ",
        },
    ],
};
const gcProducts = {
    upsells: [
        {
            id: "G2h7nccDYjXhzDSFVpDv",
            price: 1999,
            text: "Essayez tous nos saveurs ",
        },
    ],
};
const aktProducts = {
    upsells: [
        {
            id: "KgtM8ycmRV0ylRnUMZ69",
            price: 1899,
            text: "Économisez 24% en plus d’obtenir la livraison gratuite ",
        },
    ],
};
const mcfPacket = {
    upsells: [
        {
            id: "FkGJhfzNBTJbasNaK2F9",
            price: 1499,
            text: "Une offre spéciale pour vous ",
        },
    ],
};
const gmOriginal = {
    upsells: [
        {
            id: "yiKdcdi3NxFp0Zyt0Ke5",
            price: 1194,
            text: "Essayez tous nos saveurs ",
        },
    ],
};

/**
 * Gets the brand and the market based on the hostname
 * @returns {object} brand and market
 */
export const getLandingPageInfo = () => {
    const market = "Canada";
    const info = {
        [mcf]: { brand: "Mélange coupe faim", brandAcronym: "MCF", market },
        [gm]: { brand: "Go Matcha", brandAcronym: "GM", market },
        [akt]: { brand: "Aktivation", brandAcronym: "AKT", market },
        [gc]: { brand: "Go Choko", brandAcronym: "GC", market },
        [gk]: { brand: "Go Krisp", brandAcronym: "GK", market },
        [gg]: { brand: "Go Gummy", brandAcronym: "GG", market },
    };

    return info[hostName];
};

/**
 * Gets the navbar based on the hostname
 * @returns {Component} the navbar component
 */
export const GetNavbar = () => {
    const navs = {
        [mcf]: { className: "mcf", Nav: McfNav },
        [gm]: { className: "gm", Nav: GmNav },
        [akt]: { className: "akt", Nav: AktNav },
        [gc]: { className: "gc", Nav: GcNav },
        [gk]: { className: "gk", Nav: GkNav },
        [gg]: { className: "gg", Nav: GgNav },
    };

    const { className, Nav } = navs[hostName];

    return (
        <div className={className}>
            <Nav />
        </div>
    );
};

/**
 * Gets the landing page name based on the hostname
 * @returns {string} landing page name
 */
export const getLandingPageName = () => {
    const names = {
        [mcf]: "Mélange coupe faim",
        [gm]: "Go matcha",
        [akt]: "Aktivation",
        [gc]: "Go choko",
        [gk]: "Go krisp",
        [gg]: "Go gummy",
    };

    return names[hostName];
};

/**
 * Gets the helmet based on the hostname
 * @returns {Component} the header component
 */
export const GetHelmet = () => {
    const helmetOpt = {
        [mcf]: { title: "Mélange Coupe Faim", content: "Plus de 100 000 clients satisfaits!", href: McfFav },
        [gm]: { title: "GoMatcha!", content: "Libère la puissance du matcha!", href: GmFav },
        [akt]: { title: "Aktivation!", content: "La plateforme d'entraînement 100% québécoise!", href: aktFav },
        [gc]: { title: "Go Choko!", content: "La Révolution du chocolat!", href: GcFav },
        [gk]: { title: "Go! Krisp", content: "Énergie et plaisir au Carré", href: gkFav },
        [gg]: { title: "Go Gummy!", content: "Tout le goût, sans le sucre!", href: ggFav },
    };

    const { title, content, href } = helmetOpt[hostName];

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={content} />
            <link rel="icon" type="image/png" href={href} />
        </Helmet>
    );
};

/**
 * Gets the portal's helmet component
 * @param {String} title
 * @returns {Component} the header component
 */
export const GetPortalHelmet = ({ title = "Portail" }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content="Votre entraînement du Québec" />
            <link rel="icon" type="image/png" href={aktFav} />
        </Helmet>
    );
};

/**
 * Gets the marker's icon based on the hostname
 * @returns {string} the marker's icon path
 */
export const getMarkerIcon = () => {
    const markers = {
        [mcf]: mcfMarkerIcon,
        [gm]: gmMarkerIcon,
        [akt]: aktMarkerIcon,
        [gc]: gcMarkerIcon,
        [gk]: gkMarkerIcon,
        [gg]: ggMarkerIcon,
    };

    return markers[hostName];
};

/**
 * Gets the splashscreen image based on the hostname
 * @returns {String} image path
 */
export const getSplashScreenImage = () => {
    const loadingIcons = {
        [mcf]: mcfLoading,
        [gm]: gmLoading,
        [akt]: aktLoading,
        [gc]: gcLoading,
        [gk]: gkLoading,
        [gg]: ggLoading,
    };

    return loadingIcons[hostName];
};

/**
 * Gets the brand's logo based on the hostname
 */
export const getBrandLogo = () => {
    const brandLogos = {
        [mcf]: mcfLogo,
        [gm]: gmLogo,
        [akt]: aktLogo,
        [gc]: gcLogo,
        [gk]: gkLogo,
        [gg]: ggLogo,
    };

    return brandLogos[hostName];
};

/**
 * Gets the upsell id based on the hostname
 * @returns {String} upsellId
 */
export const getUpsellId = () => {
    const upsells = {
        [mcf]: mcfPacket.upsells,
        [gm]: gmOriginal.upsells,
        [akt]: aktProducts.upsells,
        [gc]: gcProducts.upsells,
        [gk]: gkProducts.upsells,
        [gg]: ggProducts.upsells,
    };

    return upsells[hostName];
};

/**
 * Gets the drawer logo based on the hostname
 * @returns {String} logoPath
 */
export const getDrawerLogo = () => {
    const drawerLogos = {
        [mcf]: mcfDrawerLogo,
        [gm]: gmDrawerLogo,
        [akt]: aktDrawerLogo,
        [gc]: gcDrawerLogo,
        [gk]: gkDrawerLogo,
        [gg]: ggDrawerLogo,
    };

    return drawerLogos[hostName];
};

/**
 * Gets the brand static url based on the hostname
 * @returns {string} staticURL
 */
export const getStaticURL = () => {
    const urls = {
        [mcf]: staticURLs.mcf,
        [gm]: staticURLs.gm,
        [akt]: staticURLs.akt,
        [gc]: staticURLs.gc,
        [gk]: staticURLs.gk,
        [gg]: staticURLs.gg,
    };

    return urls[hostName];
};
