import React from "react";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";

// Assets
import Sarah from "../../../assets/akt/coach-sarah.png";
import Sarah2 from "../../../assets/akt/coach-sarah2.png";
import Jimmy from "../../../assets/akt/coach-jimmy.png";
import Joanie from "../../../assets/akt/coach-joanie.png";
import Christian from "../../../assets/akt/coach-christian.png";
import Julie from "../../../assets/akt/coach-julie.png";
import Sophie from "../../../assets/akt/coach-sophie.png";
import Gen from "../../../assets/akt/coach-gen.png";
import Gen2 from "../../../assets/akt/coach-gen2.png";
import Tam from "../../../assets/akt/coach-tam.png";
import Matthieu from "../../../assets/akt/coach-matthieu.png";
import Jennifer from "../../../assets/akt/coach-jennifer.png";
import bg from "../../../assets/akt/coach-bg.png";

function Coach() {
    const history = useHistory();
    const settings = {
        // draggable: true,
        // swipe: true,
        // touchMove: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        // autoplay: true,
        // autoplaySpeed: 2000,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className="coach">
            <img src={bg} alt="" className="coach__bg " />
            <h3 className="coach__title">NOS COACHS</h3>
            <button className="coach__cta" onClick={() => history.push("/devenir-entraineur")}>
                DEVIENS NOTRE PROCHAIN ENTRAINEUR
            </button>
            <Slider {...settings} autoplay className="coach__slider">
                <div className="coach__item">
                    <h5>
                        Jennifer
                        <br /> ABEL
                    </h5>
                    <img src={Jennifer} />
                </div>

                <div className="coach__item">
                    <h5>
                        Jimmy
                        <br /> SÉVIGNY
                    </h5>
                    <img src={Jimmy} />
                </div>

                <div className="coach__item">
                    <h5>
                        Coach
                        <br /> SARAH
                    </h5>
                    <img src={Sarah2} />
                </div>

                <div className="coach__item">
                    <h5>
                        Joanie
                        <br /> TARDIF
                    </h5>
                    <img src={Joanie} />
                </div>

                <div className="coach__item">
                    <h5>
                        Tammara
                        <br /> THIBEAULT
                    </h5>
                    <img src={Tam} />
                </div>

                <div className="coach__item">
                    <h5>
                        Christian
                        <br /> RACICOT
                    </h5>
                    <img src={Christian} />
                </div>

                <div className="coach__item">
                    <h5>
                        Gen
                        <br /> TARDIF
                    </h5>
                    <img src={Gen2} />
                </div>
                <div className="coach__item">
                    <h5>
                        Julie
                        <br /> BÉGIN
                    </h5>
                    <img src={Julie} />
                </div>

                <div className="coach__item">
                    <h5>
                        Matthieu
                        <br /> DUBREUCQ
                    </h5>
                    <img src={Matthieu} />
                </div>

                <div className="coach__item">
                    <h5>
                        Sophie
                        <br /> YERGEAU
                    </h5>
                    <img src={Sophie} />
                </div>
            </Slider>
        </div>
    );
}

export default Coach;
