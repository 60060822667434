import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Pages
import Top from "./home/top";
import Nav from "./home/nav";
import Hero from "./home/hero";
import Iconbanner from "./home/iconbanner";
import Subhero from "./home/subhero";
import Compatible from "./home/compatible";
import Use from "./home/use";
import Connect from "./home/connect";
import What from "./home/what";
import Socials from "./home/socials";
import Coach from "./home/coach";
import Progs from "./home/progs";
import Products from "./home/products";
import Testimonials2 from "./home/testimonials2";
import Testimonials from "./home/testimonials";
import Facebook from "./home/facebook";
import Faq from "./home/faq";
import Fact from "./home/fact";
import Footer from "./home/footer";
import Brands from "./home/brands";
import Lh from "./home/lh";
// Custom Components
import { GetHelmet } from "../../utils/config";

function Home() {
    // References
    const { search } = useLocation();

    const refId = React.useMemo(() => new URLSearchParams(search).get("refId"), [search]);

    useEffect(() => {
        if (refId) {
            localStorage.setItem("ref-id", refId);
            localStorage.setItem("ref-date", Date.now());

            window.location.href = "/";
        }
    }, [refId]);

    // End references

    return (
        <div className="akt">
            <GetHelmet />
            <Top />
            <Nav />
            <Hero />
            <Iconbanner />
            <Subhero />
            <Compatible />
            <Progs />
            <Products />
            <Testimonials2 />
            <Use />
            {/* <Connect /> */}
            <What />
            <Coach />
            <Socials />
            <Testimonials />
            <Facebook />
            <Faq />
            <Fact />
            <Footer />
            <Brands />
            <Lh />
        </div>
    );
}

export default Home;
