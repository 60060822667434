import React from "react";
import Slider from "react-slick";

// Assets
import gen from "../../../assets/akt/testimonials-genevieve.png";
import isabelle from "../../../assets/akt/testimonials-isabelle.png";
import evelin from "../../../assets/akt/testimonials-evelyn.png";
import steph from "../../../assets/akt/testimonials-stephanie.png";
import karine from "../../../assets/akt/testimonials-karine.png";
import mariane from "../../../assets/akt/testimonials-mariane.png";
import annie from "../../../assets/akt/testimonials-annie.png";
import stephC from "../../../assets/akt/testimonials-stephC.png";
import france from "../../../assets/akt/testimonials-france.png";
import marysa from "../../../assets/akt/testimonials-marysa.png";
import carolyne from "../../../assets/akt/testimonials-carolyne.png";
import helene from "../../../assets/akt/testimonials-helene.png";

import TestimonialsBG from "../../../assets/akt/testimonials-bg.png";

function Testimonials() {
    var settings = {
        infinite: true,
        speed: 1500,
        slidesToShow: 2.8,
        slidesToScroll: 1,
        centerPadding: "120px",
        // autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.8,
                    className: "center",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    function TestimonialCard(name, image, text) {
        return (
            <div className="testimonials__item">
                <div className="testimonials__item--content">
                    <img src={image} alt="" />
                    <h6 className="testimonials__name">{name}</h6>
                    <span className="testimonials__text">{text}</span>
                    <div className="testimonials__change">
                        <span>#AktivezLeChangement</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="testimonials">
            <h3 className="testimonials__title section__title">Ce que nos membres disent</h3>
            <span className="testimonials__subtitle">Notre communauté témoigne des impacts positifs sur leur santé</span>

            <div className="testimonials__content"></div>

            <Slider {...settings} autoplay className="testimonials__slider">
                {TestimonialCard(
                    "Geneviève Brault",
                    gen,
                    "Aktivation a changé ma vie et ma vision de l’entraînement. C’est juste complètement incroyable le nombre d’entraînements que tu peux faire 😍 Et ça dans parler des coachs et de l’équipe de feu 🔥 Se sont de vrai personnes humaines et tellement attachantes et Pisss c’est QUÉBÉCOIS"
                )}
                {TestimonialCard(
                    "Isabelle Ménard",
                    isabelle,
                    "Aktivation a sauvé mon corps de la pandémie qui avait pris pas mal de poids. J’ai découvert des entraînements et de entraîneurs motivants. La beauté de la plate-forme est de pouvoir faire ce qui me tente QUAND ça me tente. Pas d’horaire de cours à respecter, pas d’engagement autre qu’envers moi-même! C’est vraiment super et en plus, j’encourage un entrepreneur québécois!"
                )}
                {TestimonialCard(
                    "Evelin RG",
                    evelin,
                    "C’est un investissement qui a changé ma vie. Je le recommande 150%. La santé. Le bien-être n’ont pas de prix. Aktivation fait partie de ma vie. Et je tiens à te féliciter Jimmy Sévigny à toi et ton équipe de feu!"
                )}
                {TestimonialCard(
                    "Stephanie Turbis",
                    steph,
                    "Excellente plateforme avec pleins de diversité après en avoir essayé d’autre! Les programmes sont super et les coachs encore plus! Je l’aime d’amour cette communauté 😍"
                )}
                {TestimonialCard(
                    "Karine Archambault",
                    karine,
                    "Je suis abonnée depuis le début et jamais je n’ai pensé lâcher mon abonnement, j’adore aktivation et ses coach. Aktivation m’a permis de me dépasser et atteindre des niveaux que je ne pensait jamais atteindre. Jimmy est tellement inspirant et motivant et il a su s’entourer d’une équipe en or!"
                )}
                {TestimonialCard(
                    "Mariane Proulx",
                    mariane,
                    "Aktivation est rendu un essentiel pour moi! Tellement varié, tout simplement parfait! Les coachs sont authentique et font du bien au moral, parfois on se croirais avec eux! L’essayer c’est l’adopter."
                )}
                {TestimonialCard(
                    "Annie Labrecque",
                    annie,
                    "Je suis abonnée depuis plus de deux ans et j’adore cette plateforme! Tellement de diversité, des programmes pour tous les niveaux de forme physique et tous les goûts, les coachs sont professionnels et super motivants!"
                )}
                {TestimonialCard(
                    "Stéphanie Claveau",
                    stephC,
                    "Tellement une belle plateforme complète! J’adore naviguer entre les différents programmes selon ce que j’ai envie de faire!!  Je suis une fidèle abonnée depuis maintenant 1 an et demi et je suis complètement fan, c’est parfait pour mes entraînements à la maison même avec enfants autour."
                )}
                {TestimonialCard(
                    "France Perron",
                    france,
                    "Inscrite depuis 1 ans, j’adore cette plateforme québécoise hyper bien faite et stimulante!! Une fois débuter, on ne peux plus s’en passer. Merci pour aktivation."
                )}
                {TestimonialCard(
                    "Marysa Bergeron",
                    marysa,
                    "Je m’entraîne sur cette plateforme depuis plusieurs années :D :D Et je peux vous confirmer qu’il y a des entraînements pour tous peu importe ta condition physique. Et la qualité ++ avec des entraîneurs disponibles et généreux."
                )}
                {TestimonialCard(
                    "Carolyne Gagné",
                    carolyne,
                    "Membre depuis 3 ans et plus que satisfaite! Une belle diversité d’entraînement pour tous les niveaux et une équipe d’entraîneur des plus motivants."
                )}
                {TestimonialCard(
                    "Hélène Beauregard",
                    helene,
                    "Je suis abonnée à Aktivation depuis avril 2021. J’adore!!! C’est diversifié, facilement adaptable à notre routine familiale et au temps que nous avons pour l’entraînement."
                )}
            </Slider>
        </div>
    );
}

export default Testimonials;
