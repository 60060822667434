import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { addSubscription } from "../../../store/reducers/checkout";

// assets
import useFetch from "../../../hooks/useFetch";
import { formatPrice } from "../../../utils/parseData";
import redLine from "../../../assets/akt/products-redline.svg";
import exclamation from "../../../assets/akt/exclamation-mark-yellow.svg";
import star from "../../../assets/akt/sun.svg";

// hooks
import useAds from "../../../hooks/useAds/useAds";
import { getRequest } from "../../../actions/requests";


function Products() {
    const history = useHistory();
    const dispatch = useDispatch();

    const YEARLYSOLO = "TdWWNjaYrD2Rhc95tkFv";
    const BIANNUAL = "OVuo1OrnJVNqhg0cvLwP";
    const MONTHLY = "9RPkVos0gMqxQgxRx2C7";

    const [yearly] = useFetch(`subscriptions/${YEARLYSOLO}`);
    const [biAnnual] = useFetch(`subscriptions/${BIANNUAL}`);
    const [monthly] = useFetch(`subscriptions/${MONTHLY}`);

    const [isUnscrambled, setIsUnscrambled] = useState(false);

    const ad = useAds();

    const checkUnscrambled = async () => {
        const payload = await getRequest("unscramblings/isUnscrambled");
        setIsUnscrambled(payload.data.isUnscrambled);
    };

    useEffect(() => {
        checkUnscrambled();
    }, []);

    if (!yearly || !monthly) return null;

    const yearlyDiscountPrice = formatPrice(yearly.rate.amount);
    const monthlyDiscountPrice = formatPrice(monthly.rate.amount);

    return (
        <>
            {/* FEATURED ITEM */}
            {/* <div id="promo">
            <Grid item xs={12} className="promo__desktop">
                <img src={Promo} alt="" />
            </Grid>

            <Grid item xs={12} className="promo__mobile">
                <img src={PromoMobile} alt="" />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Grid>

            <br />
            {ad?.Component ? <ad.Component dismiss={ad?.dimiss} /> : null}
            <button className="coach__cta" onClick={ad?.show}>VOIR LES CHOIX DE CADEAU</button>
            <br />
          </div> */}

            <div className="products" id="produits">
                <div className="products__container">
                    <h3 className="products__title section__title">CHOISISSEZ LE PLAN POUR VOUS!</h3>
                    <p className="products__text">
                        Tous les plans comprennent <b>tous les programmes et fonctionnalitées</b>, incluant l’application Android et iOS, Chromecast,
                        AirPlay, les téléchargements et les plans alimentaires.
                    </p>
                    {isUnscrambled && (
                        <div className="products__debrouillage">
                            <div className="products__debrouillage--title">
                                <h3>LE GRAND DÉBROUILLAGE</h3>
                                <img src={exclamation} alt="" />
                            </div>
                            <span>Essayer la plateforme Aktivation sans frais ni inscription pour une durée limitée!</span>
                            <button onClick={() => history.push("portail/login")}>Découvrir gratuitement</button>
                        </div>
                    )}

                    <div className="products__content">
                        <div className="products__card">
                            <div className="products__card--sale">
                                <img src={star} alt="" />
                                <div className="text">
                                    <span className="amount">
                                        {biAnnual && monthly && (100 - ((biAnnual?.rate.amount * 2) / (monthly?.rate.amount * 12)) * 100).toFixed(0)}%
                                    </span>
                                    <span>de rabais</span>
                                </div>
                            </div>
                            <div className="products__card--container">
                                <h4 className="products__card--title">Abonnement Biannuel</h4>
                                <div className="products__card--price">
                                    <h2 className="amount">{biAnnual && (biAnnual.rate.amount / 100 / 6).toFixed(2)}$</h2>
                                    <span className="frequence">par mois</span>
                                </div>
                                <span className="products__card--once">{biAnnual && biAnnual.rate.amount / 100} payé EN UN SEUL VERSEMENT</span>
                                <span className="products__card--regular">
                                    prix régulier{" "}
                                    <div className="old-price">
                                        <span>164.99$</span>
                                        <img src={redLine} alt="redLine" />
                                    </div>
                                </span>
                                <button
                                    className="products__card--button"
                                    onClick={async () => {
                                        dispatch(addSubscription({ ...biAnnual, selectedQuantity: 1 }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    Choisir ce plan
                                </button>
                                <span className="products__card--trial">ESSAI GRATUIT DE 14 JOURS INCLUS</span>
                            </div>
                        </div>
                        <div className="products__card favorite">
                            <div className="products__card--sale">
                                <img src={star} alt="" />
                                <div className="text">
                                    <span className="amount">
                                        {yearly && monthly && (100 - (yearly.rate.amount / (monthly.rate.amount * 12)) * 100).toFixed(0)}%
                                    </span>
                                    <span>de rabais</span>
                                </div>
                            </div>
                            <div className="products__card--favorite">
                                <span>Le choix favori</span>
                            </div>
                            <div className="products__card--container">
                                <h4 className="products__card--title">Abonnement annuel</h4>
                                <div className="products__card--price">
                                    <h2 className="amount">{yearly && (yearly.rate.amount / 100 / 12).toFixed(2)}$</h2>
                                    <span className="frequence">par mois</span>
                                </div>
                                <span className="products__card--once">{yearly && yearly.rate.amount / 100}$ payé EN UN SEUL VERSEMENT</span>
                                <span className="products__card--regular">
                                    prix régulier{" "}
                                    <div className="old-price">
                                        <span>299.99$</span>
                                        <img src={redLine} alt="redLine" />
                                    </div>
                                </span>
                                <button
                                    className="products__card--button"
                                    onClick={async () => {
                                        dispatch(addSubscription({ ...yearly, selectedQuantity: 1 }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    Choisir ce plan
                                </button>
                                <span className="products__card--trial">ESSAI GRATUIT DE 14 JOURS INCLUS</span>
                            </div>
                        </div>
                        <div className="products__card">
                            <div className="products__card--container">
                                <h4 className="products__card--title">Abonnement mensuel</h4>
                                <div className="products__card--price">
                                    <h2 className="amount">{monthly && monthly.rate.amount / 100}$</h2>
                                    <span className="frequence">par mois</span>
                                </div>
                                <span className="products__card--once">{monthly && monthly.rate.amount / 100} payé mensuellement</span>
                                <span className="products__card--regular">
                                    prix régulier{" "}
                                    <div className="old-price">
                                        <span>29.99$</span>
                                        <img src={redLine} alt="redLine" />
                                    </div>
                                </span>
                                <button
                                    className="products__card--button"
                                    onClick={async () => {
                                        dispatch(addSubscription({ ...monthly, selectedQuantity: 1 }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    Choisir ce plan
                                </button>
                                <span className="products__card--trial">ESSAI GRATUIT DE 14 JOURS INCLUS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Products;
