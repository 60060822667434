import React, { useState, useRef, useEffect } from "react";

// Components
import Nav from "./shared/nav";
import ProgressOrder from "./shared/progressOrder";
import OrderType from "./order/orderType";
import HowOrder from "./order/howOrder";
import ProductsOrder from "./order/productsOrder";
import Plan from "./order/plan";
import Engagement from "./shared/engagement";
import Newsletter from "./shared/newsletter";
import Footer from "./shared/footer";

const Order = () => {
    const [optionCreate, setOptionCreate] = useState(undefined);
    const myRef = useRef();

    const setOption = option => {
        if (option === true) setOptionCreate(true);
        if (option === false) setOptionCreate(false);
    };

    const executeScroll = ref => {
        if (!ref.current) return;
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="bs">
            <div className="order">
                <Nav />
                <ProgressOrder create={optionCreate} step={1} />
                <OrderType
                    create={optionCreate}
                    setCreate={event => {
                        console.log("event", event);
                        setOption(event);
                        executeScroll(myRef);
                    }}
                />
                <div id="howOrder">
                    <HowOrder />
                </div>
                <div id="order-products" ref={myRef}>{optionCreate !== undefined && <ProductsOrder create={optionCreate} step={1} />}</div>
                {/* <Plan / > */}
                <Engagement />
                <Newsletter />
                <div id="footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Order;
