import React, { createContext, useEffect, useState } from "react";
import useGetUser from "../general/useGetUser";

// custom components
import SplashScreen from "../components/shared/utils/splashScreen";
import { getSplashScreenImage } from "../utils/config";
import { getRequest } from "../actions/requests";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const { user, loading } = useGetUser();
    const [userEnteredUnscramlingEmail, setUserEnteredUnscramblingEmail] = useState(false);
    const [isUnscrambled, setIsUnscrambled] = useState(false);

    const checkUnscrambled = async () => {
        const payload = await getRequest("unscramblings/isUnscrambled");
        setIsUnscrambled(payload.data.isUnscrambled);
    };

    useEffect(() => {
        checkUnscrambled();
    }, []);

    if (!user && loading) return <SplashScreen image={getSplashScreenImage()} />;

    return (
        <UserContext.Provider
            value={{
                user,
                userEnteredUnscramlingEmail,
                setUserEnteredUnscramblingEmail,
                isUnscrambled,
                setIsUnscrambled,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
