import React, { useState, useMemo, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import VideoPlayer from "../../shared/videoPlayer";

import useFetch from "../../../hooks/useFetch";
import useWindowSize from "../../../hooks/useWindowSize";

const VideoDialog = ({ tag, file, open, onClose, workoutInfo }) => {
    // States
    const [player, setPlayer] = useState(null);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"} className="akt">
            <div style={{ width: "100%", height: "100%" }}>
                <VideoPlayer calculatePoints={false} player={player} setPlayer={setPlayer} jwpTag={tag} video={file} />
            </div>
            <DialogContent>
                {workoutInfo?.title && (
                    <div>
                        <p className="progs__teaser__title">{workoutInfo.title}</p>
                        {workoutInfo.duration > 0 && <p className="progs__teaser__duration">{`${workoutInfo.duration} semaines`}</p>}
                        <p className="progs__teaser__description">{workoutInfo.shortDescription}</p>
                        <p className="progs__teaser__training">{workoutInfo.entrainements}</p>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

function Progs() {
    // Hooks
    const widthOnWindowChange = useWindowSize();
    const [ws] = useFetch("workouts");

    // States
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("");
    const [selectedWorkoutId, setSelectedWorkoutId] = useState("");
    const [width, setWidth] = useState(document.body.offsetWidth);
    const [showAllWorkout, setShowAllWorkout] = useState(false);

    const onClose = () => {
        setOpen(false);
        setFile("");
    };

    const onClick = id => {
        setOpen(true);
        setSelectedWorkoutId(id);
        setFile(ws.find(workout => workout.id === id)?.teaser);
    };

    const selectedWorkout = useMemo(() => {
        if (!selectedWorkoutId) return undefined;
        return ws.find(workout => workout.id === selectedWorkoutId);
    }, [selectedWorkoutId]);

    const releasedWorkouts = useMemo(() => {
        if (!ws) return [];
        return ws.filter(w => {
            const release = new Date(w.release._value);
            return Date.now() - release > 0;
        });
    }, [ws]);

    const [featured, ...workouts] = useMemo(() => {
        if (!releasedWorkouts) return [];
        return releasedWorkouts.sort((a, b) => {
            const createdAtA = new Date(a.createdAt._value).getTime();
            const createdAtB = new Date(b.createdAt._value).getTime();

            return createdAtB - createdAtA;
        });
    }, [releasedWorkouts]);

    useEffect(() => {
        if (widthOnWindowChange !== 0) setWidth(widthOnWindowChange);
    }, [widthOnWindowChange, width]);

    if (!ws) return null;

    return (
        <div className="progs__container">
            <div id="programmes" className="progs">
                <VideoDialog open={open} file={file} tag="teaser-id" onClose={onClose} workoutInfo={selectedWorkout} />
                <h3 className="progs__title">DÉCOUVREZ NOS PROGRAMMES</h3>
                <p className="progs__text">
                    Aktivation, c'est plus de <b>285 entraînements</b> répartis dans 28 <b>programmes</b>!
                </p>
                <Grid container spacing={4} className="progs__content">
                    {/* FEATURED ITEM */}
                    {featured && (
                        <>
                            <Grid item xs={12} className="progs__feat__desktop progs__unit">
                                <img src={featured.bannerImage} onClick={() => onClick(featured.id)} alt="" />
                            </Grid>
                            <Grid item xs={12} className="progs__feat__mobile progs__unit">
                                <h3 className="progs__title">Programme vedette</h3>
                                <img src={featured.bannerImage} onClick={() => onClick(featured.id)} alt="" />
                            </Grid>
                        </>
                    )}

                    {/* GRID ITEMS */}
                    {!showAllWorkout
                        ? width >= 960
                            ? workouts.slice(0, 9).map(workout => (
                                  <Grid key={workout.id} item xs={12} sm={6} md={4} className="progs__unit">
                                      <img src={workout.profileImage} onClick={() => onClick(workout.id)} alt="" />
                                  </Grid>
                              ))
                            : width >= 600
                            ? workouts.slice(0, 6).map(workout => (
                                  <Grid key={workout.id} item xs={12} sm={6} md={4} className="progs__unit">
                                      <img src={workout.profileImage} onClick={() => onClick(workout.id)} alt="" />
                                  </Grid>
                              ))
                            : workouts.slice(0, 3).map(workout => (
                                  <Grid key={workout.id} item xs={12} sm={6} md={4} className="progs__unit">
                                      <img src={workout.profileImage} onClick={() => onClick(workout.id)} alt="" />
                                  </Grid>
                              ))
                        : workouts.map(workout => (
                              <Grid key={workout.id} item xs={12} sm={6} md={4} className="progs__unit">
                                  <img src={workout.profileImage} onClick={() => onClick(workout.id)} alt="" />
                              </Grid>
                          ))}
                    {!showAllWorkout && (
                        <>
                            <div className="progs__white"></div>
                            <button className="progs__cta" onClick={() => setShowAllWorkout(true)}>
                                VOIR PLUS DE PROGRAMMES
                            </button>
                        </>
                    )}
                </Grid>
            </div>
        </div>
    );
}

export default Progs;
