import React from "react";

// Mui
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// Assets
import faq from "../../../assets/gg/faq.png";
import x from "../../../assets/gg/x.svg";

const Faq = () => {
    return (
        <div className="faq" id="faq">
            <div className="faq__left">
                <img src={faq} alt="Un verre de Go Gummy! explosif" />
            </div>
            <div className="faq__right">
              <h3 className="faq__right--title londrina">FAQ</h3>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3 className="faq__accordion--title">Qu'est-ce que Go Gummy!?  </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="faq__accordion--text">
                      Il s’agit d'une friandise de type jujube.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Que contient Go Gummy!?</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        1 sac contient 1 gramme de sucre* et 30 grammes de fibres 
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Comment doit-on consommer  Go Gummy!?</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Comme les Go Gummy représentent un produit très riche en fibres, nous vous recommandons de débuter par consommer la moitié d’un sachet, soit 7-8 oursons, afin de voir votre tolérance et ainsi, éviter les inconforts intestinaux.  
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Est-ce que Go Gummy! est recommandé pour les enfants? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Bien qu'ils ne contiennent aucun produit qui pourrait être nocif autant pour les enfants que les adultes, le fait d'en consommer une grande quantité pourrait tout comme chez les adultes, créer des inconforts intestinaux dû à son apport très riche en fibres. Pour cette raison, nous ne recommandons pas Go Gummy! aux enfants.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">De quelle façon sont livrés les produits? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        En colis standard par Postes Canada
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Comment modifier mes informations personnelles? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Il suffit de vous connecter via votre profil afin de modifier facilement toutes vos informations.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Quelle est la durée de conservation de Go Gummy! ? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        La date d'expiration affichée est de 18 mois
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Est-ce que Go Gummy! a été créé pour la perte de poids? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Non. 
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Est-ce que ce produit est certifié sans arachides? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Il n'en contient pas mais toutefois, nous n'avons pas la certification qu'il est sans arachide.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Est-ce que ce produit est sans gluten? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Oui
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Est-ce que ce produit est considéré Vegan? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Oui
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="faq__accordion">
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <img src={x} alt="" />
                            </div>
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 className="faq__accordion--title">Est-ce que Go Gummy! contient du sucre? </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="faq__accordion--text">
                        Un sac contient 1 gramme de sucre*.
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default Faq;
