import React from "react";
import Nav from "../akt/home/nav";
import Footer from "../akt/home/footer";
import Brands from "../akt/home/brands";
import Lh from "../akt/home/lh";

const refundPolicy = () => {
    return (
      <div className="akt">
        <Nav />
        <div style={{ fontFamily: '"Cairo",sans-serif', padding: 25, margin: '0 auto', maxWidth: 1770 }}>
            <h1 style={{ textAlign: "center" }}>AKTIVATION</h1>
            <p style={{ textAlign: "center" }}>
                Politique d'Annulation et de Remboursement pour les Abonnement à la Plateforme d'Entraînement en Ligne
            </p>
            <h2 style={{ textDecoration: "underline" }}>CONDITIONS GÉNÉRALES</h2>
            Aktivation propose une plateforme d'entraînement en ligne à 100 %. En tant qu'abonné, vous avez la liberté de suivre ou de ne pas suivre
            les programmes ou planifications d'entraînement disponibles sur la plateforme. Voici les principaux points à retenir :
            <ul>
                <li>Vous n'êtes pas contraint par un horaire fixe. Il n'y a pas d'entraînements en direct ou en présentiel.</li>
                <li>Vous pouvez personnaliser votre propre routine d'entraînement en fonction de vos préférences et de vos besoins.</li>
                <li>
                    Vous avez la possibilité de répéter un programme ou un entraînement autant de fois que vous le souhaitez. Cette flexibilité vous
                    permet d'adapter votre entraînement à vos objectifs personnels.
                </li>
                <li>
                    Veuillez noter que l'abonnement à la plateforme ne comprend pas de plan d'entraînement ou d'alimentation personnalisé. Si vous
                    recherchez des services plus spécifiques, nous vous invitons à consulter les offres disponibles sur notre site{" "}
                    <a href="https://www.jimmysevigny.com/coaching/" style={{ textDecoration: "underline", color: "blue" }}>
                        Services de Coaching
                    </a>
                    . Pour toute question concernant votre abonnement, notre service client est disponible par courriel à l'adresse suivante : &nbsp;
                    <a href="mailto:info@aktivation.ca" style={{ textDecoration: "underline", color: "blue" }}>
                        info@aktivation.ca
                    </a>
                    .
                </li>
            </ul>
            <p>
                Pour toute question concernant votre abonnement, notre service client est disponible par courriel à l'adresse suivante : &nbsp;
                <a href="mailto:info@aktivation.ca" style={{ textDecoration: "underline", color: "blue" }}>
                    info@aktivation.ca
                </a>
            </p>
            <h2 style={{ textDecoration: "underline" }}>PÉRIODE D'ESSAI GRATUIT</h2>
            <p>
                Nous offrons une période d'essai gratuit de 14 jours à tous les nouveaux membres de notre plateforme. Cette période vous donne un
                accès complet à toutes nos fonctionnalités ci-dessous
            </p>
            <ul>
                <li>Les programmes d'entraînement</li>
                <li>Les fonctionnalités de l'application Android et iOS (Chromecast AirPlay)</li>
                <li>Les téléchargements</li>
                <li>Les planifications d'entraînement et les plans alimentaires disponibles avec certains programmes.</li>
            </ul>
            <p>
                Votre abonnement débute officiellement <b>à la fin</b> de la période d'essai gratuit.
            </p>
            <p>
                Vous serez automatiquement facturé selon le mode de paiement défini et consenti lors votre inscription, sauf si cette option est
                désactivée dans votre profil client.
            </p>
            <p>
                En cas de résiliation de l'abonnement avant la fin de la période d'essai gratuit, aucun débit ne sera effectué sur la carte de crédit.
                Pour plus d'informations sur la résiliation, veuillez consulter la section <b>Résiliation et remboursement</b>{" "}
                <u>incluse dans le document ci-bas</u>.
            </p>
            <p>
                *Un nouveau membre est défini comme une personne n'ayant jamais utilisé la période d'essai gratuit, que ce soit en totalité ou en
                partie.
            </p>
            <h2 style={{ textDecoration: "underline" }}>CONDITIONS D'ABONNEMENT ET RENOUVELLEMENT</h2>
            <ul>
                <li>Votre abonnement devient actif dès la fin de votre période d'essai gratuit</li>
                <li>Votre premier paiement est chargé dès la fin de votre période d'essai gratuit</li>
                <li>Le montant prélevé inclus les taxes et est indiqué au moment de l'achat</li>
                <li>Vous serez facturé en une somme forfaitaire</li>
                <li>Le tarif payé au moment de votre abonnement vous est garanti tant que votre abonnement demeure actif</li>
                <li>Vous pouvez modifier votre information de paiement à tout moment dans votre profil client.</li>
                <li>
                    Vous nous autorisez à enregistrer votre mode de paiement et à le facturer automatiquement en fonction de votre forfait jusqu'à
                    votre résiliation
                </li>
                <li>Votre abonnement sera automatiquement renouvelé à votre date de renouvellement jusqu'à ce que vous le résiliez</li>
                <li>Vous pouvez modifier votre abonnement en communiquant avec notre service à la clientèle</li>
                <li>
                    Pour les abonnements <b>annuels et biannuels</b>, un courriel d'avis de renouvellement vous sera envoyé <b>15 jours</b> avant la
                    date de votre renouvellement.
                </li>
                <li>
                    <b>Aucun</b> avis de renouvellement automatique sera envoyé pour les abonnements <b>mensuels</b>
                </li>
                <li>Au moment de votre renouvellement, si votre paiement automatique échoue, votre abonnement sera automatiquement annulé</li>
                <li>
                    Pour conserver votre abonnement au même tarif advenant l'échec de votre renouvellement, vous avez une période de 7 jours pour
                    contacter notre service à la clientèle.
                </li>
                <li>Après cette période, nous nous réservons le droit de ne pas vous redonner votre tarif initial.</li>
            </ul>
            <h2 style={{ textDecoration: "underline" }}>CONDITIONS DE RÉSILIATION ET REMBOURSEMENT</h2>
            <p>Vous pouvez résilier votre abonnement à tout moment dans votre profil client ou en contactant notre service à la clientèle.</p>
            <p>
                Si vous résiliez votre abonnement avant le renouvellement automatique, aucun paiement ne sera débité sur votre carte de crédit et
                votre accès à la plateforme se désactivera automatiquement à la fin du terme de votre abonnement.
            </p>
            <p>
                Pour les abonnements <b>annuels</b> et <b>biannuels</b>, en cas d'oubli de résiliation du renouvellement automatique, vous avez{" "}
                <b>7 jours</b> suivant le début du renouvellement pour contacter notre service à la clientèle et faire une demande de remboursement.
            </p>
            <p>
                À la fin de la période de grâce de <b>7 jours</b>, il ne vous sera plus possible d'obtenir de remboursement. Cependant, votre
                résiliation sera effectuée pour le prochain renouvellement automatique.
            </p>
            <p>
                Pour les abonnements <b>mensuels</b>, en cas d'oubli de résiliation du renouvellement automatique, <b>Aucun</b> remboursement sera
                effectué, cependant, votre résiliation sera effectuée pour le prochain renouvellement automatique.
            </p>
        </div>
        <Footer />
        <Brands />
        <Lh />
      </div>
    );
};

export default refundPolicy;
