import React, { useContext, useEffect, useState, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";

// Material UI Core
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Material UI Lab
import Skeleton from "@material-ui/lab/Skeleton";

// Context
import { CheckoutContext } from "./Checkout";

// Custom Components
import FlashSalesDialog from "./flashSalesDialog";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders, getLoading } from "../../../store/reducers/orders";
import { UserContext } from "../../../reducer/userContext";
import { clearAllData } from "../../../store/reducers/checkout";
import { signIn } from "../../../store/reducers/users";
import { getRequest } from "../../../actions/requests";

// utils
import { uniqWithCount } from "../../../utils/array";
import { orderItemsComparator } from "../../../utils/order";

import Analytics from "../../../services/analytics";

export const ConfirmationContent = ({ orders, loading = false, disableReturn = false }) => {
    const [isPhysical, setIsPhysical] = useState(true);
    const [processingMessage, setProcessingMessage] = useState("");
    const [shippingMessage, setShippingMessage] = useState("");
    const lastOrder = orders[orders.length - 1];

    const checkOrderPhysicalItems = async () => {
        if (orders && orders.length > 0) {
            for (const item of orders[0].items) {
                if (item.isSubscription) {
                    const subPayload = await getRequest(`subscriptions/${item.id}`);
                    // Analytics.subscribedTo(subPayload.data, lastOrder.user.email);
                    if (!subPayload.data.isPhysical) setIsPhysical(false);
                } else {
                    const prodPayload = await getRequest(`products/${item.id}`);
                    if (!prodPayload.data.isPhysical) setIsPhysical(false);
                }
            }
        }

        if (isPhysical) {
            setProcessingMessage(`À moins d'un avis contraire ou lors d'une promotion, si votre item est en stock, il sera traité et mis à la poste dans un délai 48 à 72 heures ouvrables.`);
            setShippingMessage(`Tout dépendamment de votre lieu de résidence, prévoir 5 à 10 jours ouvrables pour la livraison. Les livraisons sont effectuées par différents transporteurs, soit Poste Canada, UPS, Purolator, GLS ou autres.`);
        }
    };

    useEffect(() => {
        (async () => {
            const items = [];
            for (const { value, count } of uniqWithCount(lastOrder.items, orderItemsComparator)) {
                const url = value.isSubscription ? `subscriptions/${value.id}` : `products/${value.id}`;
                const data = (await getRequest(url)).data;
                items.push({ ...data, selectedSku: value.selectedSku, quantity: count });
            }

            // Analytics.checkoutSuccess(lastOrder, items);
            // Analytics.orderDetails(lastOrder);
        })();
        checkOrderPhysicalItems();
    }, []);

    return (
        <>
            <Typography variant="h3" gutterBottom>
                {!loading ? "Merci pour votre commande!" : <Skeleton animation="wave" />}
            </Typography>
            <Typography variant="h6" gutterBottom>
                {!loading ? (
                    `Vous recevrez un courriel de confirmation dans les prochaines minutes. (vérifier les courriels indésirables)`
                ) : (
                    <Skeleton animation="wave" />
                )}
                <br />
                <br />
                {isPhysical && (
                    <>
                        {!loading ? processingMessage : <Skeleton animation="wave" />}
                        <br />
                        <br />
                        {!loading ? shippingMessage : <Skeleton animation="wave" />}
                        <br />
                        <br />
                    </>
                )}

                {!loading ? (
                    orders.length === 1 ? (
                        `Votre numéro de commande est ${orders[orders.length - 1].orderNumber}.`
                    ) : (
                        `Vos numéros de commandes sont: ${orders
                            .map((o, i) => {
                                return `${o.orderNumber}`;
                            })
                            .join(", ")}`
                    )
                ) : (
                    <Skeleton animation="wave" />
                )}
            </Typography>

            {!disableReturn &&
                (!loading ? (
                    <div>
                        <a href="./">
                            <Button type="submit" variant="contained" color="primary">
                                Retour au site
                            </Button>
                        </a>
                    </div>
                ) : (
                    <Skeleton animation="wave" />
                ))}
        </>
    );
};

const ConfirmationForm = () => {
    const hist = useHistory();
    // state
    const { user } = useContext(UserContext);
    // Redux
    const dispatch = useDispatch();
    const orders = useSelector(getAllOrders);
    const ordersLoading = useSelector(getLoading);

    const [loading, setLoading] = useState(true);
    const { setShowCart } = useContext(CheckoutContext);

    const flashsaleRef = useRef(null);

    // TODO: need to rethink this part
    // useEffect(() => {
    //     if (user) dispatch(loadById(user.id));
    // }, []);

    useEffect(() => {
        setShowCart(false);
        if (!ordersLoading) {
            setLoading(false);
        }
    }, [ordersLoading]);

    useEffect(() => {
        return () => {
            dispatch(clearAllData());
            dispatch(signIn());
        };
    }, []);

    return (
        <>
            <ConfirmationContent orders={orders} loading={loading} />
            <FlashSalesDialog ref={flashsaleRef} />
        </>
    );
};

export default ConfirmationForm;
