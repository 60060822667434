import { settings } from "../settings";

export const getServer = () => {
    switch (process.env.REACT_APP_LH_ENV) {
        case "production":
            return settings.serverUrls.production;
        case "development":
            return settings.serverUrls.production;
        default:
            return settings.serverUrls.production;
    }
};
