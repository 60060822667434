import React from "react";

const Ratio = () => {
    return (
        <div className="ratio">
            <h2 className="ratio__text londrina">1 ourson normal contient autant de sucre que 22 Go Gummy!</h2>
            <a href="/#sample"><button className="cta__black">Acheter en ligne</button></a>
        </div>
    );
};

export default Ratio;
