import React from "react";
// Assets
import Background from "../../../assets/akt/what-bg.png";
import trainers from "../../../assets/akt/what-trainers.png";

function What() {
    return (
        <div className="what">
            <div className="what__container">
                <img src={trainers} alt="" className="what__left" />
                <div className="what__right">
                    <div className="what__content">
                        <h4 className="section__title what__title">AKTIVATION, C'EST...</h4>
                        <ul>
                            <li>Plus de 285 entraînements</li>
                            <li>Des vidéos de qualités supérieures</li>
                            <li>3 à 5 nouveaux programmes par année garanti</li>
                            <li>Des recettes</li>
                            <li>Des plans alimentaires</li>
                            <li>Des planifications d'entraînement</li>
                        </ul>
                        <a href="./#produits">
                            <button className="what__cta">Je veux activer le changement</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default What;
