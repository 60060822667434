import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Mui
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// Redux
import { useDispatch } from "react-redux";
import { addProduct, addSubscription } from "../../../store/reducers/checkout";

// Firestore
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
import { subscriptionsRoot } from "../../../actions/subscriptionsRoute";

// Custom Components
import { getLandingPageInfo } from "../../../utils/config";

// Utils
import { fetchProductById } from "../../../utils/apiCalls";

// Assets ******** TEMPORARY *******
import tvn from "../../../assets/gg/tvn.png";
/* import framboise from "../../../assets/gg/go-gummy-framboise.png"; */
/* import melon from "../../../assets/gg/go-gummy-melon-d-eau.png"; */
/* import ananas from "../../../assets/gg/go-gummy-ananas.png"; */
/* import peche from "../../../assets/gg/go-gummy-peche.png"; */

let images = [];

const ProductTypeSelectionDialog = ({ open, onClose, items, name }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { brand } = getLandingPageInfo();
  const [product, setProduct] = useState({});
  const [item, setItem] = useState({});

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (!_.isEmpty(product) && product.imageURLs) {
    images = product.imageURLs.reduce((acc, value) => {
      acc.push(<img src={value} alt="product" className="productpop__image" style={{ width: "100%" }} />);
      return acc;
    }, []);
  }

  const handleOnContinue = async item => {
    try {
      if (item.isProduct) {
        const status = product.isPhysical ? "À livrer" : "Expédié";
        const productPayload = await getRequest(`${productsRoot}/${item.id}`);
        const productFound = productPayload.data;
        dispatch(addProduct({ ...productFound, selectedQuantity: 1, status }));
      } else {
        const subscriptionPayload = await getRequest(`${subscriptionsRoot}/${item.id}`);
        const subscription = subscriptionPayload.data;
        dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
      }
      history.push(`/checkout`);
    } catch (e) {
      console.error(e);
    }
  };

  const getItem = name => {
    switch (name) {
      case "raspberry":
        return setItem({ backgroundColor: "#ff4f40", color: "white", btn: "black", title: "Framboise" });
      case "watermelon":
        return setItem({ backgroundColor: "#C6DD4E", color: "black", btn: "white", title: "Melon d'eau" });
      case "pineapple":
        return setItem({ backgroundColor: "#FFC942", color: "black", btn: "white", title: "Ananas" });
      case "peach":
        return setItem({ backgroundColor: "#EB5465", color: "white", btn: "black", title: "Pêche" });
      default:
        return;
    }
  };

  useEffect(() => {
    getItem(name);
  }, [name]);

  useEffect(async () => {
    if (!_.isEmpty(items)) setProduct(await fetchProductById(items.product.id));
  }, [items]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="selection-dialog-title" className="productpop gg">
      {/* <DialogTitle id="selection-dialog-title" className={`productpop__title ${brand}`}>
                {!_.isEmpty(product) && product.name}
            </DialogTitle> */}
      <DialogContent style={{ backgroundColor: item.backgroundColor }}>
        <Grid container spacing={3} className="productpop__images__left">
          <Grid item xs={12} sm={6} className="productpop__images__container">
            <Slider {...settings} autoplay>
              {images.map((item, key) => (
                <div key={key} className="productpop__images">
                  {item && item.props && item.props.src && (
                    <img src={`${item.props.src}`} alt="product" className="productpop__image" />
                  )}
                </div>
              ))}
              <div className="productpop__images">
                <img src={tvn} alt="" />
              </div>
            </Slider>
          </Grid>
          <Grid item xs={12} sm={6} className={`productpop__info__container ${brand}`} style={{ color: item.color }}>
            {/* <div className="productpop__desc">{!_.isEmpty(product) && parse(draftToHtml(JSON.parse(product.description)))}</div> */}
            <h4 className="productpop__title londrina" style={{ borderBottomColor: item.btn }}>
              {item.title}
            </h4>
            <p className="productpop__desc kanit">
              Du au fait que Go Gummy! est un produit très riche en fibres, nous vous conseillons de débuter avec la 1/2 d’un sachet
              (7-8 oursons) afin de voir votre capacité d’assimilation.
                        </p>
            <div>
              <Button
                style={{ backgroundColor: item.btn, color: item.color }}
                className={`productpop__cta ${brand}`}
                variant="contained"
                onClick={() => {
                  handleOnContinue(items.product12);
                }}
              >
                ensemble de 12 = 44.99$ {product && " (44.99$)"}
              </Button>
              <Button
                style={{ backgroundColor: item.btn, color: item.color }}
                className={`productpop__cta ${brand}`}
                variant="contained"
                onClick={() => {
                  handleOnContinue(items.product24);
                }}
              >
                ensemble de 24 = 83,76$ {product && " (83.76$)"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProductTypeSelectionDialog;
