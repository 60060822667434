import React from "react";

// Assets
import logo from "../../../assets/gg/logo-footer.png";

const Footer = () => {
    return (
        <div className="footer kanit">
            <div>
              <img src={logo} alt="Go Gummy!" />
              <div>*: Veuillez prendre note que chaque sachet contient 1 gramme de sucre ou moins. </div>
            </div>
            <div className="footer__col">
              
            </div>
            <div className="footer__col">
              <h6>Navigation</h6>
                <ul>
                    <li>
                        <a href="./#faq"><p>Faq</p></a>
                    </li>
                    <li>
                        <a href="./portail"><p>Espace Client</p></a>
                    </li>
                    <li>
                        <a href="./contact"><p>Contact</p></a>
                    </li>
                </ul>
            </div>
            <div className="footer__col">
                <h6>Suivez-nous</h6>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/JimmySevigny.CoachMotivateur"><p>Facebook</p></a>
                  </li>
                    <li>
                        <a href="https://www.instagram.com/jimmy_sevigny/"><p>Instagram</p></a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
