import React from 'react'

function Video() {
    return (
        <div className="video">
            <h3 className="video__title1">La parfaite collation “on-the-go”.</h3>
            <h3 className="video__title2">Pas encore convaincu? Écoutez notre vidéo descriptive ci-dessous...  :)</h3>
            <div>
                <div className="iframe__container">
                    <iframe
                        width="800"
                        height="540"
                        src="https://cdn.jwplayer.com/players/Vis0zUT4-hDyhcbyt.html"
                        className="iframe__iframe"
                    ></iframe>
                </div>
            </div>
            <a href="#sample"><button className="video__cta">Essayez un “sample pack” pour 21,88$ +tx</button></a>
            <span className="video__disclaimer">** la livraison est gratuite! **</span>
        </div>
    );
}

export default Video;
