import React from "react";
import { useHistory } from "react-router-dom";

// Assets
import Signature from "../../../assets/akt/js-signature.png";

function Footer() {
    const history = useHistory();

    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__logo">
                    <img src={Signature} alt="signature" />
                </div>
                <div className="footer__mission">
                    <span className="footer__mission--title">La mission d'AKTIVATION en 3 points</span>
                    <ul>
                        <li className="footer__feature">Faire rayonner le plus grand nombre d’entraineurs québécois possible</li>
                        <li className="footer__feature">Offrir LA MEILLEURE QUALITÉ VIDÉO sur le marché</li>
                        <li className="footer__feature">Permettre au plus de gens d’Activer le changement à leur rythme!</li>
                    </ul>
                </div>
                <div className="footer__links">
                    <h6>Liens</h6>
                    <span className="clickable__tag" onClick={() => history.push("/devenir-entraineur")}>
                        Devenir coach
                    </span>
                    <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                        Ambassadeurs
                    </span>
                    <span className="clickable__tag" onClick={() => history.push("/corporatif")}>
                        Corporatif
                    </span>
                    <span className="clickable__tag" onClick={() => history.push("/contact")}>
                        Contactez-nous
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Footer;
